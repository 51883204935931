import React, { useState, useEffect } from 'react';
import { Tabs, Card, Row, Col, Typography, message, Button } from 'antd';
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';
import { IssuerRegistrationRequest } from '../../Shared/interfaces';
import './IssuerRegistrationRequests.scss';
import ReqTable from './ReqTable';
import Details from './Details';

const { TabPane } = Tabs;
const {Title} = Typography


const platformIssuerAdminService = new PlatformIssuerAdminService();



export default () => {

  const [filter, setFilter] = useState({limit: 10, searchPage: 1, statuses: ['pending']});
  const [issuerRegReqs, setIssuerRegReqs] = useState<IssuerRegistrationRequest[] | null>();
  const [selectedIssuerRegReq, setSelectedIssuerRegReq] = useState<IssuerRegistrationRequest | null>();
  const [loadingMoreReqs, setLoadingMoreReqs] = useState<boolean>();
  const [canLoadMoreReqs, setCanLoadMoreReqs] = useState(true);

  useEffect(() => {
    (async () => {
      // await getIssuerRegistrationReqs(['pending']);
      const statuses = ['pending'];
      setIssuerRegReqs(null);

      const searchPage = 1;
      setFilter((prev: any) => ({...prev, statuses, searchPage}));

      const response = await platformIssuerAdminService.getIssuerRegistrationReqs(statuses.toString(), filter.limit, 1);

      const reqs: IssuerRegistrationRequest[] = response.data;

      setIssuerRegReqs([...reqs]);
      setFilter((prev: any) => ({...prev, statuses, searchPage: searchPage + 1}));
      setCanLoadMoreReqs(reqs.length === filter.limit);
    })();
  }, [filter.limit]);

  
  const getIssuerRegistrationReqs = async(statuses: string[]) => {
    setIssuerRegReqs(null);

    const searchPage = 1;
    setFilter((prev: any) => ({...prev, statuses, searchPage}));

    const response = await platformIssuerAdminService.getIssuerRegistrationReqs(statuses.toString(), filter.limit, searchPage);

    const reqs: IssuerRegistrationRequest[] = response.data;

    setIssuerRegReqs([...reqs]);
    setFilter((prev: any) => ({...prev, statuses, searchPage: searchPage + 1}));
    setCanLoadMoreReqs(reqs.length === filter.limit);
  }


  const loadMoreReqs = async() => {
    if(loadingMoreReqs || !issuerRegReqs) return;

    setLoadingMoreReqs(true);
    const response = await platformIssuerAdminService.getIssuerRegistrationReqs(filter.statuses.toString(), filter.limit, filter.searchPage);

    const reqs: IssuerRegistrationRequest[] = response.data;

    setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
    setIssuerRegReqs(prev => [...prev as any, ...reqs]);
    setLoadingMoreReqs(false);
    setCanLoadMoreReqs(reqs.length === filter.limit);
  }


  const reviewRegistrationReq = async(status: 'verified' | 'rejected', comments: string) => {
    setSelectedIssuerRegReq((prev:any) => ({...prev, [status === 'rejected'? 'rejecting' : 'verifiying']: true}));

    const response = await platformIssuerAdminService.reviewRegistrationReq({requestId: selectedIssuerRegReq?._id as string, status, comments});

    if (response.success) {
      setIssuerRegReqs(prev => (prev?.filter(req => req._id !== selectedIssuerRegReq?._id)));
      hideDetails();
      message.success(status === 'verified'? 'Request verified successfuly' : 'Request rejected successfully');
      
    } else {
      message.error(response.error.message);
      // notification.error({
      //   message: 'Error',
      //   description: 'wewe',
      //   duration: 0,
      // });
      setSelectedIssuerRegReq((prev:any) => ({...prev, [status === 'rejected'? 'rejecting' : 'verifiying']: false}));
    }
  };


  const showDetails = async(issuerRegReq: IssuerRegistrationRequest) => {
    setSelectedIssuerRegReq({...issuerRegReq});
  }


  const hideDetails = () => {
    setSelectedIssuerRegReq(null);
  }



  return (
    <>
      <br/>
      <Row justify="center">
        <Col span={22} style={{textAlign:'center'}}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
              >
              Issuer Registration Requests
            </Title>
            
            <Tabs defaultActiveKey='1' style={{textAlign:'center'}} onChange = {(key) => getIssuerRegistrationReqs(key==='1'? ['pending'] :['verified', 'rejected'] )}>
              <TabPane
                tab={
                  <span>
                    <PlaySquareOutlined/>
                    Pending Issuer Registration Requests
                  </span>
                }
                key='1'
              >
                <ReqTable data={issuerRegReqs} loading={!issuerRegReqs} statuses={filter.statuses} showDetails={showDetails}/>
                {issuerRegReqs && canLoadMoreReqs && 
                  <Button loading={loadingMoreReqs} onClick={loadMoreReqs}>
                    Load more
                  </Button>
                }
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <ProjectOutlined/>
                    Approved/Rejected Issuer Registration Requests
                  </span>
                }
                key='2'
              >
                <ReqTable data={issuerRegReqs} loading={!issuerRegReqs} statuses={filter.statuses} showDetails={showDetails}/>
              </TabPane>
            </Tabs>
          </Card>
    
          <Details 
            record={selectedIssuerRegReq} 
            visible={!!selectedIssuerRegReq}
            reviewRegistrationReq={reviewRegistrationReq}
            onCancel={hideDetails}/>

        </Col>
      </Row>
    
    </>
  );
};

