import { Col, Row } from "antd";
import React from "react";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";
const PrivacyPolicy = () => {
  return (
    <div style={{ paddingTop: "150px",textAlign:'justify' }}>
      <Container>
        <div style={{ padding: "20px" }}></div>
        <div style={{ textAlign: "center" }}>
          <p style={{color:'#141838',fontSize:'55px',fontWeight:'bold'}}><span className="underline">Privacy Policy</span></p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="tick">
              <h3 style={{color:'#00b6e3'}}>Privacy Principles</h3>
              <div style={{ padding: "15px" }}></div>
              <p style={{textAlign:'justify'}}>
              Your privacy is important to us. This Privacy Policy explains how
              information about you is collected, used and disclosed by Crypto
              Asset Rating ("CAR"). This Privacy Policy applies to information
              we collect when you access our website, mobile applications,
              tokens and wallets (collectively, our "Online Services").</p>
              <div style={{ padding: "15px" }}></div>
              <p style={{textAlign:'justify'}}>
              Since we may gather certain types of information about our users,
              we feel you should fully understand our policy and the terms and
              conditions surrounding the capture and use of that information.
              This Privacy Policy discloses what information we gather and how
              we use it. The private information you provide on the Online
              Services will be used only for its intended purpose.</p>
              <div style={{ padding: "15px" }}></div>
              <p style={{textAlign:'justify'}}>
              <b>
                BY VISITING, USING AND ACCESING THE SERVICES, YOU CONSENT TO THE
                DATA PRACTICES DESCRIBED IN THIS STATEMENT. IF YOU DO NOT AGREE
                WITH OUT PRIVACY PRACTICES, DO NOT USE THE SERVICES.
              </b>
              </p>
              <div style={{ padding: "15px" }}></div>
              <h3 style={{color:'#00b6e3'}}>Our Pledge to You</h3>
              <p style={{textAlign:'justify'}}>
              We are committed to maintaining your trust and pledge the
              following:
              </p>
              <div style={{ padding: "15px" }}></div>
              <i>Transparency.</i> We will keep you informed of our privacy
              practices.
              <br />
              <p style={{textAlign:'justify'}}>
              <i>Safety.</i> While we cannot promise perfection, we have
              implemented security tools that are designed to safeguard your
              information in accordance with industry standards.
              <br />
              <i>You are in the driver’s seat.</i> We will ask for your
              permission before we share your personal information with third
              parties, in accordance with this Privacy Policy.
              <i>
                We tell you when our privacy policies and practices change.
              </i>
              We may change this Privacy Policy from time to time. If we do, we
              will let you know by appropriate means such as by posting the
              revised policy on this page with a new “Last Updated” date. All
              changes will become effective when posted unless indicated
              otherwise.
              </p>
              <div style={{ padding: "15px" }}></div>
              <h2 className="">
                Information <span style={{color:'#00b6e3'}}>we collect</span>
              </h2>
              <p style={{textAlign:'justify'}}>
              <b>Information You Provide to Us.</b> We collect information you
              provide directly to us. For example, the types of personal
              information we may collect from you when you visit or use our
              Online Services include your name, email address, mailing address,
              social security number, telephone number(s), account numbers, user
              name and password. As a general rule, you have the right not to
              provide the foregoing information when dealing with the Online
              Services.
              <br />
              <b>
                Information We Collect Automatically When You Use the Online
                Services.
              </b>
              In addition to the personal information described above, we may
              collect certain information about your use of our Online Services.
              </p>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Log Information
              </li>
              <p style={{textAlign:'justify'}}>
              We collect log information about your use of the online services,
              including the type of browser you use, access times, pages viewed,
              your IP address and the page you visited before navigating to our
              online services.
              </p>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Device Information
              </li>
              <p style={{textAlign:'justify'}}>
              We collect information about the computer or mobile device you use
              to access our online services, including the hardware model,
              operating system and version, unique device identifiers and mobile
              network information.</p>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Location Information
              </li>
              <p style={{textAlign:'justify'}}>
              We may collect information about the location of your device each
              time you access or use one of our mobile applications or otherwise
              consent to the collection of this information.
              </p>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Information Collected by Cookies and Other Tracking Technologies
              </li>
              <p style={{textAlign:'justify'}}>
              Our third-party partners or we may use cookies (as well as web
              beacons or other technologies) to collect and store this and other
              information about your visit to, or use of, our online services.
              Cookies are small data files stored on your hard drive or in
              device memory that help us improve our online services and your
              experience, and to see which areas and features of our online
              services are popular. Web beacons are electronic images that may
              be used in our online services or emails and help deliver cookies,
              count visits and understand usage and campaign effectiveness. We
              may later associate the usage and other information we collect
              online with personal information about you, as permitted or
              required by law. For more information about cookies, and how to
              disable them, please see "Your Choices" below.</p>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Information We Collect From Other Sources
              </li>
              We may also collect information about you from additional online
              and offline sources including commercially available third-party
              sources, such as consumer reporting agencies, for the purposes of
              verifying eligibility and securely offering our services to you.
              We may combine this information with the personal and other
              information we have collected about you under this Privacy Policy.
              <div style={{ padding: "15px" }}></div>
              <h2 className="">
                Use <span style={{color:'#00b6e3'}}>of Information</span>
              </h2>
              We may use the information we collect from you for various
              purposes, including to:
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Help us deliver our Services;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Administer, evaluate and improve our business (including
                developing new products and services, improving existing
                products and services, performing data analytics, monitoring and
                analyzing trends, and performing accounting, auditing and other
                internal functions);
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Communicate information to you at the request of our partners;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Manage our risks;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Market our Services and products;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Comply with and enforce applicable laws and regulations,
                relevant industry standards, contractual obligations and our
                policies; and
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Carry out any other purpose for which the information was
                collected.
              </li>
              We may also use data that we collect on an aggregate or
              de-identified basis for various business purposes, where
              permissible under applicable laws and regulations.
              <div style={{ padding: "15px" }}></div>
              <h2 className="">
                How we use <span style={{color:'#00b6e3'}}>your Information</span>
              </h2>
              We use information collected as described in this Privacy
              Statement for the following:
              <div style={{ padding: "15px" }}></div>
              <b>Operation</b>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Operate, maintain and improve the Online Services;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Provide you with interest-based ads, communications, and offers
                for products and services from us based on your precise
                geolocation;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Manage your account, including processing payments and refunds;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Send you reminders, technical notices, updates, security alerts,
                support and administrative messages and marketing messages;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Answer your questions and respond to your requests;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Ensure that content from our Online Services is presented in the
                most effective manner for you and for your computer or device
                for accessing the Online Services;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including the
                Terms and Conditions; and
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Notify you when there are changes to any products or services we
                offer or provide though the Online Services.
              </li>
              <div style={{ padding: "15px" }}></div>
              <b>Usage and Analytics</b>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Estimate our audience size and usage patterns;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Perform analytics and conduct customer research;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Evaluate your eligibility for certain types of offers, products
                or services that may be of interest to you;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Analyze advertising effectiveness; and
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Communicate and provide additional information that may be of
                interest to you about us and our business partners.
              </li>
              <div style={{ padding: "15px" }}></div>
              <h2 className="">
                How we disclose
                <span style={{color:'#00b6e3'}}>your Information</span>
              </h2>
              We may share information about you as follows or as otherwise
              described in this Privacy Policy:
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                With CAR partners as part of the delivery of our Online Services
                to you;
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                With affiliates, consultants, and other service providers when
                we believe it will enhance the Online Services and products we
                can provide to you or for legal and routine business reasons.
                For example, we may share selected details when checking your
                identity for indicators of fraud during sign up or when
                providing alerts. These parties are authorized to use your
                personal information only as necessary to provide these services
                to us.
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                As required or permitted by law, such as to disclose personal
                data in response to lawful requests by public authorities,
                including to meet national security or law enforcement
                requirements, comply with a subpoena, bankruptcy proceedings, or
                similar legal process. For example, we may share information
                with regulatory authorities and law enforcement officials when
                we believe in good faith that such disclosure is necessary to
                comply with legal requirements. We may share information with
                third parties where appropriate to protect against fraud or
                verify or enforce our terms of use, our other rights, or other
                applicable policies.
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                In connection with a merger, acquisition, or sale of all or a
                portion of its assets; you will be notified via email and/or a
                prominent notice on our website of any change in ownership, uses
                of your personal information, and choices you may have regarding
                your personal information.
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Between and among our current and future parents, affiliates,
                subsidiaries and other companies under common control and
                ownership; and
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                With your consent or at your direction.
              </li>
              We may also share aggregated or de-identified information, which
              cannot reasonably be used to identify you.
              <div style={{ padding: "15px" }}></div>
              <h2 className="">
                Changing your
                <span style={{color:'#00b6e3'}}>Data Collection Settings</span>
              </h2>
              We strive to provide you with choices regarding the personal
              information you provide to us. You may need to adjust the settings
              on your computer and/or device to restrict the collection of
              information by the Online Services, but this may prevent you from
              accessing all of the features of the Online Services.
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Tracking Technologies: You may be able to set your browser or
                device to refuse all or some browser cookies, or to alert you
                when cookies are being sent. If you disable or refuse cookies or
                block the use of other tracking technologies, some parts of the
                Services may then be inaccessible or not function properly.
              </li>
              <li style={{ paddingBottom: "20px",textAlign: "justify" }}>
                
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Location Information: You can choose whether or not to allow the
                Online Services to collect and use real-time information about
                your device’s location through the device’s privacy settings. If
                you block the use of location information, some parts of the
                Online Services may then be inaccessible or not function
                properly. For example, you have the ability to only allow manual
                geolocation (rather than using their device’s auto locating
                functionality), but the provision of at least one piece of
                location data (i.e. you search location) may be a requirement
                for the Online Services to function.
              </li>
              <h3 style={{color:'#00b6e3'}}>Children Under Thirteen</h3>
              <div style={{ padding: "15px" }}></div>
              We do not knowingly collect personal information from children
              under the age of thirteen (13). If you are under the age of
              thirteen please do not submit any personal data through the Online
              Services.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{color:'#00b6e3'}}>Security</h3>
              <div style={{ padding: "15px" }}></div>
              We take our responsibility to protect the privacy and
              confidentiality of your information, including personal
              information, very seriously. We take reasonable measures to help
              protect information about you from loss, theft, misuse and
              unauthorized access, disclosure, alteration and destruction. The
              safety and security of your information also depends on you. The
              information you share in public areas may be viewed by any person
              using the Services. Unfortunately, the transmission of information
              via the internet and mobile services is not completely secure.
              Although we do our best to protect your personal information, we
              cannot guarantee the security of your personal information
              transmitted through our Online Services. Any transmission of
              personal information is at your own risk. We are not responsible
              for circumvention of any privacy settings or security measures we
              provide.
              <br />
              <br />
              If you suspect that there has been a breach of the security of
              your data you should contact us at
              <a href="mailto:info@cryptoassetrating.com">
                info@cryptoassetrating.com
              </a>
              and include details of the nature of the breach, the date of the
              breach, and the full circumstances of the breach.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{color:'#00b6e3'}}>Your Choices</h3>
              <div style={{ padding: "15px" }}></div>
              We provide you with relevant and appropriate choices regarding the
              sharing of personal information. CAR will provide you with details
              about whether we hold any of your personal information. If you
              wish to request access, or deletion of your personal information
              please contact us at
              <a href="cryptoassetrating.com/Home/Contact">Contact</a> Note that
              we may retain certain information as required by law or for
              legitimate business purposes. We may also retain cached or
              archived copies of information about you for a certain period of
              time. We will respond to your request within a reasonable
              timeframe, usually within five business days.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{color:'#00b6e3'}}>Changes to this Statement</h3>
              We will occasionally update this Privacy Policy to reflect company
              and customer feedback. We encourage you to periodically review
              this Policy to be informed of how we are protecting your
              information.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{color:'#00b6e3'}}>Contact us</h3>
              If you have questions or concerns about this Privacy Policy,
              please contact us through the Crypto Asset Rating website:
              <a href="/Home/Contact">Rating</a>
              <div style={{ padding: "15px" }}></div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
