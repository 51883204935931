import React, { useEffect, useState } from 'react';
import { Empty, Space, Divider, Card, Col, Row, Table, Progress ,Statistic , Button} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import { setConstantValue } from 'typescript';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const colorConfiguration = {
    textAlign: 'left',
    color: '#1890ff',
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'left'
}

export default () => {
    const { userInfo, setUserInfo } = useUserContext();
    const [totalInvestor, setTotalInvestor] = useState();
    const [sharePercentage, setSharePercentage] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [tokenDetails, setTokenDetails ] = useState(null);
    const netEstimatedDataSource = [];
    const netEstimatedColumns = [
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            key: 'exchange',
        },
           
        {
            title: 'Estimated Value',
            dataIndex: 'estimatedValue',
            key: 'estimatedValue',
        }
    ];

    const transactionDataSource = [];
    const transactionColumns = [
        {
            title: 'Tokens',
            dataIndex: 'tokens',
            key: 'tokens',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Sent | Recived',
            dataIndex: 'sent|recived',
            key: 'sent|recived',
        },
        {
            title: 'From | To',
            dataIndex: 'from|to',
            key: 'from|to',
        }
    ];

    useEffect(() => {
        (async () => {
            const result = await new InvestorService().getPayments({ investorId: userInfo._id });
            if (result.success && result.data) {
                setPaymentDetails(result.data.filter(t => t.accept === true));
            }
        })();
    }, [])



    return (
        <>
            <Row>
                <Card bordered={true} style={{ width: "40%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> My Tokens </Divider>
                }>
                    {paymentDetails?.map(pd => {
                    return (<>
                    <Button  onClick = {() => setTokenDetails(pd)}> { pd?.no_of_tokens + " " + pd?.token?.tokenSymbol} </Button>
                    </>);    
                    })}
                </Card>

                <Card bordered={true} style={{ width: "60%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Token Details </Divider>
                }>
                {tokenDetails == null ? <Empty></Empty> : < div style = {{fontSize: '16px'}}>                 
                <b>Regulation </b> : {tokenDetails?.token?.regulation} <br></br>
                <b>Token Values </b>: {tokenDetails?.token?.pricePerToken} <br></br>
                <b>Amount Paid </b> : {tokenDetails?.amountToInvest} <br></br>
                <b>Type of Security </b> : {tokenDetails?.token?.typeOfSecurity} <br></br>
                <b>Issuer </b>: {tokenDetails?.company} <br></br>
                <b>Mode Of Fund Transfer</b> : {tokenDetails?.modeOfFundTransfer} <br></br>
                </div> }             
                </Card>

            </Row>

            <Row>
                <br></br>
            </Row>

            <Row>
                <Card bordered={true} style={{ width: "70%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Net Estimated Value </Divider>
                }>
                    Last Updated On : {lastUpdatedDate}
                    <br></br>
                    <br></br>
                    <Table dataSource={netEstimatedDataSource} columns={netEstimatedColumns} />
                </Card>

                <Card bordered={true} style={{ width: "30%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Investors & Shares </Divider>
                }>
                    <Statistic title="Total Investors" value={totalInvestor} />
                    <br></br>
                    Your Shares : 
                    <br></br>
                    <br></br>
                    <Progress  type="circle" percent={sharePercentage}  />      
                </Card>
            </Row>

            <Row>
                <br></br>
            </Row>

            <Row>
                <Card title={
                    <Divider orientation="left" style={colorConfiguration}> Transactions </Divider>
                } bordered={true} style={{ width: "100%" }}>

                    <Table dataSource={transactionDataSource} columns={transactionColumns} />;

                </Card>
            </Row>
        </>
    )
}