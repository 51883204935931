import { MetamaskService } from '../../../Metamask.service';
import { WhitelistedWallet } from '../../../interfaces';

export default class WhitelistFacet {
  private contract = {
    ABI: require('./ABI.json')
  };
  
  private get web3() {
    return MetamaskService.web3;
  }
  
  async addWhitelistedWallet(tokenAddress: string, owner: string, _from: string, _isAffiliate: boolean, _isFromUSA: boolean, _isAccredetedInvestor: boolean) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addWhitelistedWallet(_from, _isAffiliate, _isFromUSA, _isAccredetedInvestor).send({ from: owner });
  }

  async addWhitelistedWalletMulti(tokenAddress: string, owner: string, _from: string[], _isAffiliate: boolean[], _isFromUSA: boolean[], _isAccredetedInvestor: boolean[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addWhitelistedWalletMulti(_from, _isAffiliate, _isFromUSA, _isAccredetedInvestor).send({ from: owner });
  }

  async removeWhitelistedWallet(tokenAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeWhitelistedWallet(_from).send({ from: owner });
  }

  async getWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelist().call()) as WhitelistedWallet[];
  }

  async getWhitelistedWallet(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelistedWallet(_from).call()) as WhitelistedWallet;
  }

  async getTotalWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getTotalWhitelist().call()) as string;
  }

  async getAffiliatesWithApprovedFileNotice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getAffiliatesWithApprovedFileNotice().call()) as string[];
  }

  async approveFiledNoticeWithSEC(tokenAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.approveFiledNoticeWithSEC(_from).send({ from: owner });
  }

};

// Not Implemented
// function getTotalAffiliatesWithApprovedFileNotice() external view returns (uint256) {
// }
// function removeFiledNoticeWithSECApproval(address _from, uint256 _months) external onlyFacet {
// }
