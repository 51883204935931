import React from 'react';
import { Row, Col, Typography, Card, Button } from 'antd';
import logo from '../../assets/metamask1.png';
import { MetamaskService } from '../Shared/Metamask.service';
import Web3 from 'web3';

const { Text, Title } = Typography;

export default (prop) => {

  const {isMetamaskInstalled, isMetamaskAuthenticated, setSelectedWallet, setNetworkId, setIsMetamaskAuthenticated} = prop;


  const connectWallet = async() => {
    try {

      MetamaskService.ethereum = window['ethereum'];
      
      MetamaskService.web3 = new Web3(MetamaskService.ethereum);

      
      await MetamaskService.ethereum.request({ method: 'eth_requestAccounts' });
      const wallets = await MetamaskService.ethereum.request({ method: 'eth_accounts' });

      console.log(wallets);
      
      MetamaskService.isMetamaskAuthenticated = true;
      
      setSelectedWallet(wallets[0]);
      
      MetamaskService.ethereum.on('accountsChanged', (accounts: string[]) => setSelectedWallet(accounts[0]));
      
      if(MetamaskService.ethereum.hasOwnProperty("autoRefreshOnNetworkChange")) MetamaskService.ethereum.autoRefreshOnNetworkChange = false; //COMMENT IN FUTURE
      
      setNetworkId(MetamaskService.ethereum.networkVersion);
      
      MetamaskService.ethereum.on('networkChanged', (networkId: string) => setNetworkId(networkId));
      
    } catch (err) {
      MetamaskService.isMetamaskAuthenticated = false;
      console.error(err);
      if (err.message === 'User rejected provider access' || err.message === 'User denied account authorization') {
        console.log("The user didn't want to sign in!");
      } else {
        // This shouldn't happen, so you might want to log this...
        console.log('There was an issue signing you in.');
      }
    }

    setIsMetamaskAuthenticated(MetamaskService.isMetamaskAuthenticated);

  }

  return (
    <div style={{background: '#f0f2f5', minHeight:'100vh'}}>
      <br/><br/>
      <Row justify="center">
        <Col span={16}>
          <Card>
            <Row justify="center">
              <Col span={16} style={{textAlign:'center'}}>
                <Title>
                  To Proceed, Connect TAP with Metamask
                </Title>
                <Text>
                  TAP uses the Metamask plugin to associate your profile and security token with your wallet. This association allows your wallet to be the key to controlling your security token. To use TAP, simply open the metamask extension in your browser and follow the instructions. Ethereum blockchains allowed are Kovan and Main network
                </Text>
                {isMetamaskInstalled && isMetamaskAuthenticated === false && 
                  <div>
                      <br/>
                      <Button size='large' type='primary' onClick={connectWallet}>
                        Connect Wallet
                      </Button>
                  </div>
                }
              </Col>
              <Col span={8}>
                <img src={logo} alt="" width="100%"/>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}