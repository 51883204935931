// import * as firebase from 'firebase/app';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

// const headers = {
//   'Content-Type': 'application/json'
// }
const sharedService = new SharedService();


export class PlatformSuperAdminService {

  private APIURL = environment.APIURL;

  async getRoles(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformSuperAdmin/getRoles`,{headers: await sharedService.getAuthHeader()});    
  }


  async getUserByRole(role:any){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformSuperAdmin/getUserByRole`,{params:role,headers: await sharedService.getAuthHeader()});
  }
  async setUserRole(user:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformSuperAdmin/setUserRole`,user,{headers: await sharedService.getAuthHeader()})
  }
  async updateUserRole(user:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformSuperAdmin/updateUserRole`,user,{headers: await sharedService.getAuthHeader()})
  }
  async addServiceProvider(user:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformSuperAdmin/addServiceProvider`,user,{headers: await sharedService.getAuthHeader()})
  }
  async getIssuers()
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformSuperAdmin/getIssuers`,{headers: await sharedService.getAuthHeader()})
  }

}