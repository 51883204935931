import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Spin,
  Descriptions,
  Form,
  Radio,
  notification
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { User } from '../../../Shared/interfaces';
import { AuthService } from '../../../Shared/Auth.service';
import { SharedService } from '../../../Shared/Shared.service';
import { AdminService } from '../Admin.service';
import moment from 'moment';
// import { SharedService } from '../../../Shared/Shared.service';
const { Title } = Typography;
const adminService = new AdminService();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();
// const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default () => {
  const { userInfo } = useUserContext();
  // const {selectedWallet, networkId} = useSelectedWalletContext();
  const history = useHistory();
  const [filter, setFilter] = useState({ limit: 10, searchPage: 1 });
  const [teamMembers, setTeamMembers] = useState<User[] | null>();
  const [loadingMoreTeamMembers, setLoadingMoreTeamMembers] = useState<boolean>();
  const [canLoadMoreTeamMembers, setCanLoadMoreTeamMembers] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      let [_teamMembers] = (await Promise.all([
        adminService.getCompanyMembers(filter.limit, 1)
      ])).map(res => res.data)
      _teamMembers = _teamMembers.filter(teamMember => teamMember['authorizer'] !== true)

      setTeamMembers(_teamMembers);
      setFilter((prev: any) => ({ ...prev, searchPage: prev.searchPage + 1 }));
      setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
    })();
  }, [userInfo, filter.limit]);

  const loadMoreTeamMembers = async () => {
    if (loadingMoreTeamMembers || !teamMembers || !userInfo) return;
    setLoadingMoreTeamMembers(true);
    const response = await adminService.getCompanyMembers(filter.limit, filter.searchPage);
    const _teamMembers: User[] = response.data;
    setTeamMembers(prev => [...prev as any, ..._teamMembers]);
    setLoadingMoreTeamMembers(false);
    setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
  }

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'firstName'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ADMIN ROLES',
      dataIndex: 'roles',
      render: (roles: string[], record: User) => (
        sharedService.adminRolesOptions.find(opt => roles.includes(opt.value)) ?
          sharedService.adminRolesOptions.filter(opt => roles.includes(opt.value)).map(opt => opt.name).join('| ')
          :
          'None'
      )
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'displayableTeamDepartment',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'TEAM ROLE',
      dataIndex: 'displayableTeamRole',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'AFFILIATE',
      dataIndex: 'isAffiliate',
      render: (value: User['isAffiliate']) => (value ? 'Affiliate' : 'Not Affiliate')
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: User['creationTS']) => moment(value).format('LLL'),
    }
  ];
  const selectTeamMembersFromTable = (selectedRows: User[]) => {
    const newData = sharedService.clone(teamMembers);
    newData?.forEach(member => member['selected'] = selectedRows.map(row => row._id).includes(member._id));
    setTeamMembers(newData);
  }

  const addAuthorizers = async () => {
    if (!teamMembers?.find(member => member['selected'])) return;
    setSubmitting(true);
    const response = await adminService.addAuthorizers(
    {
     teamMembersIds: teamMembers.filter((member => member['selected'])).map(member => member._id)
    });
    if (response.success) {
      notification.success({ message: 'Success', description: 'Authorizer(s) selected successfully' });
      history.push(`/issuer-super-admin/admin/admin-roles`);
    } else 
    {
      notification.error({ message: 'Error', description: response.error.message });
    }
    setSubmitting(false);
  }

  return (
    <>
      <br /><br />
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Title level={1} style={{ textAlign: 'center' }}>Add Authorizers</Title>
            <Table
              columns={columns}
              dataSource={(teamMembers as User[])}
              loading={!teamMembers}
              rowKey='_id'
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: teamMembers?.filter((member => member['selected'])).map(member => member._id),
                onChange: (selectedRowKeys, selectedRows) => {
                  selectTeamMembersFromTable(selectedRows);
                },
              }}
              footer={() =>
                <div style={{ textAlign: 'center' }}>
                  {
                  teamMembers && canLoadMoreTeamMembers 
                  && <Button onClick={loadMoreTeamMembers}><PlusOutlined />Load more</Button>
                  }
                </div>
              }
            />
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: '5px' }} htmlType='button' size='large' onClick={() => history.push(`/issuer-super-admin/admin/admin-roles`)}>
                BACK
              </Button>
              <Button htmlType='submit' size='large' type='primary' onClick={addAuthorizers} loading={submitting}>
                ADD
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};