import { Button, Card, Col, Form, Input, notification, Result, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../Shared/Auth.service';
import { RecoveryRequest } from '../../Shared/interfaces';
import { SharedService } from '../../Shared/Shared.service';
import { RecoveryRequestsService } from '../RecoveryRequests.service';



const {Title} = Typography;
const sharedService = new SharedService();
const recoveryRequestsService = new RecoveryRequestsService();
const useUserContext = () => new AuthService().useUserContext();
const { Option } = Select;

export default function RecoveryRequestsNew() {
  const {userInfo} = useUserContext();

  const [recoveryProcessReq, setRecoveryProcessReq] = useState<RecoveryRequest>();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingReq, setLoadingReq] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [companyId, setCompanyId] = useState<string>('');
  const [tokenSymbolsPurchased, setTokenSymbolsPurchased] = useState<{tokenSymbol: string, companyId: string }[]>();

  useEffect(() => {
    (async () => {

      if(!userInfo) return;

      if(userInfo.role === 'investor') {
        const _tokenSymbolsPurchased = (await recoveryRequestsService.getInvestorTokenSymbols()).data;
        setTokenSymbolsPurchased(_tokenSymbolsPurchased);

      } else {
        const _companyId = userInfo.company?.id as string;
        setCompanyId(_companyId);
  
        // const _recoveryProcessReq = (await recoveryRequestsService.getRecoveryProcessRequestByUserId({companyId: _companyId})).data;
        // setRecoveryProcessReq(_recoveryProcessReq);
      }
  
      setLoading(false);

    })();
  },[userInfo]);


  useEffect(() => {
    (async () => {
      if(!companyId) return;

      // if(userInfo?.role !== 'investor' && userInfo?.company?.id) return;
      setLoadingReq(true);

      const _recoveryProcessReq = (await recoveryRequestsService.getRecoveryProcessRequestByUserId({companyId})).data;
      setRecoveryProcessReq(_recoveryProcessReq);

      setLoadingReq(false);

    })();
  },[companyId]);


  const requestRecoveryProcess = async(formValue) => {
    const req = sharedService.clone(formValue);
    req.companyId = companyId;

    setSubmitting(true);

    const response = await recoveryRequestsService.requestRecoveryProcess({...req});

    if (response.success) {
      notification.success({message: 'SUCCESS', description: 'Request sent successfully'});
      setRecoveryProcessReq({
        _id: response.data,
        companyId, 
        userId: userInfo?._id as string,
        apiKey: environment.apiKey,
        oldWallet: userInfo?.wallet as string,
        newWallet: req.wallet,
        reason: req.reason,
        creationTS: Date.now()
      });
  
    } else {
      console.error(response.error);
      notification.error({message: 'ERROR', description: response.error.message});
    }

    setSubmitting(false);
  }


  const selectCompanyId = (_companyId: string) => {
    setCompanyId(_companyId);
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={3} style={{textAlign:'center'}}>Securities Recovery Process</Title>

              {
                (userInfo?.role === 'investor' && tokenSymbolsPurchased?.length as number>0) &&
                <div style={{textAlign: 'center'}}>
                  <br/>
                  <Select size="large" showSearch placeholder="Select Token Symbol" onChange={e => selectCompanyId(e as string)}>
                    {tokenSymbolsPurchased?.map(tokenSymbolPurchased => 
                      <Option key={tokenSymbolPurchased.tokenSymbol} value={tokenSymbolPurchased.companyId}>{tokenSymbolPurchased.tokenSymbol}</Option>
                    )}
                  </Select>
                  <br/><br/>
                </div>
              }

              {loadingReq && 
                <div style={{textAlign:'center'}}>
                  <br/><br/>
                  <Spin size='large'/>
                </div>
              }

              {!loadingReq && 
                <>
                  {!companyId && 
                    <>
                      {!tokenSymbolsPurchased?.length && 
                        <Result title={`You have to invest in one Token first`}/>
                      }
                      {tokenSymbolsPurchased?.length as number>0 && 
                        <Result title={`Select one of your Tokens`}/>
                      }
                    </>
                  }

                  {companyId && 
                    <>
                      {recoveryProcessReq && 
                        <Result title={`Your Request is under review`}
                        />
                      }

                      {!recoveryProcessReq && 
                        <>
                          <Form labelAlign="left" form={form} onFinish={requestRecoveryProcess}>
                          
                            <Form.Item
                              label="Reason"
                              name='reason'
                              labelCol={{span: 10, push:4}}
                              wrapperCol={{span: 10}}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                }
                              ]}>
                                <Input.TextArea/>
                            </Form.Item>

                            <Form.Item
                              label="New Wallet"
                              name='wallet'
                              labelCol={{span: 10, push:4}}
                              wrapperCol={{span: 10}}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                }
                              ]}>
                                <Input/>
                            </Form.Item>

                            <div style={{textAlign:'right'}}>
                              <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                                SUBMIT
                              </Button>
                            </div>

                          </Form>
                        </>
                      }
                    </>
                  }
                </>
              }


            </Card>
            
          }
        </Col>
      </Row>
    </>
  );
}