import React, { useEffect, useState, useRef } from 'react';
import { SharedService } from '../../Shared/Shared.service';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin.service';
import { AuthService } from '../../Shared/Auth.service';
import {
  List,
  Checkbox,
  Row,
  Input,
  Typography,
  Card,
  Col,
  Button,
  Divider,
} from 'antd';

const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const auth = new AuthService();
const useUserContext = () => auth.useUserContext();

const AllowCountries = () => {
  const { userInfo } = useUserContext();
  const [countries, setCountries] = useState<any[]>([]); 
  const [filterCountries, setFilterCountries] = useState<any[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [check, setCheck] = useState(new Map());
  const [removeCheck, setRemoveCheck] = useState(new Map());
  const search = useRef<any>(null);
  const removeSearch = useRef<any>(null);
  const { Title } = Typography;

  useEffect(() => {
    (async () => {
      let response = await sharedService.getCountries();
      let updatedResponse = response.data.map((country) => country.value.toUpperCase().replaceAll('-', ' '));
      setCountries(updatedResponse);
      response = await issuerTokenAdminService.getAllowedCountries({ companyId: userInfo?.company?.id });
      setSelectedCountries(response.data);
      updatedResponse = updatedResponse.filter((country) => !response.data.includes(country));
      setFilterCountries(updatedResponse);
    })();
  }, [userInfo]);

  const onClick = async () => {
    let result = Array.from(check).flatMap((c) => (c[1] === true && !selectedCountries.includes(c[0])) ? c[0] : []);
    if (result.length === 0) {
      alert('Please Select A Country ...');
    }
    let allowedCountries = [...result, ...selectedCountries];
    await issuerTokenAdminService.setAllowedCountries({
      allowedCountries,
      companyId: userInfo?.company?.id,
    });
    search.current.state.value = '';
    setCheck(new Map());
    setFilterCountries(countries.filter((c) => !allowedCountries.includes(c)));
    setSelectedCountries(allowedCountries);
  }

  const onRemoveClick = async (e) => {
    let result = Array.from(removeCheck).flatMap((c) => (c[1] === true) ? c[0].substring(0, c[0].length - 9) : []);
    if (result.length === 0) {
      alert('Please Select A Country ...');
    }
    console.log(result);
    console.log(selectedCountries);
    let allowedCountries = countries.filter((c) => !result.includes(c) && !filterCountries.includes(c));
    console.log(allowedCountries);
    await issuerTokenAdminService.setAllowedCountries({
     allowedCountries,
     companyId: userInfo?.company?.id,
    });
    removeSearch.current.state.value = '';
    setRemoveCheck(new Map());
    setFilterCountries([...result, ...filterCountries]);
    setSelectedCountries(allowedCountries);
  }

  const onChange = (e) => {
    check.set(e.target.id, e.target.checked);
    setCheck(check);
  }

  const onRemoveChange = (e) => {
    removeCheck.set(e.target.id, e.target.checked);
    setRemoveCheck(removeCheck);
  }

  const onSearch = (e) => {
    const filterList = countries.filter((country) => (country.startsWith(e.target.value.toUpperCase()) && !selectedCountries.includes(country)));
    setFilterCountries(filterList);
  }

  const onRemoveSearch = (e) => {
    const selectedList = countries.filter((country) => (country.startsWith(e.target.value.toUpperCase()) && !filterCountries.includes(country)));
    setSelectedCountries(selectedList);
  }

  const onSearchClick = () => {
    console.log(removeSearch.current.state.value);
    removeSearch.current.state.value = '';
    console.log(removeSearch.current.state.value);
    const selectedList = countries.filter((country) => !filterCountries.includes(country));
    setSelectedCountries(selectedList);
  }

  const onRemoveSearchClick = () => {
    console.log(search.current.state.value);
    search.current.state.value = '';
    console.log(search.current.state.value);
    const filterList = countries.filter((country) => !selectedCountries.includes(country));
    setFilterCountries(filterList);
  }

  const onAddAll = async () => {
    let allowedCountries = [...filterCountries, ...selectedCountries];
    await issuerTokenAdminService.setAllowedCountries({
      allowedCountries,
      companyId: userInfo?.company?.id,
    });
    setCheck(new Map());
    setFilterCountries([]);
    setSelectedCountries(allowedCountries);
  }

  const onRemoveAll = async () => {
    await issuerTokenAdminService.setAllowedCountries({
      allowedCountries : [],
      companyId: userInfo?.company?.id,
    });
    setRemoveCheck(new Map());
    setFilterCountries([...selectedCountries, ...filterCountries]);
    setSelectedCountries([]);
  }

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                fontWeight: 'bold'
              }}
            >
              Transfer Restriction
          </Title>
            <Divider></Divider>
            <Row>
              <Col span={10} offset={1}>
                <h4> Select Countries To Allow Exchange  </h4>
                <Input ref={search} size='large' onClick={onSearchClick} onChange={onSearch} placeholder='Search'></Input>
                <div style={{
                  height: "50vh",
                  overflow: 'scroll',
                }}>
                  <List bordered>
                    {
                      filterCountries.sort().map((country) =>
                        <List.Item key={country}>
                          <Checkbox
                            id={country}
                            onChange={onChange}
                          >{country}</Checkbox>
                        </List.Item>)
                    }
                  </List>
                </div>
                <Row align='bottom' justify='end'>
                  <Col>
                    <Button style={{
                      marginTop: 15,
                      marginRight: 15
                    }}
                      type='primary'
                      onClick={onAddAll}> Add All </Button>
                  </Col>
                  <Col>
                    <Button style={{
                      marginTop: 15
                    }}
                      type='primary'
                      onClick={onClick}> Add </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={10} offset={2}>
                <h4> Selected Country </h4>
                <Input ref={removeSearch} size='large' onClick={onRemoveSearchClick} onChange={onRemoveSearch} placeholder='Search'></Input>
                <div style={{
                  height: '50vh',
                  overflow: 'scroll',
                }}>
                  <List bordered>
                    {
                      selectedCountries.sort().map((country) =>
                        <List.Item key={`${country}+selected`}>
                          <Checkbox
                            id={`${country}+selected`}
                            onChange={onRemoveChange}
                          >{country}</Checkbox>
                        </List.Item>)
                    }
                  </List>
                </div>
                <Row align='bottom' justify='end'>
                  <Col>
                    <Button style={{
                      marginTop: 15,
                      marginRight: 15
                    }}
                      type='primary'
                      onClick={onRemoveAll}> Remove All </Button>
                  </Col>
                  <Col>
                    <Button style={{
                      marginTop: 15
                    }}
                      onClick={onRemoveClick}
                      type='primary'
                    > Remove </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default AllowCountries;
