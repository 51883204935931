import { MetamaskService } from '../../../Metamask.service';

export default class TransferFacet {
  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async transfer(tokenAddress: string, owner: string, _to: string, _value: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.transfer(_to, _value).send({ from: owner });
  }

  async regTransfer(tokenAddress: string, owner: string, _to: string, _value: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.regTransfer(_to, _value, _launchIndex).send({ from: owner });
  }

  async burnAllRegs(tokenAddress: string, owner: string, target: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.burnAllRegs(target).send({ from: owner });
  }

  async issueReg(tokenAddress: string, owner: string, target: string, mintedAmount: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.issueReg(target, mintedAmount, _launchIndex).send({ from: owner });
  }

  async addInvestorLockedAmountMulti(tokenAddress: string, owner: string, lockPeriod: number, _from: string[], _amount: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addInvestorLockedAmountMulti(lockPeriod, _from, _amount).send({ from: owner });
  }

};

//  Not Implemented
// 	function transferFrom(address sender, address recipient, uint256 amount) public virtual returns (bool) {
//   }
//   function issue(address target, uint256 mintedAmount) external onlyFacetOrOwner {
//   }
// 	function issueMulti(address[] calldata _tokenHolders, uint256[] calldata _values) external onlyFacetOrOwner {
//   }
