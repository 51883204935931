import React, { useState } from 'react';
import { Modal, Spin, Divider, Input, Button, Descriptions, message,Row,Col } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';

const {TextArea} = Input;

const sharedService = new SharedService();


export default ({record, reviewBasicInfoVerifReq, ...props}) => {
  const [comments, setComments] = useState<string>();
  
  const submitInfo = async (status:string, comments:string | null) => {
    reviewBasicInfoVerifReq(status, comments);
  }

  return (
    <Modal 
      width={700} 
      {...props}
      footer={record?.status === 'pending'? 
      [
          <Button key="reject" danger disabled={record?.verifiying} loading={record?.rejecting} onClick={() => submitInfo('rejected', comments || null)}>
          Reject
        </Button>,
        <Button key="approve" type="primary" disabled={record?.rejecting} loading={record?.verifiying} onClick={() => submitInfo('verified', comments || null)}>
          Approve
        </Button>
      ] : 
      [
        <Button key="close" onClick={props.onCancel}>
          Close
        </Button>
      ]}>
      {/* {record && 
        <Spin spinning={false} size='large'>
          <div className='modal-details'>
            <Divider orientation="left">Details</Divider>

            <Descriptions bordered column={2}>
              <Descriptions.Item label="Company Name Issuing Tokens">{record.companyName}</Descriptions.Item>
              <Descriptions.Item label="Company Registered Country">{record['displayableCountry']}</Descriptions.Item>
              <Descriptions.Item label="Company Registered State">{record.companyState}</Descriptions.Item>
              <Descriptions.Item label="Zip Code">{record.zipCode}</Descriptions.Item>
              <Descriptions.Item label="Date of Incorporate">{moment(record.dateOfIncorporate).format('LLL')}</Descriptions.Item>
              <Descriptions.Item label="Government Tax ID Number">{record.govTaxIdNumber}</Descriptions.Item>
              <Descriptions.Item label="Company registered documents">
                <a href={record.companyRegFile.URL} download={record.companyRegFile.name} target="_blank" rel="noopener noreferrer">{record.companyRegFile.name}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Tax Number Registration Document">
                <a href={record.taxNumberRegFile.URL} download={record.taxNumberRegFile.name} target="_blank" rel="noopener noreferrer">{record.taxNumberRegFile.name}</a>
              </Descriptions.Item>
              {record.boardResolution.option === 'upload' && 
                <Descriptions.Item label="Board Resolution on token Creation">
                  <a href={record.boardResolution.file?.URL} download={record.boardResolution.file?.name} target="_blank" rel="noopener noreferrer">{record.boardResolution.file?.name}</a>
                </Descriptions.Item>
              }
              {record.additionInfo && 
                <Descriptions.Item label="Addition Info">{record.additionInfo}</Descriptions.Item>
              }
              <Descriptions.Item label="Regulation">{sharedService.regulationName(record.regulation)}</Descriptions.Item>
              <Descriptions.Item label="Issuer Filed for Regulation D Excemption with SEC">{record.SECFilingOption === 'yes'? 'Yes' : 'No'}</Descriptions.Item>
              {record.SECFilingFile && 
                <Descriptions.Item label="SEC filing Document">
                  <a href={record.SECFilingFile?.URL} download={record.SECFilingFile?.name} target="_blank" rel="noopener noreferrer">{record.SECFilingFile?.name}</a>
                </Descriptions.Item>
              }
            </Descriptions>
              
              // <Row>
              //   <Col span={12}>
              //     <p>
              //       <span>Company name Issuing Tokens: </span>{record.companyName}
              //     </p>
              //     <p>
              //       <span>Company Register Country: </span>{record.companyCountryName}
              //     </p>
              //     <p>
              //       <span>State: </span>{record.companyState}
              //     </p>
              //     <p>
              //       <span>Company Registered Doc: </span>{record.companyRegFile.name}
              //     </p>
              //     <p>
              //       <span>Tax No Registration Doc: </span>{record.taxNumberRegFile.name}
              //     </p>
              //     <p>
              //       <span>Board resolution Doc on token creation: </span>{record.boardResolution.file?.name}
              //     </p>
              //   </Col>
              //   <Col span={12}>
              //     <p>
              //       <span>Govt Tax ID No: </span>{record.govTaxIdNumber}
              //     </p>
              //     <p>
              //       <span>Date of Incorporate: </span>{record.dateOfIncorporateString}
              //     </p>
              //     <p>
              //       <span>Email address: </span>{record.email}
              //     </p>
              //     <p>
              //       <span>Regulation: </span> {record.regulationName}
              //     </p>
              //   </Col>
              // </Row> 
              <Divider orientation="left">Comments</Divider>
              {record?.status === 'pending' &&
                <TextArea value={comments} onChange={e => setComments(e.target.value)}></TextArea>
              }
              {record?.status !== 'pending' && record.comments}
              <Divider orientation="left">URL</Divider>
              {record?.status === 'pending' &&
                <Input type="url" value={url}  placeholder="Enter Issuer backend URL or IP address starts with https:// or http://" onChange={e => setURL(e.target.value)}></Input>
              }
              {record?.status !== 'pending' && record?.url}
          </div>
        </Spin> 
      } */}

{record && 
        <Spin spinning={false} size='large'>
          <div className='modal-details'>
            <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                  <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Issuer Basic Information</sub>
              </div>
              <br/>
              <Row>
                <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company Name Issuing Tokens</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.companyName}</sub>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company Registered Country</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record['displayableCountry']}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company Registered State</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.companyState}</sub>
                </div>
              </Col>
                <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Zip Code</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.zipCode}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Date of Incorporate</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(record.dateOfIncorporate).format('LLL')}</sub>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Govt. Tax ID No.</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.govTaxIdNumber}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
                <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company registered documents</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={record.companyRegFile.URL} download={record.companyRegFile.name} target="_blank" rel="noopener noreferrer">{record.companyRegFile.name}</a></sub>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Tax Number Registration documents</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={record.taxNumberRegFile.URL} download={record.taxNumberRegFile.name} target="_blank" rel="noopener noreferrer">{record.taxNumberRegFile.name}</a></sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Regulation</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{sharedService.regulationName(record.regulation)}</sub>
                </div>
              </Col>
              {record.boardResolution.option === 'upload' && 
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Board Resolution On Token Creation</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={record.boardResolution.file?.URL} download={record.boardResolution.file?.name} target="_blank" rel="noopener noreferrer">{record.boardResolution.file?.name}</a></sub>
                </div>
              </Col>
              }
              </Row>
              <br/>
              <Row>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Issuer Filed for Regulation D Excemption with SEC</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.SECFilingOption === 'yes'? 'Yes' : 'No'}</sub>
                </div>
              </Col>
              {record.SECFilingFile && 
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>SEC filing Document</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={record.SECFilingFile?.URL} download={record.SECFilingFile?.name} target="_blank" rel="noopener noreferrer">{record.SECFilingFile?.name}</a></sub>
                </div>
              </Col>
              }
              </Row>
              <br/>
              {record.additionInfo && 
              <>
              <Row>
                <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Addition Info</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.additionInfo}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              </>
              }
              {record?.status === 'pending' && 
              <>
              <Divider orientation="left">Comments</Divider>
              <TextArea value={comments} onChange={e => setComments(e.target.value)}></TextArea>
              </>
              }
              {record?.status !== 'pending' && 
              <>
              <Divider orientation="left">Comments</Divider>
              <p>{ record.comments ?(<>{record.comments}</>):(<>{'---'}</>)}</p>
              </>
              }
              {record?.status !== 'pending' && record.comments}
          </div>
        </Spin> 
      }

    </Modal>
  )
  };
