import React, { useState, useEffect } from 'react';
import { AuthService } from '../../../Shared/Auth.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { TokenConfigurationProcess, SymbolDetailsAndSTData, User, TokenModule, VestingScheduleTemplate, RegLaunched } from '../../../Shared/interfaces';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { InputNumber, Select, Button, Row, Col, Card, Typography, Table, Spin, Result, message, Modal } from 'antd';
// import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import { SharedService } from '../../../Shared/Shared.service';
import { useHistory } from 'react-router-dom';
import TransactionModal from '../../../Shared/TransactionModal';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import moment from 'moment';

//import { VestingService } from '../../../Shared/Vesting/Vesting.service';
import {SecurityTokenService} from '../../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet/index';
import VestingFacet from '../../../Shared/SecurityToken/Facets/VestingFacet/index';

const {Option} = Select;
const {Title} = Typography;
const issuerSuperAdminService = new IssuerSuperAdminService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

const securityTokenService = new SecurityTokenService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();
const mainFacet = new MainFacet();

const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const history = useHistory();

  // const [submitting, setSubmitting] = useState<boolean[]>();
  // const [transactionReceipt, setTransactionReceipt] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  // const [vestingModuleExists, setVestingModuleExists] = useState<boolean>();
  const [vestingModule, setVestingModule] = useState<TokenModule>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfimationModalVisible, setIsConfimationModalVisible] = useState(false);
  // const [vestingScheduleList, setVestingScheduleList] = useState<VestingSchedule[]>();
  const [vestingScheduleTemplates, setVestingScheduleTemplates] = useState<VestingScheduleTemplate[]>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [lastLaunchedReg, setLastLaunchedReg] = useState<string>('');
  
  // const [USDPrice, setUSDPrice] = useState('');
  // const precision = securityTokenService.precision;
  const [teamMembers, setTeamMembers] = useState<User[] | null>();
  const [tokenConfigurationProcess, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>([]);

  // const [totalVestingScheduleOwners, setTotalVestingScheduleOwners] = useState<number>();
  // const offset = 10;
  // const [loadingMoreTeamMembers, setLoadingMoreTeamMembers] = useState<boolean>();
  // const [canLoadMoreTeamMembers, setCanLoadMoreTeamMembers] = useState(true);

  const USDPrice = tokenConfigurationProcess?.pricePerToken && new BigNumber(100).div(tokenConfigurationProcess?.pricePerToken).decimalPlaces(2).toFixed();


  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      setTokenConfigurationProcess(_tokenConfigurationProcess);

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const [ _symbolDetailsAndSTData] = await Promise.all([
        // issuerSuperAdminService.getVestingScheduleList(_tokenConfigurationProcess._id, 0, 0, true),
        securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol),
      ]);

      // const _vestingScheduleList: VestingSchedule[] = responseVestingScheduleList.data;

      
      // setVestingScheduleList(_vestingScheduleList);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);
      
      // update
      // const [_vestingModule, _lastLaunchedReg, _regulationsLaunched] = await Promise.all([
      //   securityTokenService.getVestingModule(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   securityTokenService.regulation(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   securityTokenService.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      // ]);

      const [_vestingModule, _lastLaunchedReg, _regulationsLaunched] = await Promise.all([
        securityTokenService.getVestingModule(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        mainFacet.regulation(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        mainFacet.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);

      const _vestingModuleExists = new BigNumber( _vestingModule.creationDate).isGreaterThan(0);

      // const _vestingScheduleTemplates = _vestingModuleExists? await vestingService.getVestingScheduleTemplates(_vestingModule.moduleAddress) : [];

      const _vestingScheduleTemplates = _vestingModuleExists? await vestingFacet.getVestingScheduleTemplates(_vestingModule.moduleAddress) : [];

      // _vestingScheduleTemplates.forEach(template => template['key'] = template.index);

      const teamMembersWithoutVesting = await issuerSuperAdminService.getTeamMembersWithoutVesting(_vestingModuleExists? _vestingModule.moduleAddress : null);
      // setTotalVestingScheduleOwners(teamMembersWithoutVesting.totalVestingScheduleOwners);
      const _teamMembers = teamMembersWithoutVesting.teamMembers;
      
      setVestingModule(_vestingModule);
      setLastLaunchedReg(_lastLaunchedReg);
      setRegulationsLaunched(_regulationsLaunched);
      // setVestingModuleExists(_vestingModuleExists);
      console.log(_teamMembers)
      setTeamMembers(_teamMembers);
      setVestingScheduleTemplates(_vestingScheduleTemplates);
      // setCanLoadMoreTeamMembers(_teamMembers.length === offset);
      setLoading(false);
        
    })();
    
  }, [userInfo]);


  const columns  = [
    {
      title: '#',
      dataIndex: 'columNumber',
      render: (value: string, record: User) => teamMembers?.findIndex(member => member._id===record._id) as number + 1,
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (value: string, record: User) => `${record.firstName} ${record.lastName}`

    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ROLE',
      dataIndex: 'displayableTeamRole',
    },
    {
      title: 'AFFILIATE/NON AFFILIATE',
      dataIndex: 'isAffiliate',
      render: (value: boolean) => value? 'Affiliate' : 'Non Affiliate'

    },
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'wallet',
      render: (value: string) => <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>{sharedService.minifyAddress(value)}</a>
    },
    {
      title: 'TOTAL AMOUNT (USD)',
      dataIndex: 'USDamount',
      render: (value, record: User) => <InputNumber min={0} value = {value} onChange={e => editTeamTable(e, record, 'USDamount')}/>
    },
    {
      title: `TOTAL AMOUNT (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amount',
    },
    {
      title: 'VESTING SCHEDULE',
      dataIndex: 'vestingScheduleList',
      render: (value, record) => {
        return (
          <Select showSearch placeholder="Select Vesting Schedule" value={value} onChange={e => editTeamTable(e, record, 'vestingScheduleIndex')}>
            {vestingScheduleTemplates?.map(vestingSchedule => 
              <Option key={vestingSchedule.index} value={vestingSchedule.index}>{sharedService.bytes32ToString(vestingSchedule.name)}</Option>
            )}
          </Select>
        );
      }
    },

  ];


  const confirmationColumns  = [
    {
      title: '#',
      dataIndex: 'columNumber',
      render: (value: string, record: User) => teamMembers?.filter(member => member['selected'])?.findIndex(member => member._id===record._id) as number + 1,
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (value: string, record: User) => `${record.firstName} ${record.lastName}`

    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ROLE',
      dataIndex: 'displayableTeamRole',
    },
    {
      title: 'AFFILIATE/NON AFFILIATE',
      dataIndex: 'isAffiliate',
      render: (value: boolean) => value? 'Affiliate' : 'Non Affiliate'

    },
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'wallet',
      render: (value: string) => <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>{sharedService.minifyAddress(value)}</a>
    },
    {
      title: 'TOTAL AMOUNT (USD)',
      dataIndex: 'USDamount',
      render: (value: string) => <span style={{color:'orange', fontWeight: 'bold'}}>{value}</span>
    },
    {
      title: `TOTAL AMOUNT (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amount',
    },
    {
      title: 'VESTING SCHEDULE',
      dataIndex: 'vestingScheduleIndex',
      render: (value, record) => sharedService.bytes32ToString(vestingScheduleTemplates?.find(vestingSchedule => vestingSchedule.index === value)?.name as string)
    },

  ];



  const editTeamTable = (value: any, record: User, dataIndex: 'USDamount' | 'vestingScheduleIndex') => {
    if(!value && value !== 0 && value !== false) return;

    const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);

    const newData = sharedService.clone(teamMembers) as User[];
    const index = newData.findIndex(member => member._id === record._id);
    
    if(dataIndex === 'USDamount') {
      if(!USDPrice) return message.error('Enter Price');
      value = new BigNumber(value).decimalPlaces(2).toNumber();
      newData[index]['amount'] = new BigNumber(value).times(USDPrice).decimalPlaces(decimals).toFixed();
    }

    newData[index][dataIndex] = value;

    // newData[index] = {...newData[index], [dataIndex]: value};
    setTeamMembers(newData);
  };

  const selectTeamMembersFromTable = (selectedRowKeys: string[]) => {
    const newData = [...teamMembers];
    newData.forEach(member => member['selected'] = selectedRowKeys.includes(member._id));
  }


  const openConfirmationModal = () => {
    if(!teamMembers?.length) return;

    const selectedTeamMembers = teamMembers.filter(member => member['selected']);
    console.log(selectedTeamMembers);

    if(!selectedTeamMembers.length) return message.error('Select users');
    if(selectedTeamMembers.find(member => !member['amount'])) return message.error('Enter all the amounts for selected users');
    if(selectedTeamMembers.find(member => !member['vestingScheduleIndex'])) return message.error('Enter all the vestings for selected users');

    const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);
    
    const _from: string[] = [];
    const _amount: string[] = [];
    const _index: string[] = [];

    selectedTeamMembers.forEach(member => {
      const value = new BigNumber(member['amount']).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toString(10);

      _from.push(member.wallet as string);
      _amount.push(value);
      _index.push(member['vestingScheduleIndex']);
    });

    if(_amount.find(value => !(new BigNumber(value).isGreaterThan(0)))) return message.error('Invalid amounts');


    setIsConfimationModalVisible(true);
  }



  const assignVestingScheduleTemplateMulti = async() => {
    setIsConfimationModalVisible(false);

    if(!teamMembers?.length) return;

    const selectedTeamMembers = teamMembers.filter(member => member['selected']);
    console.log(selectedTeamMembers);

    if(!selectedTeamMembers.length) return message.error('Select users');
    if(selectedTeamMembers.find(member => !member['amount'])) return message.error('Enter all the amounts for selected users');
    if(selectedTeamMembers.find(member => !member['vestingScheduleIndex'])) return message.error('Enter all the vestings for selected users');

    const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);
    
    const _from: string[] = [];
    const _amount: string[] = [];
    const _index: string[] = [];

    selectedTeamMembers.forEach(member => {
      const value = new BigNumber(member['amount']).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toString(10);

      _from.push(member.wallet as string);
      _amount.push(value);
      _index.push(member['vestingScheduleIndex']);
    });

    if(_amount.find(value => !(new BigNumber(value).isGreaterThan(0)))) return message.error('Invalid amounts');


    setIsModalVisible(true);

    setTransactions([
      { details: 'Applying Vesting', submitting: true }
    ]);

    try {

      // const receipt0 = await vestingService.assignVestingScheduleTemplateMulti(
      //   vestingModule?.moduleAddress as string, 
      //   selectedWallet as string,
      //   _from,
      //   _amount,
      //   _index
      // );

      const receipt0 = await vestingFacet.assignVestingScheduleTemplateMulti(
        vestingModule?.moduleAddress as string, 
        selectedWallet as string,
        _from,
        _amount,
        _index
      );


      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });
      

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }

  // const updateUSDPrice = (value: string) => {
  //   if(new BigNumber(value).isGreaterThan(0)) return setUSDPrice(value);
  //   if(!value) return setUSDPrice('');
  // }
  




  
  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={24}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }
          {!loading &&
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Apply Vesting Schedule for {sharedService.regOptions.find(opt => opt.shortValue === lastLaunchedReg)?.name}</Title>
                {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                  <Result
                    title={`Security Token not deployed`}
                    subTitle="You have to deploy your Security Token first"
                    extra={
                      <Button key="console" onClick={() => history.push('/token-configurations')}>
                        Create Token
                      </Button>
                    }/>
                }

                {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                  <>
                    <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                    <Result
                      status="error"
                      title = {
                        <p>
                          Select the wallet {' '}
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                            {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                          </a> 
                          {' '} in order to Apply Vesting
                        </p>
                      }
                    />
                  </>
                }

                
                {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                  <>

                  <Title level={2} style={{textAlign:'center'}}>Date of Launch: {moment(+(regulationsLaunched[regulationsLaunched.length-1].dateLaunched)*1000).format('LLL')}</Title>


                  <div style={{textAlign:'center'}}>
                    <Title level={3}>Token Price</Title>

                    <p style={{display:'inline'}}>1 USD = {USDPrice} {symbolDetailsAndSTData.securityTokenData.symbol}</p>
                    {/* <Input value={USDPrice} onChange={e => updateUSDPrice(e.target.value)} placeholder='Price' style={{display:'inline-block', width: '110px', margin: '0 10px'}}/> */}
                    {/* <p style={{display:'inline'}}>{symbolDetailsAndSTData.securityTokenData.symbol}</p> */}
                    <br/><br/>
                  </div>

                  <Table
                    columns={columns} 
                    dataSource={teamMembers as any[]}
                    // expandable={{expandedRowRender: (record: User) => 
                    //   <div style={{display:'flex', flexDirection:'row', justifyContent: 'center', }}>
                    //     <Descriptions column={2} style={{}}>
                    //       <Descriptions.Item style={{textAlign:'center'}} label="FIRST NAME">
                    //         {record.firstName}
                    //       </Descriptions.Item>
                    //       <Descriptions.Item style={{textAlign:'center'}} label="LAST NAME">
                    //        {record.lastName}
                    //       </Descriptions.Item>
                    //     </Descriptions>
                    //   </div>
                    // }}
                    rowSelection={{
                      type: 'checkbox',
                      onChange: (selectedRowKeys, selectedRows) => {
                        console.log(selectedRowKeys);
                        selectTeamMembersFromTable(selectedRowKeys.map(rowKey => rowKey.toString()));
                        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                      },
                    }}
                    rowKey='_id'
                    pagination={false}/>
                  <div style={{textAlign:'right', marginTop:'20px'}}>
                    <Button style={{marginRight:'5px'}} type='primary' size='large' onClick={openConfirmationModal}>
                      APPLY
                    </Button>
                  </div>
                  </>
                }
            </Card>
          }
        </Col>
      </Row>

      <Modal
        width='1200px'
        title="Please verify and confirm the total amount in USD"
        visible={isConfimationModalVisible}
        okText='Confirm'
        onOk={assignVestingScheduleTemplateMulti}
        onCancel={() => setIsConfimationModalVisible(false)}
      >
        <Table
          scroll={{x:true}}
          columns={confirmationColumns} 
          dataSource={teamMembers?.filter(member => member['selected']) as any[]}
          rowKey='_id'
          pagination={false}/>
      </Modal>

      <TransactionModal
        title = 'Vesting application'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => {setIsModalVisible(false); history.push('/issuer-token-admin/token-managment/create-vesting-schedule')}}
      />

    </>
  );
}