import React, { useEffect, useState, useRef } from 'react'
import { Table, Card, Col, Row, Typography, Descriptions, Button, Tabs, Radio, message, Input, Carousel } from 'antd'
import { IssuerComplianceAdminService } from '../IssuerComplianceAdmin.service'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { AuthService } from '../../Shared/Auth.service'
import { SharedService } from '../../Shared/Shared.service';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import moment from 'moment'

const { Title } = Typography
const { TextArea } = Input;
const { TabPane } = Tabs
const issuerComplianceAdminService = new IssuerComplianceAdminService()

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();

const displayOptions = {
  AfterRejectDivision: '2',
  MainDivision: '1'
}
const subDisplayOptions = {
  RejectView: '1',
  ResubmitView: '2'
}

export default () => {
  const [investors, setInvestors] = useState()
  const { userInfo, setUserInfo } = useUserContext()
  const [detailsDivision, setDetailsDivision] = useState(true)
  const [investorDetails, setInvestorDetails] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [approvedAndRejectedKYCRequests, setInvestorsList] = useState([])
  const [detailsDivision2, setDetailsDivision2] = useState(true)
  const [displayOption, setDisplayOption] = useState(displayOptions.MainDivision);
  const [subDisplayOption, setSubDisplayOption] = useState(subDisplayOptions.ResubmitView);
  const resubmitRadioRef = useRef(null);
  const rejectRadioRef = useRef(null);
  const textBoxRef = useRef(null);
  const columns = [
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'PHONE',
      dataIndex: 'contactNumber',
      key: 'contactNumber'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'dateOfSubmission',
      key: 'creationTS',
      render: text => moment(text).format('LLL')
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: (text, investor) => <Button type='primary' onClick={() => showDetails(investor)}>DETAILS</Button>
    }

  ]

  const columns2 = [
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'PHONE',
      dataIndex: 'contactNumber',
      key: 'contactNumber'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'dateOfSubmission',
      key: 'creationTS',
      render: text => moment(text).format('LLL')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, investor) => {
        return (
          <>
            <b>
              {investor.approvedByICA && <span>Approved</span>}
              {investor.rejected && <span>rejected</span>}
            </b>
          </>

        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, investor) => <Button type='primary' onClick={() => showDetailsForResult(investor)}>DETAILS</Button>
    }

  ]
  const showDetails = investor => {
    setDetailsDivision(false)
    setInvestorDetails(investor)
  }
  const showDetailsForResult = investor => {
    setDetailsDivision2(false)
    setInvestorDetails(investor)
  }
  const rejectKYC = (details) => {
    (async () => {
      setSubmitting(true)
      await delete details._id;
      details.rejected = true
      const response = await issuerComplianceAdminService.updateKYC(details);
      if (response.success) {
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  const acceptKYC = (details) => {
    (async () => {
      setSubmitting(true)
      details.approvedByICA = true
      details.rejected = false
      details.companyName = userInfo.company.name;
      console.log("abhi log", details);
      const response = await issuerComplianceAdminService.updateKYC(details);
      if (response.success) {
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  useEffect(
    () => {
      (async () => {
        let data = { company: userInfo.companyName.toLowerCase() }
        const response = await issuerComplianceAdminService.getInvestorKYCRequests(data);
        setInvestors(response.data)
      }
      )();
    }, [detailsDivision]
  )
  useEffect(
    () => {
      (async () => {
        let data = { company: userInfo.companyName.toLowerCase() }
        const response = await issuerComplianceAdminService.getInvestorsList(data);
        setInvestorsList(response.data)
      }
      )();
    }, [detailsDivision]
  )

  const resubmitKYC = async () => {
    investorDetails.resubmit = true;
    const response = await issuerComplianceAdminService.resubmitKYC(investorDetails);
    if (response.success) {
      setDetailsDivision(true);
    }
    else {
      message.error(response.error);
      return;
    }
  }

  const changeView = (e) => {
    if (e.target.value === 1) setSubDisplayOption(subDisplayOptions.ResubmitView);
    else setSubDisplayOption(subDisplayOptions.RejectView);
  }

  const proceedToRejectWithCheck = () => {
    console.log(resubmitRadioRef);
    if (resubmitRadioRef.current.state.checked) {
      resubmitKYC();
      return;
    }
    else {
      if (!textBoxRef.current.state.value) {
        message.error("Please provide the reason for rejection.");
        return;
      }
      investorDetails.reasonToReject = textBoxRef.current.state.value.trim();
      rejectKYC(investorDetails);
    }
  }

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: 'center' }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
            >
              Investors KYC Request
          </Title>
            <Tabs defaultActiveKey="1" centered>
              <TabPane tab={<span><PlaySquareOutlined />Pending KYC Requests</span>} key="1">
                <div hidden={!detailsDivision}>
                  <Table dataSource={investors} columns={columns} />
                </div>
                <div hidden={detailsDivision}>
                  <Carousel autoplay>
                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Selfie</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.selfie} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Passport</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.passport.URL} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Driving Licence(Front side)</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.licenceFrontSide.URL} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Driving Licence(Back side)</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.licenceBackSide.URL} ></img>
                      </Card>
                    </div>
                  </Carousel>
                  <Descriptions>
                    {investorDetails?.fullResult.state == 'A' && <Descriptions.item label="Automated KYC result"><p style={{ fontWeight: 'bold', color: "green", fontSize: '25px' }}>Approved</p></Descriptions.item>}
                    {investorDetails?.fullResult.state == 'R' && <Descriptions.item label="Automated KYC result"><p style={{ fontWeight: 'bold', color: "yellow", fontSize: '25px' }}>Could not decide. Manual intervention needed.</p></Descriptions.item>}
                    {investorDetails?.fullResult.state == 'D' && <Descriptions.item label="Automated KYC resultt"><p style={{ fontWeight: 'bold', color: "red", fontSize: '25px' }}>Rejected</p></Descriptions.item>}
                  </Descriptions>

                  <Card style={{ margin: 'auto', width: 900, boxShadow: "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)" }}>
                    <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>PROFILE</p>
                    <table style={{ margin: 'auto', width: '100%' }}>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Name</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.firstName} {investorDetails?.lastName}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Present Address</td>
                        <td style={{ textAlign: 'left' }}>{<div>{investorDetails?.pAStreetAddress1} {investorDetails?.pAStreetAddress2} {investorDetails?.pACity} {investorDetails?.pAState} {investorDetails?.pACountry} {investorDetails?.pAZipCode}</div>}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Permanent Address</td>
                        <td style={{ textAlign: 'left' }}>{<div>{investorDetails?.pStreetAddress1} {investorDetails?.pStreetAddress2} {investorDetails?.pCity} {investorDetails?.pAState} {investorDetails?.pACountry} {investorDetails?.pZipCode}</div>}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Contact Number</td>
                        {investorDetails?.contactNumber && <td style={{ textAlign: 'left' }}>{formatPhoneNumberIntl('+' + investorDetails?.contactNumber)}</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Email</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.email}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Last Stage run</td>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>3</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Date of KYC Request</td>
                        <td style={{ textAlign: 'left' }}>{moment(investorDetails?.dateOfSubmission).format('LLL')}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Customer since</td>
                        <td style={{ textAlign: 'left' }}>{moment(userInfo?.creationTS).format('LLL')}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Priority</td>
                        <td style={{ textAlign: 'left' }}>Normal</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Progress</td>
                        <td style={{ textAlign: 'left' }}>New</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Name of fraud rule</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult.frn}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Result of fraud evaluation</td>
                        {investorDetails?.fullResult.frp === 'ACCEPT' && <td style={{ textAlign: 'left', color: 'green' }}>Accepted</td>}
                        {investorDetails?.fullResult.frp !== 'ACCEPT' && <td style={{ textAlign: 'left', color: 'red' }}>Rejected</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Result of policy evaluation</td>
                        {investorDetails?.fullResult.res === 'ACCEPT' && <td style={{ textAlign: 'left', color: 'green' }}>Accepted</td>}
                        {investorDetails?.fullResult.res !== 'ACCEPT' && <td style={{ textAlign: 'left', color: 'red' }}>Rejected</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>User reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.user}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>User previous reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.upr}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Reason for reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.erd}</td>
                      </tr>


                    </table>

                    <p style={{ textAlign: 'right' }}>< Button style={{ color: 'green' }} href={"https://staging.identitymind.com/edna/applications"} target="__blank" >Detailed Report</Button></p>

                  </Card>
                  <br />
                  <br />
                  <br />


                  <div hidden={displayOption !== displayOptions.MainDivision} >
                    <Button onClick={() => acceptKYC(investorDetails)} type='primary' loading={submitting} >Accept</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={() => setDisplayOption(displayOptions.AfterRejectDivision)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={() => setDetailsDivision(true)} disabled={submitting}>Cancel</Button>
                  </div>

                  <div hidden={displayOption !== displayOptions.AfterRejectDivision} id="radioDiv">
                    <Radio.Group onChange={changeView}  >
                      <Radio value={1} ref={resubmitRadioRef} >Ask To Resubmit Details</Radio>
                      <Radio value={2} ref={rejectRadioRef}>Reject</Radio>
                    </Radio.Group>
                    {(subDisplayOption === subDisplayOptions.RejectView) && <><center><table><tr><td>Reason:<sup style={{ color: 'red' }} >*</sup></td><td><TextArea style={{ width: 650 }} ref={textBoxRef} rows={8}></TextArea></td></tr></table></center> </>}
                    <Button type="primary" htmlType="button" onClick={proceedToRejectWithCheck} >Submit</Button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<span><ProjectOutlined />Approved/Rejected KYC Requests</span>} key="2">
                <div hidden={!detailsDivision2} >
                  <Table columns={columns2} dataSource={approvedAndRejectedKYCRequests}></Table>
                </div>
                <div hidden={detailsDivision2}>
                  <Carousel autoplay>
                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Selfie</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.selfie} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Passport</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.passport.URL} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Driving Licence(Front side)</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.licenceFrontSide.URL} ></img>
                      </Card>
                    </div>

                    <div >
                      <Card style={{ backgroundColor: '#ebeff5' }}>
                        <p style={{ fontSize: '25px' }}>Driving Licence(Back side)</p>
                        <img height={300} width={400} style={{ margin: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} src={investorDetails?.licenceBackSide.URL} ></img>
                      </Card>
                    </div>
                  </Carousel>
                  <Descriptions>
                    {investorDetails?.fullResult.state == 'A' && <Descriptions.item label="Automated KYC result"><p style={{ fontWeight: 'bold', color: "green", fontSize: '25px' }}>Approved</p></Descriptions.item>}
                    {investorDetails?.fullResult.state == 'R' && <Descriptions.item label="Automated KYC result"><p style={{ fontWeight: 'bold', color: "orange", fontSize: '25px' }}>Could not decide. Manual review needed.</p></Descriptions.item>}
                    {investorDetails?.fullResult.state == 'D' && <Descriptions.item label="Automated KYC resultt"><p style={{ fontWeight: 'bold', color: "red", fontSize: '25px' }}>Rejected</p></Descriptions.item>}
                  </Descriptions>
                  <Card style={{ margin: 'auto', width: 900, boxShadow: "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)" }}>
                    <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>PROFILE</p>
                    <table style={{ margin: 'auto', width: '100%' }}>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Name</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.firstName} {investorDetails?.lastName}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Present Address</td>
                        <td style={{ textAlign: 'left' }}>{<div>{investorDetails?.pAStreetAddress1} {investorDetails?.pAStreetAddress2} {investorDetails?.pACity} {investorDetails?.pAState} {investorDetails?.pACountry} {investorDetails?.pAZipCode}</div>}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Permanent Address</td>
                        <td style={{ textAlign: 'left' }}>{<div>{investorDetails?.pStreetAddress1} {investorDetails?.pStreetAddress2} {investorDetails?.pCity} {investorDetails?.pAState} {investorDetails?.pACountry} {investorDetails?.pZipCode}</div>}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Contact Number</td>
                        {investorDetails?.contactNumber && <td style={{ textAlign: 'left' }}>{formatPhoneNumberIntl('+'+investorDetails?.contactNumber)}</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Email</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.email}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Last Stage run</td>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>3</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Date of KYC Request</td>
                        <td style={{ textAlign: 'left' }}>{moment(investorDetails?.dateOfSubmission).format('LLL')}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Customer since</td>
                        <td style={{ textAlign: 'left' }}>{moment(userInfo?.creationTS).format('LLL')}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Priority</td>
                        <td style={{ textAlign: 'left' }}>Normal</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Progress</td>
                        <td style={{ textAlign: 'left' }}>New</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Name of fraud rule</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult.frn}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Result of fraud evaluation</td>
                        {investorDetails?.fullResult.frp === 'ACCEPT' && <td style={{ textAlign: 'left', color: 'green' }}>Accepted</td>}
                        {investorDetails?.fullResult.frp !== 'ACCEPT' && <td style={{ textAlign: 'left', color: 'red' }}>Rejected</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Result of policy evaluation</td>
                        {investorDetails?.fullResult.res === 'ACCEPT' && <td style={{ textAlign: 'left', color: 'green' }}>Accepted</td>}
                        {investorDetails?.fullResult.res !== 'ACCEPT' && <td style={{ textAlign: 'left', color: 'red' }}>Rejected</td>}
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>User reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.user}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>User previous reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.upr}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Reason for reputation</td>
                        <td style={{ textAlign: 'left' }}>{investorDetails?.fullResult?.erd}</td>
                      </tr>
                    </table>

                    <p style={{ textAlign: 'right' }}>< Button style={{ color: 'green' }} href={"https://staging.identitymind.com/edna/applications"} target="__blank" >Detailed Report</Button></p>

                  </Card>
                  <br />
                  <br />
                  <Button type="primary" htmlType="button" onClick={() => { setDetailsDivision2(true); }} >BACK</Button>
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  )
}
