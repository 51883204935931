import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();


export class InvestorService {

  private APIURL = environment.APIURL;
  
  async getTokens()
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getTokens`,{headers:await sharedService.getAuthHeader()});
  }
  async getTokenDetails()
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getTokenDetails`,{headers:await sharedService.getAuthHeader()});
  }
  async getBankDetails()
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getBankDetails`,{headers:await sharedService.getAuthHeader()});
  }
  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);
    sharedService.uploadDocument(`${this.APIURL}/investor/uploadDocument`, req, progressCb, errorCb, completeCb)
  }
  async submitKYCDetails(formData:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/submitKYCDetails`,formData,{headers:await sharedService.getAuthHeader()});
  }
  async submitAIDetails(formData:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/submitAIDetails`,formData,{headers:await sharedService.getAuthHeader()});
  }
  async getKYCDetails(investorId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getKYCDetails`,investorId,{headers:await sharedService.getAuthHeader()});
  }
  async getAiDetails(investorId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getAIDetails`,investorId,{headers:await sharedService.getAuthHeader()});
  }
  async getPayments(investorId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getPayments`,investorId,{headers:await sharedService.getAuthHeader()});
  }
  async getCompanyDetails(investorId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getCompanyDetails`,investorId,{headers:await sharedService.getAuthHeader()});
  }
  async setFirstTimeInvestButtonClicked(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/setFirstTimeInvestButtonClicked`,data,{headers:await sharedService.getAuthHeader()});
  }
  async acceptTandC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/setTandCAccepted`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getSignURL(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getSignURL`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getTestSignURL(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getTestSignURL`,data,{headers:await sharedService.getAuthHeader()});
  }
  async handleSigningDocument(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/handleSigningDocument`,data,{headers:await sharedService.getAuthHeader()});
  }
  async saveWireTransferPaymentDetails(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/saveWireTransferPaymentDetails`,data,{headers:await sharedService.getAuthHeader()});
  }

  async getTokenPhaseDetail(companyId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getTokenPhaseDetail`,companyId,{headers:await sharedService.getAuthHeader()});
  }
  async getSubscriptionAgreement(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getSubscriptionAgreement`,data,{headers:await sharedService.getAuthHeader()});
  }
  async signSubscriptionAgreement(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/signSubscriptionAgreement`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getIssuerWebpageConfig(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getIssuerWebpageConfig`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getIssuerTokenInfo(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getIssuerTokenInfo`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getPrimeTrustEntity(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getPrimeTrustEntity`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getIssuerDueDiligence(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getIssuerDueDiligence`,data,{headers:await sharedService.getAuthHeader()});
  }
  

}
