import React, { useState, useEffect } from 'react';
import { CheckSquareOutlined, BorderOutlined } from '@ant-design/icons';
import { Checkbox, InputNumber, Form, Row, Col, Card, Typography, Input, Table, Button, Spin, Result } from 'antd';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationProcess, SymbolDetailsAndSTData, TokenModule } from '../../../../Shared/interfaces';
import { MetamaskService } from '../../../../Shared/Metamask.service';
import { AuthService } from '../../../../Shared/Auth.service';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import TransactionModal from '../../../../Shared/TransactionModal';
import { SharedService } from '../../../../Shared/Shared.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';
import { SecurityTokenService } from '../../../../Shared/SecurityToken/SecurityToken.service';
// import { VestingService } from '../../../../Shared/Vesting/Vesting.service';
import VestingFacet from '../../../../Shared/SecurityToken/Facets/VestingFacet/index';

const {Title} = Typography;
// const {TextArea} = Input;

const securityTokenRegisteryService = new SecurityTokenRegistryService();

// const vestingService = new VestingService();
const securityTokenService = new SecurityTokenService();
const vestingFacet = new VestingFacet();

const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();


const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  // const [submitting, setSubmitting] = useState<boolean>();
  const history = useHistory();

  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [vestingModuleExists, setVestingModuleExists] = useState<boolean>();
  const [vestingModule, setVestingModule] = useState<TokenModule>();

  const [vestingScheduleData, setVestingScheduleData] = useState<any[]>([]);
  const [count, setCount] = useState(0);

  const [form] = Form.useForm();

  const precision = securityTokenService.precision;

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      // setTokenConfigurationProcess(_tokenConfigurationProcess);

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const [_symbolDetailsAndSTData] = await Promise.all([
        securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol),
      ]);

      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const _vestingModule = await securityTokenService.getVestingModule(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      const _vestingModuleExists = new BigNumber(_vestingModule.creationDate).isGreaterThan(0);

      setLoading(false);
      setVestingModule(_vestingModule);
      setVestingModuleExists(_vestingModuleExists);

    })();
    
  // }, [tokenConfigurationProcess, history, filter.limit]);
  },[userInfo]);




  const previousVestingDuration = (record) => {
    const index = vestingScheduleData.findIndex(offer => offer.key === record.key);
    return index? vestingScheduleData[index-1].endOfMonth : 0;
  };

  const totalVestingPercentAcc = (record) => {
    let total = 0;
    const index = vestingScheduleData.findIndex(offer => offer.key === record.key);
    vestingScheduleData.slice(0, index).forEach(({percent}) => total = new BigNumber(total).plus(percent).toNumber());
    return total;
  };
 

  const vestingNumber = record => vestingScheduleData.findIndex(offer => offer.key === record.key) + 1;


  const editVestingTable = (value: any, record, dataIndex: 'isFixed' | 'endOfMonth' | 'percent' | 'lockPeriod') => {
    if(!value && value !== 0 && value !== false) return;

    if(dataIndex === 'endOfMonth') value = new BigNumber(value).decimalPlaces(0).toNumber();
    else if(dataIndex === 'percent') value = new BigNumber(value).decimalPlaces(2).toNumber();

    
    const newData = [...vestingScheduleData];
    const index = newData.findIndex(offer => offer.key === record.key);
    newData[index] = {...newData[index], [dataIndex]: value};

    if(dataIndex === 'endOfMonth' || dataIndex === 'percent') {
      const percentAcc = newData
        .slice(0, index + 1)
        .map(data => data['percent'])
        .reduce((endOfMonth1, endOfMonth2) => new BigNumber(endOfMonth1).plus(endOfMonth2).toNumber());

      const lastVestingMonth = dataIndex === 'endOfMonth'? value : record.endOfMonth;

      if(form.getFieldValue('duration') > lastVestingMonth && 100 > percentAcc) {
        const newVesting = {
          key: count, 
          isFixed: true, 
          endOfMonth: form.getFieldValue('duration'),
          percent: new BigNumber(100).minus(percentAcc).toNumber(),
          lockPeriod: 12
        };

        newData.splice(index + 1, newData.length - index + 1, newVesting);
        setCount(prev => prev + 1);

      } else {
        // if(dataIndex === 'endOfMonth') newData[index].percent = form.getFieldValue('percent') - percentAcc;
        // else newData[index].endOfMonth = form.getFieldValue('duration') - durationAcc;
        newData.splice(index + 1, 1);
      }
    }

    setVestingScheduleData(newData);
  };


  const initVestingTable = (_duration: number | undefined | string) => {
    if(_duration) {
      setVestingScheduleData([{key: 0, isFixed: true, endOfMonth: _duration, percent: 100, lockPeriod: 12}]);
      setCount(1);

    } else {
      setVestingScheduleData([]);
      setCount(0);
    }
  };


  const columns = [
    {
      title: 'Vesting Schedule',
      dataIndex: 'vestingScheduleNumber',
      render: (value: string, record) => `Vesting ${vestingNumber(record)}`
    },
    {
      title: <>Fixed <CheckSquareOutlined /> or divide equaly <BorderOutlined /></>,
      dataIndex: 'isFixed',
      render: (value: boolean, record) => {
        return (
          <div style={{marginLeft:'30%'}}>
            <Checkbox checked={value} onChange={e => editVestingTable(e.target.checked, record, 'isFixed')} />
          </div>
        );
      }
    },
    {
      title: 'End of vesting month',
      dataIndex: 'endOfMonth',
      render: (value: number, record) => {
        return (
          <>
            {!record.isFixed && `${previousVestingDuration(record)+1} - `}
            <InputNumber min={previousVestingDuration(record)+1} max={+form.getFieldValue('duration')} value={value} onChange={e => editVestingTable(e, record, 'endOfMonth')}/>
          </>
        );
 
      }
    },
    {
      title: 'Vesting %',
      dataIndex: 'percent',
      render: (value: number, record) => {
        return <InputNumber min={0} max={new BigNumber(100).minus(totalVestingPercentAcc(record)).toNumber()} value={value} onChange={e => editVestingTable(e, record, 'percent')}/>
      }
    },
    {
      title: 'Lock Period',
      dataIndex: 'lockPeriod',
      render: (value: number, record) => {
        return <InputNumber min={12} value={value} onChange={e => editVestingTable(e, record, 'lockPeriod')}/>
      }
    }
  ];


  const createModuleAndVestingScheduleTemplate = async (_newVestingSchedule: any) => {
    setIsModalVisible(true);

    setTransactions([
      { details: 'Creating Vesting Module (This time only)', submitting: true },
      { details: 'Creating Vesting Schedule' }
    ]);

    const _name: string = _newVestingSchedule.name;
    const duration: number = _newVestingSchedule.duration;
    const isFixedArr: boolean[] = _newVestingSchedule.vesting.map(schedule => schedule.isFixed);
    const endOfMonthArr: number[] = _newVestingSchedule.vesting.map(schedule => schedule.endOfMonth);
    const percentArr: string[] = _newVestingSchedule.vesting.map(schedule => new BigNumber(schedule.percent).times(new BigNumber(10).pow(precision)).toFixed(0));
    const lockPeriodArr: number[] = _newVestingSchedule.vesting.map(schedule => schedule.lockPeriod);
    
    
    try {

      const receipt0 = await securityTokenService.addVestingModule(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if(!receipt0.status) return;


      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });

      const _vestingModule = await securityTokenService.getVestingModule(symbolDetailsAndSTData?.securityTokenData.contractAddress as string);
      const _vestingModuleExists = new BigNumber( _vestingModule.creationDate).isGreaterThan(0);

      setVestingModule(_vestingModule);
      setVestingModuleExists(_vestingModuleExists);

      // const receipt1 = await vestingService.createVestingScheduleTemplate(
      //   _vestingModule.moduleAddress as string, 
      //   selectedWallet as string,
      //   _name,
      //   duration,
      //   isFixedArr,
      //   endOfMonthArr,
      //   percentArr,
      //   lockPeriodArr
      // );

      const receipt1 = await vestingFacet.createVestingScheduleTemplate(
        _vestingModule.moduleAddress as string, 
        selectedWallet as string,
        _name,
        duration,
        isFixedArr,
        endOfMonthArr,
        percentArr,
        lockPeriodArr
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }


  const createVestingScheduleTemplate = async(_newVestingSchedule: any) => {
    setIsModalVisible(true);

    setTransactions([
      { details: 'Creating Vesting Schedule', submitting: true }
    ]);

    const _name: string = _newVestingSchedule.name;
    const duration: number = _newVestingSchedule.duration;
    const isFixedArr: boolean[] = _newVestingSchedule.vesting.map(schedule => schedule.isFixed);
    const endOfMonthArr: number[] = _newVestingSchedule.vesting.map(schedule => schedule.endOfMonth);
    const percentArr: string[] = _newVestingSchedule.vesting.map(schedule => new BigNumber(schedule.percent).times(new BigNumber(10).pow(precision)).toFixed(0));
    const lockPeriodArr: number[] = _newVestingSchedule.vesting.map(schedule => schedule.lockPeriod);
    
    
    try {

      // const receipt0 = await vestingService.createVestingScheduleTemplate(
      //   vestingModule?.moduleAddress as string, 
      //   selectedWallet as string,
      //   _name,
      //   duration,
      //   isFixedArr,
      //   endOfMonthArr,
      //   percentArr,
      //   lockPeriodArr
      // );

      const receipt0 = await vestingFacet.createVestingScheduleTemplate(
        vestingModule?.moduleAddress as string, 
        selectedWallet as string,
        _name,
        duration,
        isFixedArr,
        endOfMonthArr,
        percentArr,
        lockPeriodArr
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }


  const closeModal = async() => {
    setIsModalVisible(false);
    history.push(`/issuer-token-admin/token-managment/create-vesting-schedule`);
  }


  const saveVestingSchedule = async (formValue) => {
    let total = 0;
    vestingScheduleData.forEach(({percent}) => total += percent);
    if(total !== 100) return;

    const req = {...formValue};
    req.vesting = vestingScheduleData.map(schedule => ({isFixed: schedule.isFixed, endOfMonth: schedule.endOfMonth, percent: schedule.percent, lockPeriod: schedule.lockPeriod}));

    if(!vestingModuleExists) createModuleAndVestingScheduleTemplate(req);
    else createVestingScheduleTemplate(req);

    // setSubmitting(true);
    // console.log(req);

    // const response = await issuerSuperAdminService.saveVestingSchedule(req);

    // if(response.success) {
    //   history.push(`/issuer-super-admin/token-managment/create-vesting-schedule`)
    // } else {

    // }
    // setSubmitting(false);
  };


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
        {loading &&  
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&

          <Card>
            <Title level={1} style={{textAlign:'center'}}>Vesting Schedule</Title>

            {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <Result
                title={`Security Token not deployed`}
                subTitle="You have to deploy your Security Token first"
                extra={
                  <Button key="console" onClick={() => history.push('/token-configurations')}>
                    Create Token
                  </Button>
                }/>
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
              <>
                <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                <Result
                  status="error"
                  title = {
                    <p>
                      Select the wallet {' '}
                      <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                        {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                      </a> 
                      {' '} in order to Create Vesting
                    </p>
                  }
                />
              </>
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
              <Form form={form} autoComplete={'off'} labelAlign="left" initialValues={{description:null}} onFinish={saveVestingSchedule}>
                {/* <Form.Item wrapperCol={{span:18, offset:3}} 
                  name='description'
                >
                  <TextArea placeholder='Description message' />
                </Form.Item> */}
                <br/>

                <Card
                  bordered={false} 
                  className='card-shadow'>
                  <Title level={3} style={{textAlign:'center'}}>Enter the name for the Vesting Schedule</Title>
                  <Form.Item wrapperCol={{span:12, offset:6}} 
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <Input placeholder='Enter name'/>
                  </Form.Item>

                  <Form.Item  labelCol={{offset:6}}
                    label='Enter total duration for Vesting'
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <InputNumber min={1} precision={0} placeholder='Months' onChange={initVestingTable}/>
                  </Form.Item>

                  <Table
                    columns={columns}
                    dataSource={vestingScheduleData}
                    pagination={false}
                    summary={pageData => {
                      let total = 0;
                      pageData.forEach(({percent}) => total = new BigNumber(total).plus(percent).toNumber());
                      return (
                        <>
                        <tr>
                          <td></td>
                          <td></td>
                          <th>Total Vesting Percent = </th>
                          <th style={{color: total === 100? 'green' : 'red'}}>{total}%</th>
                        </tr>
                          
                        </>
                      );
                    }}
                  />

                  <br/><br/>
                  <div style={{textAlign:'right'}}>
                    {/* {vestingScheduleList && vestingScheduleList.length>0 && 
                      <Button style={{marginRight:'5px'}} size='large' onClick={() => setScreenState('list')}>
                        BACK
                      </Button>
                    } */}
                    <Button size='large' type='primary' htmlType="submit">
                      SAVE THE VESTING SCHEDULE
                    </Button>
                  </div>

                </Card>

              </Form>
            }

          </Card>
        }

        </Col>
      </Row>


      <TransactionModal
        title = {'Vesting Schedule Creation'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => closeModal()}
      />

    </>
  );
}