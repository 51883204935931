import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
const { Title } = Typography;

export default ({ heading, component }) => {
    return (
        <>
            <Row justify="center">
                <Col span={22} style={{ textAlign: 'center' }}>
                    <Card>
                        <Title
                            level={2}
                            style={{
                                textAlign: 'left',
                                color: '#1890ff',
                                // fontSize: '30px',
                                fontWeight: 'bold'
                            }}
                        >
                            {heading}
                        </Title>
                        {component}
                    </Card>
                </Col>
            </Row>

        </>
    )
}