import { MetamaskService } from '../../../Metamask.service';
import { RegLaunched } from '../../../interfaces';

export default class MainFacet {

  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async setDefaultSenderReg(tokenAddress: string, owner: string, _index: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.setDefaultSenderReg(_index).send({ from: owner });
  }

  async relaunchToken(tokenAddress: string, owner: string, _regulation: string, _regDTransferableOutsideUSA: boolean, _creationTS: number, _typeOfSecurity: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.relaunchToken(_regulation, _regDTransferableOutsideUSA, _creationTS, _typeOfSecurity).send({ from: owner });
  }

  async removeLegend(tokenAddress: string, owner: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeLegend(_creationTS).send({ from: owner });
  }

  async legendRemoved(tokenAddress: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.legendRemoved(_creationTS).call()) as boolean;
  }

  async getRegLaunchedByTS(tokenAddress: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegLaunchedByTS(_creationTS).call()) as RegLaunched;
  }

  async getRegulationsLaunched(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegulationsLaunched().call()) as RegLaunched[];
  }

  async getRegLaunchedByIndex(tokenAddress: string, _index: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegLaunchedByIndex(_index).call()) as RegLaunched;
  }

  async getDefaultSenderReg(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getDefaultSenderReg(_from).call()) as RegLaunched;
  }

  async regBalanceOf(tokenAddress: string, _from: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.regBalanceOf(_from, _launchIndex).call()) as string;
  }

  async regulation(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.regulation().call()) as string;
  }

  async monthUnit(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.monthUnit().call()) as string;
  }

  async balanceOf(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.balanceOf(_from).call()) as string;
  }

  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.totalSupply().call()) as string;
  }

  async issuerComplianceAdmin(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.issuerComplianceAdmin().call()) as string;
  }

  async issuerCounsel(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.issuerCounsel().call()) as string;
  }

};

//   Not Implemented
//   function name() public view returns (string memory) {
//   }
//   function symbol() public view returns (string memory) {
//   }
//   function decimals() public view returns (uint8) {
//   }
//   function allowance(address owner, address spender) public view virtual returns (uint256) {
//   }
//   function approve(address spender, uint256 amount) public virtual returns (bool) {
//   }
//   function approve(address owner, address spender, uint256 amount) external virtual onlyFacet returns (bool) {
//   }
//   function increaseAllowance(address spender, uint256 addedValue) public virtual returns (bool) {
//   }
//   function decreaseAllowance(address spender, uint256 subtractedValue) public virtual returns (bool) {
//   }
//   function mint(address account, uint256 amount) external onlyFacet {
//   }
//   function burn(address account, uint256 amount) external onlyFacet {
//   }
//   function precision() external view returns (uint256) {
//   }
//   function tokenDetails() external view returns (string memory) {
//   }
//   function issuerCompanyOfficer() external view returns (address) {
//   }
//   function issuerSuperAdmin() external view returns (address) {
//   }
//   function initialize(
//   string calldata name,
//   string calldata symbol,
//   uint8 decimals,
//   string calldata _tokenDetails,
//   address _CCAPRegistry,
//   string calldata _regulation,
//   bool _regDTransferableOutsideUSA,
//   uint256 _creationTS
//   ) external {
//   }
//   function saveTypeOfSecurity(string calldata _typeOfSecurity) external onlyOwner {
//   }
//   function setIssuers(address[] calldata _issuers) external onlyOwner {
//   }