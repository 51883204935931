import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Home from './Home';
import KYCUpload from './KYCUpload';
import KYCStatus from './KYCStatus';
import AIStatus from './AIStatus';
import Payments from './Payments';
import Dashboard from './Dashboard';
import NotVerified from './NotVerified';
import EmailNotVerified from './EmailNotVerified';
export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/home`}>
       <Home/>
      </Route>
      <Route path={`${path}/KYCVerification`}>
        <KYCUpload/>
      </Route>
      <Route path={`${path}/kyc-status`}>
        <KYCStatus/>
      </Route>
      <Route path={`${path}/ai-status`}>
        <AIStatus/>
      </Route>
      <Route path={`${path}/payments`}>
        <Payments/>
      </Route>
      <Route path={`${path}/dashboard`} >
        <Dashboard/>
      </Route>
      <Route path={`${path}/not-verified`} >
        <NotVerified/>
      </Route>
      <Route path={`${path}/email-not-verified`} >
        <EmailNotVerified />
      </Route>
       </Switch>
  )
}
