import React, { useState } from 'react';
import { TokenConfigurationProcess, User } from '../../../Shared/interfaces';
import List from './List';
import New from './New';
import Details from './Details';
import Edit from './Edit';



export default ({savePhase, tokenConfigurationProcess, userInfo, editPhase}: {savePhase?: any, tokenConfigurationProcess:TokenConfigurationProcess | undefined, userInfo: User, editPhase}) => {

  const [type, setType] = useState<{component: 'list' | 'new' | 'details' | 'edit', tokenPhaseId?: string}>({component:'list'});

  return (
    <>
      {type.component === 'list' && 
        <List userInfo={userInfo} tokenConfigurationProcess={tokenConfigurationProcess} setType={setType}/>
      }
      {type.component === 'new' && (userInfo.role === 'issuer token admin') && 
        <New tokenConfigurationProcess={tokenConfigurationProcess} setType={setType} savePhase={savePhase}/>
      }
      {type.component === 'details' && 
        <Details userInfo={userInfo} tokenPhaseId={type.tokenPhaseId} setType={setType}/>
      }
      {type.component === 'edit' && 
        <Edit tokenPhaseId={type.tokenPhaseId} setType={setType} editPhase={editPhase}/>
      }
    </>
  );
}