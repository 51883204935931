import { MetamaskService } from '../../../Metamask.service';
import { VestingScheduleFromContract, VestingScheduleTemplate } from '../../../interfaces';

export default class VestingFacet {

  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async getVestingScheduleTemplates(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getVestingScheduleTemplates().call()) as VestingScheduleTemplate[];
  }
  
  async getVestingScheduleTemplate(moduleAddress: string, _index: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getVestingScheduleTemplate(_index).call()) as VestingScheduleTemplate;
  }
  
  async createVestingScheduleTemplate(
    moduleAddress: string, 
    owner: string,
    _name: string,
    duration: number,
    isFixedArr: boolean[],
    endOfMonthArr: number[], 
    percentArr: string[],
    lockPeriodArr: number[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.createVestingScheduleTemplate(this.web3.utils.utf8ToHex(_name), duration, isFixedArr, endOfMonthArr, percentArr, lockPeriodArr).send({from: owner});
  }

  async assignVestingScheduleTemplateMulti(
    moduleAddress: string, 
    owner: string,
    _from: string[], 
    _amount: string[], 
    _index: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.assignVestingScheduleTemplateMulti(_from, _amount, _index).send({from: owner});
  }

  async addVestingScheduleMulti(
    moduleAddress: string, 
    owner: string, 
    _from: string[], 
    duration: number[], 
    amount: string[], 
    isFixedArr: boolean[][],
    endOfMonthArr: number[][], 
    percentArr: string[][],
    lockPeriodArr: number[][]) {

    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.addVestingScheduleMulti(_from, duration, amount, isFixedArr, endOfMonthArr, percentArr, lockPeriodArr).send({ from: owner });
  }

  async getAllVestingScheduleOwnerAddresses(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getAllVestingScheduleOwnerAddresses().call()) as string[];
  }

  async getVestingSchedule(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getVestingSchedule(_from).call()) as VestingScheduleFromContract;
  }

  async hasVestingSchedule(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.hasVestingSchedule(_from).call()) as boolean;
  }

  async deleteVestingSchedule(moduleAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.deleteVestingSchedule(_from).send({from: owner});
  }

  async withdrawFromVesting(moduleAddress: string, owner: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.withdrawFromVesting().send({from: owner});
  }

  async getPercentageToWithdrawFromVesting(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getPercentageToWithdrawFromVesting(_from).call()) as string;
  }

};

  // Not Implemented
  // function editVestingScheduleTemplate(
  //   uint256 _index,
  //   bytes32 _name,
  //   uint256 duration,
  //   bool[] calldata isFixedArr,
  //   uint256[] calldata endOfMonthArr,
  //   uint256[] calldata percentArr,
  //   uint256[] calldata lockPeriodArr) external onlyOwner {
  // }
  // function deleteVestingScheduleTemplate(uint256 _index) external onlyOwner {
  // }
  // function addVestingSchedule(
  //   address _from,
  //   uint256 duration,
  //   uint256 _amount,
  //   bool[] memory isFixedArr,
  //   uint256[] memory endOfMonthArr,
  //   uint256[] memory percentArr,
  //   uint256[] memory lockPeriodArr) public onlyOwner {
  // }
  // function getTotalVestingScheduleOwners() public view returns(uint256) {
  // }
  // function getSlicedVestingScheduleOwnerAddresses(uint256 start, uint256 end) public view returns(address[] memory) {
  // }
  // function hasVestingScheduleMulti(address[] memory _from) public view returns(bool[] memory) {
  // }
  // function getPercentageToWithdrawFromVestingTest(address _from, uint256 monthsTS) external view returns(uint256) {
  // }
