import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Table, Spin, Descriptions, Button  } from 'antd';
import { TokenPhase } from '../../../../Shared/interfaces';
import moment from 'moment';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';

const {Title} = Typography;


const tokenConfigurationService = new TokenConfigurationService();


export default ({tokenPhaseId, setType, userInfo}) => {
  const [tokenPhase, setTokenPhase] = useState<TokenPhase>();

  useEffect(() => {
    (async () => {
      if(!tokenPhaseId) return; //status !== 'phase'
      
      const response = await tokenConfigurationService.getPhase(tokenPhaseId);
      setTokenPhase(response.data);
      
    })();
  }, [tokenPhaseId]);



  const phaseColumns = [
    {
      title: 'Minimum Investment Amount',
      dataIndex: 'minInvestmentAmount',
      render: (value) => `${value.min} - ${value.max}`
    },
    {
      title: '% Bonus',
      dataIndex: 'bonusPercent',
    },
  ];

  
  return (
    <>
    <br/><br/>
    <Row justify="center">

      <Col span={20}>
        {!tokenPhase && 
          <div style={{textAlign:'center'}}>
            <Spin size='large'/>
          </div>
        }
        {tokenPhase && 
          <>
            <Descriptions bordered column={2} title="Information">
              <Descriptions.Item span={2} label="Name of the phase">{tokenPhase.phaseName}</Descriptions.Item>
              <Descriptions.Item label="Start date">{moment(tokenPhase.startDate).format('LLL')}</Descriptions.Item>
              <Descriptions.Item label="End date">{moment(tokenPhase.endDate).format('LLL')}</Descriptions.Item>
            </Descriptions>

            <Table 
              title={()=><Title level={3}>Offer Bonus</Title>} 
              columns={phaseColumns}
              dataSource={tokenPhase.bonusOffers.map((offers, i) => ({...offers, key: i}))}
              pagination={false}
            >
            </Table>
            <br/><br/>
            <div style={{textAlign:'right'}}>
              <Button style={{marginRight:'5px'}} size='large' onClick={() => setType({component:'list'})}>
                BACK
              </Button>
              {(userInfo.role === 'issuer super admin' || userInfo.role === 'issuer token admin') && 
                <Button size='large' type='primary' htmlType="submit" onClick={() => setType({component:'edit', tokenPhaseId})}>
                  EDIT
                </Button>
              }
            </div>
          </>
        }
      </Col>
    </Row>
    </>
  );
}