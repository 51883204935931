import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react'
import { Card, Col, Row, Typography, Form, Input, Button, Progress, Alert, Result, Divider, Select, Steps, Switch, notification, message } from 'antd'
import { AuthService } from '../../Shared/Auth.service'
import { SharedService } from '../../Shared/Shared.service';
import { InvestorService } from '../Investor.service'
import { Tabs } from 'antd'
import 'react-html5-camera-photo/build/css/index.css';
import Webcam from 'react-webcam'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory, useLocation } from 'react-router-dom';
import Process from '../Process';
import moment from 'moment';

const { Option } = Select
const { TabPane } = Tabs
const { Step } = Steps;
const { Title } = Typography
const { TextArea } = Input
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();
const investorService = new InvestorService();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// var Credential = {
//   passive_username: "SubscriptionlessSDK@cryptoassetrating.com",
//   passive_password: "HsF+T83z%PcY",
//   id_username: "SubscriptionlessSDK@cryptoassetrating.com",
//   id_password: "HsF+T83z%PcY",
//   passive_subscriptionId: null, 
//   acas_endpoint: "https://eu.acas.acuant.net",
//   liveness_endpoint: "https://eu.passlive.acuant.net"
// }

const videoConstraints = {
  width: 600,
  height: 400,
  facingMode: "user"
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

 

export default () => {
  const history = useHistory();
  let query = useQuery();
  const [mainForm] = Form.useForm()
  const licenceRef = useRef(null);
  const passportRef = useRef(null);
  const selfieRef = useRef(null);
  const [licenceForm] = Form.useForm()
  const [licenceSubmitted, setLicenceSubmitted] = useState(false)
  const [defaultActiveKey, setDefaultActiveKey] = useState('form');
  const [passPortForm] = Form.useForm()
  const [formSubmited, setFormSubmited] = useState(false)
  const { userInfo } = useUserContext()
  const [passportSubmitted, setPassportSubmitted] = useState(false)
  const [formData, setFormData] = useState({})
  const [disableFields, setDisableFields] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [selfieSubmitted, setSelfieSubmitted] = useState(false)
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null)
  const [countries, setCountries] = useState();
  const [contactNumberCountry, setContactNumberCountry] = useState('us');
  const [current, setCurrent] = useState(0);
  const [basicDetailsView, setBasicDetailsView] = useState(true);
  const [licenceUploadView, setLicenceUploadView] = useState(false);
  const [passportUploadView, setPassportUploadView] = useState(false);
  const [selfieUploadView, setSelfieUploadView] = useState(false);
  const [basicDetailsProgress, setBasicDetailsProgress] = useState('process');
  const [licenceDetailsProgress, setLicenceDetailsProgress] = useState('wait');
  const [passportDetailsProgress, setPassportDetailsProgress] = useState('wait');
  const [selfieDetailsProgress, setSelfieDetailsProgress] = useState('wait');
  const [KYCVerifiedView, setKYCVerifiedView] = useState(false);
  const [KYCData, setKYCData] = useState(null);
  const [licenceFrontSide, setLicenceFrontSide] = useState(
    {
      licenceFrontSide: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  const [licenceBackSide, setLicenceBackSide] = useState(
    {
      licenceBackSide: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  const [passportFile, setPassportFile] = useState(
    {
      passport: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  
  
  

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
    })();
    
  }, []);

  useEffect(() => {
    (async () => {
      const response = await investorService.getKYCDetails({ investorId: userInfo._id });
      if (response.success && response.data) {
        if (response.data.length > 0) {

          if (userInfo.KYCSubmittedCompany.length > 0) {
            setKYCData(response.data[0]);
            setKYCVerifiedView(true);
          }
        }
      }
    })();
  }, [])

  useEffect(() => {
    let resubmit = query.get('resubmit');
    if (resubmit === 'yes') {
      notification.info(
        {
          message: 'Resubmit KYC Details',
          description: `Issuer(${query.get('company')}) ask you to resubmit the KYC details.`
        }
      );
      return;
    }
  }, []);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc)
      console.log(imageSrc)
    },
    [webcamRef]
  );
  const recapture = () => {
    setImageSrc(null)
  }


  const saveFormData = (data) => {
    setDisableFields(true)
    let temp = Object.assign(formData, data);
    setFormData(temp);
    console.log(formData);
    if (formSubmited && !licenceSubmitted) {
      setLicenceSubmitted(true)
    }
    if (formSubmited && licenceSubmitted && !passportSubmitted) {
      setPassportSubmitted(true)
    }
    if (formSubmited && licenceSubmitted && passportSubmitted) {
      submitFormData()
    }
    setFormSubmited(true);

  }
  const submitFormData = async () => {
    setSelfieSubmitted(true)
    setSubmitting(true)
    formData.walletAddress = userInfo.walletAddress;
    formData.accountCreationDate = userInfo.creationTS;
    formData.investorId = userInfo._id;
    formData.firstName = userInfo.firstName;
    formData.lastName = userInfo.lastName;
    formData.email = userInfo.email;
    formData.contactNumber = userInfo.contactNumber;
    formData.country = userInfo.country;
    formData.state = userInfo.state;
    formData.dob = moment(userInfo.dateOfBirth).format('YYYY-MM-DD');
    formData.approved = false;
    formData.rejected = false;
    formData.resubmit = false;
    formData.dateOfSubmission = Date.now();
    formData.approvedByICA = false;
    formData.isFromUSA = userInfo.country.toLowerCase() === "united states of america" ? true : false;
    formData.company = await query.get("company").toLocaleLowerCase();
    formData.companyId = userInfo?.company?.id;
    const response = await investorService.submitKYCDetails(formData);
    if (response.success && response.data) {
      setSuccess(true)
    }
    else {
      message.error("Something went wrong please try again!");
    }
    setSubmitting(false)
  }
  const uploadExisting = async () => {
    setSubmitting(true)
    KYCData.rejected = false;
    KYCData.resubmit = false;
    KYCData.dateOfSubmission = Date.now();
    KYCData.approvedByICA = false;
    KYCData.company = await query.get("company").toLowerCase();
    KYCData.companyName = await query.get("company").toLowerCase();
    const response = await investorService.submitKYCDetails(KYCData);
    if (response.success && response.data) {
      setKYCVerifiedView(false);
      setSuccess(true)
    }
    setSubmitting(false)
  }




  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    console.log(file);

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);

    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    investorService.uploadFile(file, (uploadFilePercent) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });

    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });

    }, async (response) => {

      if (response.success) {
        const URL = response.data.url;
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        form.setFieldsValue({ [key]: { URL, name: file.name } });

      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }

    });
  }
  const changeCountry = (event) => {
    console.log(event);
    setContactNumberCountry(event);
  }


  useEffect(() => {
    (() => {

      if (formSubmited) {
        setDefaultActiveKey('licence');
        setBasicDetailsView(false);
        setBasicDetailsProgress('finish');
        setLicenceUploadView(true);
        setLicenceDetailsProgress('process');
        setCurrent(1);
      }
      if (licenceSubmitted) {
        setDefaultActiveKey('passport');
        setLicenceUploadView(false);
        setLicenceDetailsProgress('finish');
        setPassportUploadView(true);
        setPassportDetailsProgress('process');
        setCurrent(2);
      }
      if (passportSubmitted) {
        setDefaultActiveKey('selfie');
        setPassportUploadView(false);
        setPassportDetailsProgress('finish');
        setSelfieUploadView(true);
        setSelfieDetailsProgress('process');
        setCurrent(3);
      }

    })()
  }, [formSubmited, licenceSubmitted, passportSubmitted])

  const initMainForm = () => {
    mainForm.setFieldsValue({
      "email": userInfo.email,
      "firstName": userInfo.firstName,
      "lastName": userInfo.lastName
    });
  }
  const changeAddressState = checked => {
    if (checked) {
      mainForm.setFieldsValue(
        {
          'pStreetAddress1': mainForm.getFieldValue('pAStreetAddress1'),
          'pStreetAddress2': mainForm.getFieldValue('pAStreetAddress2'),
          'pCity': mainForm.getFieldValue('pACity'),
          'pState': mainForm.getFieldValue('pAState'),
          'pZipCode': mainForm.getFieldValue('pAZipCode'),
          'pCountry': mainForm.getFieldValue('pACountry')
        });
    }
    else {
      mainForm.setFieldsValue(
        {
          'pStreetAddress1': '',
          'pStreetAddress2': '',
          'pCity': '',
          'pState': '',
          'pZipCode': '',
          'pCountry': ''
        });
    }
  }
  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: 'center' }}>
          <Card>
            <Process />
            <Divider />
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
            >
              KYC Verification
          </Title>
            <div hidden={success || KYCVerifiedView}>
              <Steps
                type="navigation"
                current={current}
                className='site-navigation-steps'
              >
                <Step
                  title='Step 1'
                  description='Basic Details'
                  status={basicDetailsProgress}
                />

                <Step
                  title='Step 2'
                  description='Driving Licence'
                  status={licenceDetailsProgress}
                />

                <Step
                  title='Step 3'
                  description='Passport'
                  status={passportDetailsProgress}
                />

                <Step
                  title='Step 4'
                  description='Selfie'
                  status={selfieDetailsProgress}
                />
              </Steps>
              <div id='basicDetailsDivision' hidden={!basicDetailsView} >
                {disableFields && <Alert type="success" message="Your details submitted now please upload your documents"></Alert>}
                {basicDetailsView && initMainForm()}
                <Form
                  {...formItemLayout}

                  form={mainForm}
                  onFinish={saveFormData}
                  style={{ margin: '2% 10% 0 10%', width: '60%' }}
                >

                  <h4 align="left">Personal Details</h4>
                  <h5 align="left">Full Name:</h5>
                  <Row>
                    <Col>
                      <Form.Item
                        //label='First Name'
                        name='firstName'
                        rules={[
                          {
                            required: true,
                            message: "First name can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="First Name" disabled={disableFields} autoComplete="new-password" ></Input>
                      </Form.Item >
                    </Col>
                    <Col>
                      <Form.Item
                        //label='Middle Name'
                        name='middleName'
                      >
                        <Input placeholder="Middle Name" disabled={disableFields}></Input>

                      </Form.Item >
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        //label='Last Name'
                        name='lastName'
                        rules={[
                          {
                            required: true,
                            message: "Last name can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="Last Name" disabled={disableFields}></Input>

                      </Form.Item >
                    </Col>
                  </Row>
                  
                  <h4 align="left" >Present Address</h4>
                  <Form.Item
                    //label=' '
                    name='pAStreetAddress1'
                    rules={[
                      {
                        required: true,
                        message: "Present Address can't be empty"
                      }
                    ]}

                  >

                    <Input placeholder="Street Address" disabled={disableFields} ></Input>
                  </Form.Item >
                  <Form.Item
                    //label=' '
                    name='pAStreetAddress2'

                  >

                    <Input placeholder="Street Address2" disabled={disableFields} ></Input>
                  </Form.Item >
                  <Row>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pACity'
                        rules={[
                          {
                            required: true,
                            message: "Present city can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="city" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pAState'
                        rules={[
                          {
                            required: true,
                            message: "Present state can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="State" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pAZipCode'
                        rules={[
                          {
                            required: true,
                            message: "Present zip code can't be empty"
                          }
                        ]}
                      >
                        <Input type='number' placeholder="Zip/Postal Code" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pACountry'
                        rules={[
                          {
                            required: true,
                            message: "Present country can't be empty"
                          }
                        ]}
                      >
                        <Select onChange={changeCountry} showSearch placeholder="Select Country" disabled={disableFields} style={{ width: 300 }} autoComplete="new-password" >
                          {countries?.map(country =>
                            <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                          )}
                        </Select>
                      </Form.Item >
                    </Col>
                  </Row>
                  <h4 align='left'><Switch onChange={changeAddressState} ></Switch> same as present address</h4>
                  <h4 align="left" >Permanent Address</h4>
                  <Form.Item
                    //label=' '
                    name='pStreetAddress1'
                    rules={[
                      {
                        required: true,
                        message: "Permanent street address can't be empty"
                      }
                    ]}

                  >

                    <Input placeholder="Street Address" disabled={disableFields} ></Input>
                  </Form.Item >
                  <Form.Item
                    //label=' '
                    name='pStreetAddress2'
                  >

                    <Input placeholder="Street Address2" disabled={disableFields} ></Input>
                  </Form.Item >
                  <Row>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pCity'
                        rules={[
                          {
                            required: true,
                            message: "Permanent city can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="city" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pState'
                        rules={[
                          {
                            required: true,
                            message: "Permanent state can't be empty"
                          }
                        ]}
                      >
                        <Input placeholder="State" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pZipCode'
                        rules={[
                          {
                            required: true,
                            message: "Permanent zip code can't be empty"
                          }
                        ]}
                      >
                        <Input type='number' placeholder="Zip/Postal Code" disabled={disableFields} ></Input>
                      </Form.Item >
                    </Col>
                    <Col>
                      <Form.Item
                        //label=' '
                        name='pCountry'
                        rules={[
                          {
                            required: true,
                            message: "Permanent country can't be empty"
                          }
                        ]}
                      >
                        <Select showSearch placeholder="Select Country" disabled={disableFields} style={{ width: 300 }} autoComplete="new-password" >
                          {countries?.map(country =>
                            <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                          )}
                        </Select>
                      </Form.Item >
                    </Col>
                  </Row>


                  <h4 align="left" >Contact Details</h4>
                  <Form.Item
                    //label=''
                    name='contactNumber'
                    rules={[
                      {
                        required: true,
                        message: "Contact number can't be empty"
                      }
                    ]}

                  >
                    <PhoneInput
                      disabled={disableFields}
                      country={contactNumberCountry}
                    ></PhoneInput>
                  </Form.Item >
                  <Form.Item
                    //label=' '
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: "Email Address can't be empty"
                      }
                    ]}

                  >

                    <Input type="email" placeholder="Email" disabled={disableFields} ></Input>
                  </Form.Item >

                  <Form.Item
                    {...tailFormItemLayout}
                  >
                    <Button htmlType='submit' type='primary' disabled={disableFields} >Submit</Button >
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button htmlType='button' onClick={() => mainForm.resetFields()} disabled={disableFields} >Cancel</Button>
                  </Form.Item >

                </Form>

              </div>

              <div id='licenceUploadDivision' hidden={!licenceUploadView} >
                <Form
                  {...formItemLayout}

                  form={licenceForm}
                  onFinish={saveFormData}
                  style={{ margin: '2% 10% 0 10%', width: '60%' }}
                >
                  <Form.Item
                    label="Front Side"
                    name="licenceFrontSide"
                    rules={[
                      {
                        required: true,
                        message: "Please select a image !"
                      }
                    ]}
                  >
                    <Input type='file' onChange={e => { uploadFile(e, 'licenceFrontSide', setLicenceFrontSide, licenceForm) }} disabled={licenceSubmitted} />
                    {licenceFrontSide.licenceFrontSide.uploadFilePercentage > 0 &&
                      <Progress percent={licenceFrontSide.licenceFrontSide.uploadFilePercentage} />}
                    <br />
                    {licenceFrontSide.licenceFrontSide.uploadFilePercentage == 100 && <img height={250} width={500} src={licenceFrontSide.licenceFrontSide?.URL} />}
                  </Form.Item>

                  <Divider />
                  <Form.Item
                    label="Back Side"
                    name="licenceBackSide"
                    rules={[
                      {
                        required: true,
                        message: "Please select a image !"
                      }
                    ]}
                  >
                    <Input type='file' onChange={e => { uploadFile(e, 'licenceBackSide', setLicenceBackSide, licenceForm) }} disabled={licenceSubmitted} />
                    {licenceBackSide.licenceBackSide.uploadFilePercentage > 0 &&
                      <Progress percent={licenceBackSide.licenceBackSide.uploadFilePercentage} />}
                    <br />
                    {licenceBackSide.licenceBackSide.uploadFilePercentage == 100 && <img height={250} width={500} src={licenceBackSide.licenceBackSide?.URL} />}

                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button htmlType='submit' type='primary' disabled={licenceSubmitted} >Submit</Button >
                   &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button htmlType="button" onClick={() => licenceForm.resetFields()} disabled={licenceSubmitted} >CANCEL</Button>
                  </Form.Item>

                </Form>
              </div>

              <div id='passportUploadDivision' hidden={!passportUploadView}  >
                <Form
                  {...formItemLayout}
                  form={passPortForm}
                  onFinish={saveFormData}
                  style={{ margin: '2% 10% 0 10%', width: '60%' }}
                >
                  <Form.Item
                    label="Passport"
                    name="passport"
                    rules={[{
                      required: true,
                      message: "Please upload a image!"
                    }]}
                  >
                    <Input type='file' onChange={e => { uploadFile(e, 'passport', setPassportFile, passPortForm) }} disabled={passportSubmitted} />
                    {passportFile.passport.uploadFilePercentage > 0 &&
                      <Progress percent={passportFile.passport.uploadFilePercentage} />}
                    <br />
                    {passportFile.passport.uploadFilePercentage == 100 && <img height={250} width={500} src={passportFile.passport?.URL} />}

                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button htmlType='submit' type='primary' disabled={passportSubmitted} loading={submitting} >Submit</Button >
                   &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button htmlType="button" onClick={() => passPortForm.resetFields()} disabled={passportSubmitted} loading={submitting} >CANCEL</Button>
                  </Form.Item>
                </Form>
              </div>
              <div id='selfieUploadDivision' hidden={!selfieUploadView} >
                {(imageSrc === null && selfieUploadView) && <>
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={600}
                    videoConstraints={videoConstraints}
                  />
                  <br />
                  <br />
                  <Button type='primary' onClick={capture}>Capture Image</Button>
                </>}
                {imageSrc !== null &&
                  <>
                    <img src={imageSrc}></img>
                    <br />
                    <br />
                    <Button onClick={recapture}>Retake Image</Button>&nbsp;&nbsp;&nbsp;&nbsp;
    <Button type='primary' onClick={() => saveFormData({ selfie: imageSrc })} loading={submitting}>Submit</Button>
                  </>
                }
              </div>

            </div>
            <div hidden={!success}>
              <Result
                status="success"
                title="Successfully submitted KYC details."
                subTitle="You details are submitted.Please wait for approval."
                extra={[
                  <Button type="primary" key="Home" href="home">
                    Home
              </Button>,
                ]} />
            </div>

            <div hidden={!KYCVerifiedView}>
              <Result
                status="success"
                title="You had successfully submitted your  KYC details.Would you like to submit old details or submit new?"
                subTitle="You details are submitted.Please wait for approval."
                extra={[
                  <Button type="primary" key="upload" onClick={() => { uploadExisting() }}>
                    UPLOAD EXISTING
              </Button>,
                  <Button key="new" onClick={() => { setKYCVerifiedView(false) }}>
                    SUBMIT NEW
              </Button>
                ]} />
            </div>
          </Card>
        </Col>
      </Row>
    </>

  )
}