// import React from 'react';
import { MetamaskService } from '../Metamask.service';
import { SymbolDetailsAndSTData, APIResponse } from '../interfaces';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import axios from 'axios';
import { environment } from '../../../environments/environment';


const tokenConfigurationService = new TokenConfigurationService();
// const metamaskService = new MetamaskService();


const headers = {
  'Content-Type': 'application/json',
  'apiKey':environment.apiKey
}


export class SecurityTokenRegistryService {

  private APIURL = environment.APIURL;

  public contract = {
    address: '0xF9796De2C08900822D3fe63E6612e51c4447F320',
    ABI: require('./ABI.json')
  }

  private get web3() {
    return MetamaskService.web3;
  }

  async tokenSymbolAvailable(tokenSymbol: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.methods.tokenSymbolAvailable(tokenSymbol).call();
  }

  registerNewTokenSymbol(owner: string, tokenSymbol: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.methods.registerNewTokenSymbol(owner, tokenSymbol).send({ from: owner });
  }

  async getAllOwnerSymbolsDetailsAndSTData(owner: string): Promise<SymbolDetailsAndSTData[]> {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return (await contractInstance.methods.getAllOwnerSymbolsDetailsAndSTData(owner).call());
    // return new Promise(async(resolve, reject) => {

    // let symbols: string[];

    // let symbolsInfo: SymbolDetailsAndSTData[] = [];

    // symbols = (<string[]>(await contractInstance.methods.getUserTokenSymbols(owner).call())).map(symbol => this.web3.utils.toUtf8(symbol));

    // if(symbols.length === 0) {
    //   resolve(symbolsInfo); return;
    // }

    // const batch = new this.web3.BatchRequest();

    // symbols.forEach((symbol, index) => {

    //   const info: SymbolDetailsAndSTData = {registeredSymbol: symbol, symbolDetails: null, securityTokenData: null};

    //   batch.add(contractInstance.methods.registeredTokenSymbols(symbol).call.request((err, symbolDetails) => {
    //     info.symbolDetails = symbolDetails;
    //   }));

    //   batch.add(contractInstance.methods.tokenSymbolToSecurityTokenData(symbol).call.request((err, securityTokenData) => {
    //     info.securityTokenData = securityTokenData;

    //     symbolsInfo.push(info);

    //     if(symbols.length-1 === index) resolve(symbolsInfo);
    //   }));

    // });

    // batch.execute();

    // });
  }

  async getSymbolDetailsAndSTData(symbol: string): Promise<SymbolDetailsAndSTData> {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return (await contractInstance.methods.getSymbolDetailsAndSTData(symbol).call());
  }

  getSymbolRegistrationEvent(owner: string, registrationDate: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.getPastEvents('RegisterTokenSymbol', {
      filter: {
        _owner: owner,
        _registrationDate: registrationDate
      },
      fromBlock: 0,
      toBlock: 'latest'
    });
  }

  async generateNewSecurityToken(
    owner: string, 
    name: string, 
    tokenSymbol: string, 
    decimals: number, 
    tokenDetails: string, 
    _regulation: string,
    _regDTransferableOutsideUSA: boolean,
    _creationTS: number,
    _typeOfSecurity: string
    // _maxAffiliateSellPercentage: string,
    // _maxAffiliateAmountMonths: number,
    // _maxAffiliateAmount: string,
    // _maxAffiliateTokensMonths: number,
    // _maxAffiliateTokens: string,
    // _investorLockPeriord: number
    ) {

    const issuersWallets: string[] = (await tokenConfigurationService.getIssuersWallets()).data;

    console.log(issuersWallets);

    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return contractInstance.methods.generateNewSecurityToken(
      [name, tokenSymbol, tokenDetails, _regulation, _typeOfSecurity],
      // name, 
      // tokenSymbol, 
      decimals, 
      // tokenDetails, 
      // _regulation,
      issuersWallets,
      _regDTransferableOutsideUSA,
      _creationTS
      ).send({ from: owner });
  }

  async getSymbolsDetailsAndSTData(symbols: string[]): Promise<SymbolDetailsAndSTData[]> {    
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return (await contractInstance.methods.getSymbolsDetailsAndSTData(symbols).call());
  }

  async getDeployedTokensData(): Promise<SymbolDetailsAndSTData['securityTokenData'][]> {    
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return (await contractInstance.methods.getDeployedTokensData().call());
  }


  async getCompaniesAndTokenInfo() {
    const tokens = await this.getDeployedTokensData();

    const symbols = tokens.map(token => token.symbol);

    const response = await axios.get<any, APIResponse>(`${this.APIURL}/shared/getCompaniesAndTokenInfo`, {params: {symbols}, headers});

    if(response.success) {
      response.data = (response.data as any[]).map(info => {

        const tokenInfoFound = tokens.find(token => token.symbol === info.tokenSymbol);

        const result = {
          tokenInfo: {
            name: tokenInfoFound?.name,
            symbol: tokenInfoFound?.symbol,
            contractAddress: tokenInfoFound?.contractAddress,
            decimals: tokenInfoFound?.decimals,
            tokenDetails: tokenInfoFound?.tokenDetails,
            deployedAt: tokenInfoFound?.deployedAt

          },
          ...info
        }

        return result;
      });
    }



    return response;
  }


  
}
