import { SharedService } from '../Shared/Shared.service';
import { APIResponse} from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';



const sharedService = new SharedService();


export class TokenConfigurationService {
  private APIURL = environment.APIURL;

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/tokenConfiguration/uploadDocument`, req, progressCb, errorCb, completeCb)
  }

  async getTokenConfigurationProcesses(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getTokenConfigurationProcesses`, {headers: await sharedService.getAuthHeader()});    
  }

  async getTokenConfigurationProcessById(params: {tokenConfigurationProcessId: string}){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getTokenConfigurationProcessById`, {params, headers: await sharedService.getAuthHeader()});    
  }
  // async getTokenConfigurationProcess(){
  //   return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getTokenConfigurationProcess`, {headers: await sharedService.getAuthHeader()});    
  // }

  async getLastTokenConfigurationProcess(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getLastTokenConfigurationProcess`, {headers: await sharedService.getAuthHeader()});    
  }

  async saveTokenTypeAndDetails(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/saveTokenTypeAndDetails`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getTokenTypeAndDetails(tokenTypeAndDetailsId: string){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getTokenTypeAndDetails`, {params: {tokenTypeAndDetailsId}, headers: await sharedService.getAuthHeader()});
  }

  async getPreviousTokenTypeAndDetails(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getPreviousTokenTypeAndDetails`, {headers: await sharedService.getAuthHeader()});
  }

  async saveRule144Config(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/saveRule144Config`, req, {headers: await sharedService.getAuthHeader()});
  }

  async currentOfferingStatus(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/currentOfferingStatus`, req, {headers: await sharedService.getAuthHeader()});
  }
  async handlePrimeTrustEscrowAccount(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/handlePrimeTrustEscrowAccount`, req, {headers: await sharedService.getAuthHeader()});
  }

  async handlePrimeTrustSign(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/handlePrimeTrustSign`, req, {headers: await sharedService.getAuthHeader()});
  }
  async fileEscrowServiceApplication(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/fileEscrowServiceApplication`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getSignURL(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/investor/getSignURL`,data,{headers:await sharedService.getAuthHeader()});
  }


  async getRule144Config(rule144ConfigId: string){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getRule144Config`, {params: {rule144ConfigId}, headers: await sharedService.getAuthHeader()});
  }

  async getPreviousRule144Config(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getPreviousRule144Config`, {headers: await sharedService.getAuthHeader()});
  }

  async savePhase(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/savePhase`, req, {headers: await sharedService.getAuthHeader()});
  }

  async editPhase(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/editPhase`, req, {headers: await sharedService.getAuthHeader()});
  }


  async getPhases(tokenConfigurationProcessId: string, limit: number | null, searchPage: number | null, all?: boolean){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getPhases`, {params: {tokenConfigurationProcessId, limit, searchPage, all}, headers: await sharedService.getAuthHeader()});
  }

  async getPhase(tokenPhaseId: string){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getPhase`, {params: {tokenPhaseId}, headers: await sharedService.getAuthHeader()});
  }

  async saveTokenSymbolReservation(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/saveTokenSymbolReservation`, req, {headers: await sharedService.getAuthHeader()});
  }

  async submitTokenConfigForReview(req: {tokenConfigurationProcessId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/submitTokenConfigForReview`, req, {headers: await sharedService.getAuthHeader()});
  }

  async reviewFinalTokenConfig(req: {status: 'verified' | 'rejected', comments: string, tokenConfigurationProcessId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/reviewFinalTokenConfig`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getRegulation(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getRegulation`, {headers: await sharedService.getAuthHeader()});
  }

  async saveCheckboxesStatus(req: {checkboxes: {[key: string]: string}}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/saveCheckboxesStatus`, req, {headers: await sharedService.getAuthHeader()});
  }

  async submitCheckboxesStatus() {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/submitCheckboxesStatus`, {}, {headers: await sharedService.getAuthHeader()});
  }

  async adminsRolesCreated(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/adminsRolesCreated`, {headers: await sharedService.getAuthHeader()});    
  }

  async getIssuersWallets(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getIssuersWallets`, {headers: await sharedService.getAuthHeader()});    
  }


  async getIssuerQuestionAnswer(){
    return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getIssuerQuestionAnswer`, {headers: await sharedService.getAuthHeader()});    
  }

  async closeSale(req: {tokenConfigurationProcessId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/closeSale`, req, {headers: await sharedService.getAuthHeader()});
  }

  
  async addLegend(req: {tokenConfigurationProcessId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/addLegend`, req, {headers: await sharedService.getAuthHeader()});
  }

  async submitPublicDocs(req) {
    return axios.post<any, APIResponse>(`${this.APIURL}/tokenConfiguration/submitPublicDocs`, req, {headers: await sharedService.getAuthHeader()});
  }





}