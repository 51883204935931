import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateAdminRole from './CreateAdminRoles'
import ManageAllAdminRoles  from './ManageAllAdminRoles'
import AddServiceProviders from './AddServiceProviders'
import ViewAllIssuers from './ViewAllIssuers';
export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create-admin-role`}>
        <CreateAdminRole/>
      </Route>
      <Route path={`${path}/manage-all-admin-roles`}>
        <ManageAllAdminRoles/>
      </Route>
      <Route path={`${path}/add-service-providers`}>
        <AddServiceProviders/>
      </Route>
      <Route path={`${path}/view-all-issuers`}>
        <ViewAllIssuers/>
      </Route>
    </Switch>
  )
}