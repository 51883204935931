import React,{useState,useEffect} from 'react';
import {Button,Input,Row,Card,Col,Form,Typography,Select,message,Space} from 'antd';
import {PlatformComplianceAdminService} from '../PlatformComplianceAdmin.service';
import dueDiligenceData from '../dueDiligenceData'
import { publish } from 'rxjs/operators';
const {Title}=Typography;
const {TextArea}=Input;
const {Option}=Select;
const FormItem=Form.Item;
const platformComplianceAdminService=new PlatformComplianceAdminService();
const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };
  
export default ()=>{
    const [form]=Form.useForm();  
    const [submitting,setSubmitting]=useState(false);
    const [issuersList,setIssuersList]=useState(null);
    const [questionsList,setQuestionsList]=useState([])

    useEffect(()=>{
        (async ()=>{
            const issuersListResponse=await platformComplianceAdminService.getIssuersList();
            if(issuersListResponse.success)
            {
                if(issuersListResponse.data.length>0)
                {
                    await setIssuersList(issuersListResponse.data);
                }
                else
                {
                    message.error("No issuer(s) present on the platform");
                }
            }
            else
            {
                message.error("Error in loading issuers list");
            }
        })();
    },[]);

    const addDueDiligence=async (formData)=>{
        setSubmitting(true);
        console.log({formData});
        formData.answer='';
        formData.isAnswered=false;
        formData.isReviewed=false;
        const dataSubmissionResult=await platformComplianceAdminService.submitDueDiligenceQuestion(formData);
        if(dataSubmissionResult.success && dataSubmissionResult.data)
        {
            await form.resetFields();
            message.success("Due diligence addded succesfully.");
        }
        else
        {
            message.error("Some problem occured while uploading question.");
        }
        setSubmitting(false);
    }

    const loadQuestion= (selected)=> {
      if(selected==="public") {
        setQuestionsList(dueDiligenceData.public);
      }
      else if (selected === "private") {
        setQuestionsList(dueDiligenceData.private);
      }
      else setQuestionsList([]);
    }
    return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Add Due Diligence
          </Title>

          <Form 
            {...formItemLayout}
            form={form}
            name='addDueDiligenceForm'
            key='addDueDiligenceForm'
            onFinish={addDueDiligence}
            style={{ margin: '2% 10% 0 10%', width: '60%' }}
          >
          <FormItem
            label='Select Issuer'
            name='companyId'
            key="issuerName"
            rules={[
                {
                    required:true,
                    message:'Please select a issuer!',
                }
            ]}
          >
              <Select key="issuerDropDown" disabled={submitting} >
                    {issuersList && issuersList.map((data,index)=><Option key={index} value={data.companyId} >{data.companyName}</Option>)}
              </Select>

          </FormItem>
          
          <FormItem
            label='Select Visiblity'
            name='visiblity'
            key="visiblity"
            rules={[
                {
                    required:true,
                    message:'Please select the visiblity!',
                }
            ]}
          >
              <Select key='visiblityDropDown' onChange={loadQuestion} disabled={submitting}>
                    <Option key="public" value="public">Public</Option>
                    <Option key="private" value="private">Private</Option>
              </Select>

          </FormItem>
        
         <FormItem
            label="Enter Question"
            name="question"
            key="question"
            rules={[
                {
                    required:true,
                    message:"Question can't be empty!",
                }
            ]}
         >
             <Select key="questionsDropDown" disabled={submitting} >
                {questionsList?.map(data => <Option key={data} value={data} >{data}</Option>)}
             </Select>
         </FormItem>
         <FormItem
            label="Select Answer type"
            name="answerType"
            key="answerType"
            rules={[
                {
                    required:true,
                    message:'Please select answer type!',
                }
            ]}
         >
             <Select
             key="answerTypeDropDown"
             disabled={submitting}
             >
                 <Option key='option' value='option' >option(yes/no)</Option>
                 <Option key='description' value='textArea' >Description(TextArea)</Option>
                 <Option key='attachment' value='attachment' >File(s) upload</Option>
             </Select>

         </FormItem>

         <Form.Item {...tailFormItemLayout}>
                <Space>
                <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
                <Button  loading={submitting} onClick={()=>{form.resetFields()}} >Cancel</Button>
                </Space>
        </Form.Item>
         </Form>
        </Card>
     </Col>
    </Row>
    </>
    );
}