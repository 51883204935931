import React,{useState,useEffect} from 'react';
import {Button,Input,Row,Col,Card,Form,Typography,message,Space,List,Radio, Empty,Spin,Table} from 'antd';
import {IssuerSuperAdminService} from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import {AuthService} from '../../Shared/Auth.service'
const {Title}=Typography;
const {TextArea}=Input;
const auth=new AuthService();
const useUserContext=()=> auth.useUserContext();
// const sharedService=new SharedService();
const issuerSuperAdminService=new IssuerSuperAdminService();

export default ()=>{
    const {userInfo}=useUserContext();    
    const [dueDiligenceList,setDueDiligenceList]=useState(null);
    const [dueDiligenceListView,setDueDiligenceListView]=useState(true);
    const [dueDiligenceForm]=Form.useForm();
    const [submitting,setSubmitting]=useState(false);
    const [answeredDueDiligenceList,setAnsweredDueDligenceList]=useState(null);
    const [currentQuestionToAnswer,setCurrentQuestionToAnswer]=useState(null);
    const [heading,setHeading]=useState("Answer Due Diligence");
    const [answerView,setAnswerView]=useState(true);
    const columns=[
        {
          title:'Question',
          dataIndex:'question',
          key:'question'
        },
        {
          title:'Answer',
          dataIndex:'answer',
          key:'answer'
        },
        {
          title:'Status',
          dataIndex:'status',
          key:'status',
          render:(text,dueDiligenceQuestion)=>(<>{dueDiligenceQuestion.isReviewed && 'Approved'}
                                                {!dueDiligenceQuestion.isReviewed && 'Under Review'}
          </>)
        }
    
      ]
    useEffect(()=>{
        (async ()=>{
            const getDueDiligenceListResult=await issuerSuperAdminService.getDueDiligenceList({companyId:userInfo.company.id}); 
            if(getDueDiligenceListResult.success)
            {
                if(getDueDiligenceListResult.data.length>0)
                {
                    await setDueDiligenceList(getDueDiligenceListResult.data);
                }
                else
                {
                    await setDueDiligenceList([]);
                }
            }
            else
            {
                await setDueDiligenceList([]);
                message.error("Error in loading due diligence.");
            }
            const getAnsweredDueDligenceListResult=await issuerSuperAdminService.getAnsweredDueDligenceList({companyId:userInfo.company.id});
            if(getAnsweredDueDligenceListResult.success)
            {
                if(getAnsweredDueDligenceListResult.data.length>0)
                {
                    await setAnsweredDueDligenceList(getAnsweredDueDligenceListResult.data);
                }
                else
                {
                    await setAnsweredDueDligenceList([]);
                }
            }
        })();
    },[dueDiligenceListView]);

    const answerDueDiligence=(data)=>{
        setCurrentQuestionToAnswer(data);
        setDueDiligenceListView(false);
    }

    const submitAnswerForDueDiligence=async (data)=>{
        await setSubmitting(true);
        let dataToSend=await {...currentQuestionToAnswer,...data};
        let response=await issuerSuperAdminService.setDueDiligenceAnswer(dataToSend);
        if(response.success && response.data)
        {
            message.success("Answered.");
        }
        else
        {
            message.error("Error while saving data!!");
        }
        await setDueDiligenceListView(true);
        await setSubmitting(false);
    }

    return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            {heading}
          </Title>
          <div hidden={!answerView} >
              <Space>
                <a  onClick={()=>{
                    setHeading("View Answer Status");
                    setAnswerView(false);
                }}>View answer review status</a>
              </Space>
              <br/>
          <div hidden={!dueDiligenceListView} >
          {dueDiligenceList?<List
          style={{textAlign:'left'}}
          loading={!dueDiligenceList}
          key="dueDiligenceList"
          >
              {dueDiligenceList?.map((data,index)=>
              <List.Item 
              key={index} 
              style={{cursor:'pointer'}} 
              actions={[
                  <Button type="primary" onClick={()=>answerDueDiligence(data)} >Answer</Button>
              ]}
              >
                <List.Item.Meta title={data.question}></List.Item.Meta> 
              </List.Item>)}
        </List>:<Spin/>}
        {(dueDiligenceList && dueDiligenceList?.length === 0 ) && <Empty/>}
        </div>

        <div hidden={dueDiligenceListView} >
              <Form
                key="dueDiligenceAnswerForm"
                form={dueDiligenceForm}
                onFinish={submitAnswerForDueDiligence}
              >
                  <Form.Item
                  label={currentQuestionToAnswer?.question}
                  key={currentQuestionToAnswer?.question}
                  >
                  </Form.Item>

                  {(currentQuestionToAnswer && currentQuestionToAnswer?.answerType=='option') && <Form.Item
                    key="optionAnswer"
                    label="Answer:"
                    name="answer"
                    rules={[
                        {
                            required:true,
                            message:'Please select a option'
                        }
                    ]}
                  >
                      <Radio.Group disabled={submitting} >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                  </Form.Item>}
                  
                  {(currentQuestionToAnswer && currentQuestionToAnswer?.answerType === 'textArea') && <Form.Item
                    key="optionAnswer"
                    label="Answer:"
                    name="answer"
                    rules={[
                        {
                            required:true,
                            message:'Please select a option'
                        }
                    ]}
                  >
                      <TextArea disabled={submitting}></TextArea>
                  </Form.Item>}

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit"  loading={submitting} >Submit</Button>
                            <Button onClick={()=>{
                                setCurrentQuestionToAnswer(null);
                                setDueDiligenceListView(true);
                            }} loading={submitting} >Cancel</Button>
                        </Space>
                    </Form.Item>
              </Form>
        </div>
    </div>
    <div hidden={answerView} >
            <Space>
                <a onClick={()=>{
                    setHeading("Answer due diligence");
                    setAnswerView(true);
                }}>Answer due diligence</a>
              </Space>
              <br/>
        {answeredDueDiligenceList==null && <Spin/>}
        {(answeredDueDiligenceList && answeredDueDiligenceList?.length===0 )&& <Empty/>}
        {(answeredDueDiligenceList && answeredDueDiligenceList?.length>0) &&
        <Table dataSource={answeredDueDiligenceList} columns={columns}  />
        }
    </div>
        </Card>
     </Col>
    </Row>
    </>
    );
}