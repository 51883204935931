import React,{useState, useEffect,useRef,useCallback,forwardRef} from 'react'
import {Card,Col,Row,Typography,Form, Input,Button,Progress, Alert, Result,Steps, Divider,Select} from 'antd'
import {AuthService} from '../../Shared/Auth.service'
import { SharedService } from '../../Shared/Shared.service';
import {TeamMemberService} from '../TeamMember.service'
import {PicLeftOutlined,PicCenterOutlined,EditOutlined,CameraOutlined} from '@ant-design/icons'
import {Tabs} from 'antd'
import 'react-html5-camera-photo/build/css/index.css';
import Webcam from 'react-webcam'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const {Option}=Select;
const {Step}=Steps;
const {TabPane}=Tabs
const {Title}=Typography
const {TextArea}=Input
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();
const teamMemberService= new TeamMemberService();

const videoConstraints = {
  width: 600,
  height: 400,
  facingMode: "user"
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

export default ()=>{
  const [mainForm]=Form.useForm()
  const [licenceForm]=Form.useForm()
  const [licenceSubmitted,setLicenceSubmitted]=useState(false)
  const [defaultActiveKey,setDefaultActiveKey]=useState('form');
  const [passPortForm]=Form.useForm()
  const [formSubmited,setFormSubmited]=useState(false)
  const {userInfo,setUserInfo}=useUserContext()
  const [passportSubmitted,setPassportSubmitted]=useState(false)
  const [formData,setFormData]=useState({})
  const [disableFields,setDisableFields]=useState(false)
  const [submitting,setSubmitting]=useState(false)
  const [success,setSuccess]=useState(false)
  const [selfieSubmitted,setSelfieSubmitted]=useState(false)
  const webcamRef = useRef(null);
  const [imageSrc,setImageSrc]=useState(null)
  const [countries, setCountries] = useState();
  const [contactNumber,setContactNumber]=useState('')
  const [current,setCurrent]=useState(0);
  const [basicDetailsView,setBasicDetailsView]=useState(true);
  const [licenceUploadView,setLicenceUploadView]=useState(false);
  const [passportUploadView,setPassportUploadView]=useState(false);
  const [selfieUploadView,setSelfieUploadView]=useState(false);
  const [basicDetailsProgress,setBasicDetailsProgress]=useState('process');
  const [licenceDetailsProgress,setLicenceDetailsProgress]=useState('wait');
  const [passportDetailsProgress,setPassportDetailsProgress]=useState('wait');
  const [selfieDetailsProgress,setSelfieDetailsProgress]=useState('wait');
  const [licenceFrontSide, setLicenceFrontSide] = useState(
    {
      licenceFrontSide: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  const [licenceBackSide, setLicenceBackSide] = useState(
    {
      licenceBackSide: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  const [passportFile, setPassportFile] = useState(
    {
      passport: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );


  
  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
    })();
  }, []);
  

  useEffect(()=>{
    (async ()=>{
      console.log(userInfo._id);
      const response=await teamMemberService.getKYCDetails({teamMemberId:userInfo._id});
      if(response.success && response.data)
      {
        if(response.data.length>0)
        {
          setSuccess(true)
        }
      }
    })();
    },[])
    

      
     
const capture = useCallback(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          setImageSrc(imageSrc)
          console.log(imageSrc)
        },
        [webcamRef]
      );
const recapture =()=>{
          setImageSrc(null)
        }


const saveFormData=(data)=>{
  setDisableFields(true)
  let temp=Object.assign(formData,data);
  setFormData(temp);
  console.log(formData);
  if(formSubmited && !licenceSubmitted)
  {
    setLicenceSubmitted(true)
  }
  if(formSubmited && licenceSubmitted && !passportSubmitted)
  {
    setPassportSubmitted(true)
  }
  if(formSubmited && licenceSubmitted && passportSubmitted)
  {
    submitFormData()
  }
  setFormSubmited(true);
}
const submitFormData=async ()=>{
  setSelfieSubmitted(true)
  setSubmitting(true)
  formData.teamMemberId=userInfo._id;
  formData.firstName=userInfo.firstName;
  formData.lastName=userInfo.lastName;
  formData.email=userInfo.email;
  formData.contactNumber=userInfo.contactNumber;
  formData.country=userInfo.country;
  formData.state=userInfo.state;
  formData.approved=false;
  formData.rejected=false;
  formData.role=userInfo.role;
  formData.dateOfSubmission=Date.now();
  formData.approvedByICA=false;
  formData.isFromUSA=formData.pCountry.toLowerCase()==='united states of america'?true:false;
  formData.companyId=userInfo.company?.id;
  formData.company=userInfo.companyName.toLowerCase();
  console.log(formData)
  const response=await teamMemberService.submitKYCDetails(formData);
  if(response.success && response.data)
  {
    setSuccess(true)
  }
  setSubmitting(false)
}




const uploadFile = (e, key,setFilesObj,form) => {
  if(!e.target.files) return;

  const file =  e.target.files[0];
  console.log(file);

  if(!file) return;

  const fileType = sharedService.getFileType(file.name);

  setFilesObj(prev => {
    const current = sharedService.clone(prev);
    current[key].URL = null;
    return current;
  });

teamMemberService.uploadFile(file, (uploadFilePercent) => {
  setFilesObj(prev => {
    const current = sharedService.clone(prev);
    current[key].uploading = true;
    current[key].uploadFilePercentage = uploadFilePercent;
    return current;
  });

}, (err) => {
  console.error(err);
  setFilesObj(prev => {
    const current = sharedService.clone(prev);
    current[key].uploading = false;
    return current;
  });
  
}, async(response) => {

  if (response.success) {
    const URL = response.data.url;
    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = URL;
      current[key].uploading = false;
      current[key].name = file.name;
      current[key].type = fileType.toLowerCase()
      return current;
    });
    form.setFieldsValue({[key]: URL});
    
  } else {
    console.error(response.error);
    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].uploading = false;
      return current;
    });
  }

});
}


useEffect(()=>{
  (()=>{
  
  if(formSubmited)
  {
    setDefaultActiveKey('licence');
    setBasicDetailsView(false);
    setBasicDetailsProgress('finish');
    setLicenceUploadView(true);
    setLicenceDetailsProgress('process');
    setCurrent(1);
  }
  if(licenceSubmitted)
  {
    setDefaultActiveKey('passport');
    setLicenceUploadView(false);
    setLicenceDetailsProgress('finish');
    setPassportUploadView(true);
    setPassportDetailsProgress('process');
    setCurrent(2);
  }
  if(passportSubmitted)
  {
    setDefaultActiveKey('selfie');
    setPassportUploadView(false);
    setPassportDetailsProgress('finish');
    setSelfieUploadView(true);
    setSelfieDetailsProgress('process');
    setCurrent(3);
  }
  
  })()
  },[formSubmited,licenceSubmitted,passportSubmitted])
  
    return (
      <>
      <br/>
       <Row justify="center">
        <Col span={22} style={{textAlign:'center'}}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
              >
              KYC Verification
            </Title> 
            <div hidden={success}>
            <Steps 
                type="navigation" 
                current={current} 
                className='site-navigation-steps'
                >
                  <Step
                  title='Step 1'
                  description='Basic Details'
                  status={basicDetailsProgress}
                  />
              
                  <Step
                  title='Step 2'
                  description= 'Driving Licence'
                  status={licenceDetailsProgress}
                  />
  
                  <Step
                  title='Step 3'
                  description='Passport'
                  status={passportDetailsProgress}
                  />
  
                  <Step
                  title='Step 4'
                  description='Selfie'
                  status={selfieDetailsProgress}
                  />
                  </Steps>
            <div id='basicDetailsDivision' hidden={!basicDetailsView} >
            {disableFields && <Alert type="success" message="Your details submitted now please upload your documents"></Alert>} 
            <Form
            {...formItemLayout}
            form={mainForm}
            onFinish={saveFormData}
            style={{ margin: '2% 10% 0 10%', width: '60%' }}
            >
  
              <h3 align="left">Personal Details</h3>
              <h4 align="left">Full Name:</h4>
              <Row>
                <Col>
              <Form.Item 
              //label='First Name'
              name='firstName'
              rules={[
                {
                  required:true,
                  message:"First Name can't be empty"
                }
              ]}
              >
                <Input placeholder="First Name" disabled={disableFields}></Input>
              </Form.Item >
              </Col>
              <Col>
              <Form.Item 
              //label='Middle Name'
              name='middleName'
              >
                <Input placeholder="Middle Name" disabled={disableFields}></Input>
              
              </Form.Item >
              </Col>
              </Row>
              <Row>
              <Col>
              <Form.Item 
              //label='Last Name'
              name='lastName'
              rules={[
                {
                  required:true,
                  message:"Last Name can't be empty"
                }
              ]}
              >
                <Input placeholder="Last Name" disabled={disableFields}></Input>
              
              </Form.Item >
              </Col>
              </Row>
              <h4 align="left">Date of Birth:</h4>
              <Form.Item 
              //label='Date of Birth'
              style={{ alignSelf:"left",width:300}}
              name='dob'
              rules={[
                {
                  required:true,
                  message:"Date of Birth Name can't be empty"
                }
              ]}
              >
                <Input type='date'  disabled={disableFields}></Input>
              
              </Form.Item >
              
              <h3 align="left" >Present Address</h3>
              <Form.Item 
              //label=' '
              name='pAStreetAddress1'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              
              >
                
                <Input placeholder="Street Address" disabled={disableFields} ></Input>
              </Form.Item >
              <Form.Item 
              //label=' '
              name='pAStreetAddress2'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              
              >
                
                <Input placeholder="Street Address2" disabled={disableFields} ></Input>
              </Form.Item >
              <Row>
                <Col>
                <Form.Item 
              //label=' '
              name='pACity'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              >
                <Input placeholder="city" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
                <Col>
                <Form.Item 
              //label=' '
              name='pAState'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              >
                <Input placeholder="State" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
              </Row>
              <Row>
                <Col>
                <Form.Item 
              //label=' '
              name='pAZipCode'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              >
                <Input type='number' placeholder="Zip/Postal Code" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
                <Col>
                <Form.Item 
              //label=' '
              name='pACountry'
              rules={[
                {
                  required:true,
                  message:"Present Address can't be empty"
                }
              ]}
              >
                <Select showSearch placeholder="Select Country" disabled={disableFields} style={{width:300}}>
                    {countries?.map(country => 
                      <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                    )}
                  </Select>
              </Form.Item >
                </Col>
              </Row>
  
              <h3 align="left" >Permanent Address</h3>
              <Form.Item 
              //label=' '
              name='pStreetAddress1'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              
              >
                
                <Input placeholder="Street Address" disabled={disableFields} ></Input>
              </Form.Item >
              <Form.Item 
              //label=' '
              name='pStreetAddress2'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              
              >
                
                <Input placeholder="Street Address2" disabled={disableFields} ></Input>
              </Form.Item >
              <Row>
                <Col>
                <Form.Item 
              //label=' '
              name='pCity'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              >
                <Input placeholder="city" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
                <Col>
                <Form.Item 
              //label=' '
              name='pState'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              >
                <Input placeholder="State" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
              </Row>
              <Row>
                <Col>
                <Form.Item 
              //label=' '
              name='pZipCode'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              >
                <Input type='number' placeholder="Zip/Postal Code" disabled={disableFields} ></Input>
              </Form.Item >
                </Col>
                <Col>
                <Form.Item 
              //label=' '
              name='pCountry'
              rules={[
                {
                  required:true,
                  message:"Permanent Address can't be empty"
                }
              ]}
              >
                <Select showSearch placeholder="Select Country" disabled={disableFields} style={{width:300}}>
                    {countries?.map(country => 
                      <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                    )}
                  </Select>
              </Form.Item >
                </Col>
              </Row>
  
  
              <h3 align="left" >Contact Details</h3>
              <Form.Item 
              //label=''
              name='contactNumber'
              rules={[
                {
                  required:true,
                  message:"Contact number can't be empty"
                }
              ]}
              
              >
                <PhoneInput
                disabled={disableFields}
                ></PhoneInput>
              </Form.Item >
              <Form.Item 
              //label=' '
              name='email'
              rules={[
                {
                  required:true,
                  message:"Email Address can't be empty"
                }
              ]}
              
              >
                
                <Input type="email" placeholder="Email" disabled={disableFields} ></Input>
              </Form.Item >
  
              <Form.Item 
              {...tailFormItemLayout}
              >
                <Button htmlType='submit'  type='primary' disabled={disableFields} >Submit</Button >
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button htmlType='button' onClick={()=>mainForm.resetFields()} disabled={disableFields} >Cancel</Button>
              </Form.Item >
            
            </Form>
           
            </div>
  
            <div  id='licenceUploadDivision' hidden={!licenceUploadView} >          
              <Form
              {...formItemLayout}
              
              form={licenceForm}
              onFinish={saveFormData}
              style={{ margin: '2% 10% 0 10%', width: '60%' }}
              >
                <Form.Item
                label="Front Side"
                name="licenceFrontSide"
                rules={[
                  {
                    required:true,
                    message:"Please select a image !"
                  }
                ]}
                > 
                <Input type='file' onChange={e => { uploadFile(e, 'licenceFrontSide',setLicenceFrontSide,licenceForm) }} disabled={licenceSubmitted} />
                {licenceFrontSide.licenceFrontSide.uploadFilePercentage>0 && 
                <Progress percent={licenceFrontSide.licenceFrontSide.uploadFilePercentage} />}
                <br/>
                {licenceFrontSide.licenceFrontSide.uploadFilePercentage==100 && <img height={250} width={500} src={licenceFrontSide.licenceFrontSide?.URL}/>}
                  </Form.Item>
                  
                  <Divider/>
                  <Form.Item
                label="Back Side"
                name="licenceBackSide"
                rules={[
                  {
                    required:true,
                    message:"Please select a image !"
                  }
                ]}
                > 
                <Input type='file' onChange={e => { uploadFile(e, 'licenceBackSide',setLicenceBackSide,licenceForm) }} disabled={licenceSubmitted} />
                {licenceBackSide.licenceBackSide.uploadFilePercentage>0 && 
                <Progress percent={licenceBackSide.licenceBackSide.uploadFilePercentage} />}
                <br/>
                {licenceBackSide.licenceBackSide.uploadFilePercentage==100 && <img height={250} width={500} src={licenceBackSide.licenceBackSide?.URL}/>}
                
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                  <Button htmlType='submit' type='primary' disabled={licenceSubmitted} >Submit</Button >
                     &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button htmlType="button" onClick={()=>licenceForm.resetFields()} disabled={licenceSubmitted} >CANCEL</Button>
                  </Form.Item>
  
                </Form>
            </div>
  
            <div id='passportUploadDivision' hidden={!passportUploadView}  >
             <Form
              {...formItemLayout}
              form={passPortForm}
              onFinish={saveFormData}
              style={{ margin: '2% 10% 0 10%', width: '60%' }}
              >
               <Form.Item
               label="Passport"
               name="passport"
               rules={[{
                 required:true,
                 message:"Please upload a image!"
               }]}
               >
                 <Input type='file' onChange={e => { uploadFile(e,'passport',setPassportFile,passPortForm) }} disabled={passportSubmitted} />
                {passportFile.passport.uploadFilePercentage>0 && 
                <Progress percent={passportFile.passport.uploadFilePercentage} />}
                <br/>
                {passportFile.passport.uploadFilePercentage==100 && <img height={250} width={500} src={passportFile.passport?.URL}/>}
                 
                 </Form.Item> 
                 <Form.Item {...tailFormItemLayout}>
                  <Button htmlType='submit' type='primary' disabled={passportSubmitted} loading={submitting} >Submit</Button >
                     &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button htmlType="button" onClick={()=>passPortForm.resetFields()} disabled={passportSubmitted} loading={submitting} >CANCEL</Button>
                  </Form.Item>
              </Form>
            </div>
            <div id='selfieUploadDivision'  hidden={!selfieUploadView} >
        {(imageSrc===null && selfieUploadView ) && <>
        <Webcam
          audio={false}
          height={400}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={600}
          videoConstraints={videoConstraints}
        />
        <br/>
        <br/>
        <Button type='primary' onClick={capture}>Capture Image</Button>
      </>}
      {imageSrc!==null&& 
      <> 
      <img src={imageSrc}></img>
      <br/>
      <br/>
      <Button  onClick={recapture}>Retake Image</Button>&nbsp;&nbsp;&nbsp;&nbsp;
      <Button type='primary' onClick={()=>saveFormData({selfie:imageSrc})} loading={submitting}>Submit</Button>
      </>
      }
      </div>
  
            </div>
            <div hidden={!success}>
            <Result
              status="success"
               title="Successfully submitted KYC details"
                subTitle="You details are submitted.Please wait for approval."
               extra={[
               <Button type="primary" key="Home" href="home">
                  Home
                </Button>,
              ]}/>
            </div>
            </Card>
        </Col>
       </Row>
      </>
  
    )
  }