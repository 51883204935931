import React, {useState, useEffect} from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const history = useHistory();
  
  const [reqErr, setReqErr] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(true);

  const {userInfo, setUserInfo} = useUserContext();
  
  useEffect(() => {
    if(!userInfo) return;
    if(userInfo.role === 'platform issuer admin') {
      history.push('/platform-issuer-admin/issuer-reg-reqs');      
    } else if(userInfo.role === 'issuer super admin') {
      history.push('/issuer-super-admin/basic-info-verif');
    } else if(userInfo.role === 'employee' || userInfo.role === 'contractor_consultant_Advisor') {
      history.push(`/team-member/vesting-schedule`);
    }else if(userInfo.role==='issuer token admin') {
      history.push('/token-dashboard');
    }
    else if(userInfo.role==='platform super admin') {
      history.push('/platform-super-admin/create-admin-role');
    } else if(userInfo.role === 'issuer counsel') {
      history.push('/token-configurations');
    }
    else if(userInfo.role === 'issuer company officer') {
      history.push('/token-configurations');
    }
    else if(userInfo.role==='investor')
    {
      history.push('/investor/home');
    }
    else if(userInfo.role==='platform compliance admin')
    {
      history.push('/platform-compliance-admin/kyc-verification-request')
    }
    else if(userInfo.role==='issuer compliance admin')
    {
      history.push('/issuer-compliance-admin/kyc-verification-request')
    }
    // const subscription = props.source.subscribe();
    // return () => {
    //   subscription.unsubscribe();
    // };
  },
  [userInfo, history],
);
    

  const handleCaptchaChange = (value: any) => {
    setExpired(false);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };
  
  const login = async ({email, password}: {[name: string]: string}) => {
    setSubmitting(true);
    
    // CHECK IF EMAIL IS VERIFIED
    try {
      const response = await accountService.login(email, password);
      
      if (response.success) {
        sharedService.setToken(response.data.token);
        setUserInfo({...response.data.user});
        
      } else {
        console.error(response.error.message);
        setReqErr(response.error.message);
      }

    } catch (err) {
      console.error(err);
      setReqErr('An error ocurred. Try again later');
    }

    setSubmitting(false);

  }

  return (
    <Row justify="center" style={{marginTop:"60px"}}>
      <Col span={16} style={{textAlign:'center'}}>
        <Card style={{
          // margin: '5% 0 0 15%',
          // width: '70%'
        }}>
          <h2 style={{textAlign:'center'}}>Log In</h2>
          <Form 
            // form = {form}
            // size='middle'
            onFinish={login}
            className='login-form'
            // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
            >
    
            <Form.Item
              // label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
              validateStatus={reqErr && 'error'}
              style={{width: '40%', margin: 'auto', marginBottom: '10px'}}
              >
              <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder='Email'/>
            </Form.Item>
            
            <Form.Item
              // label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
              validateStatus={reqErr && 'error'}
              help={reqErr}
              style={{width: '40%', margin: 'auto'}}>
              <Input.Password prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
            </Form.Item>
    
            <div style={{ marginTop: '30px', display: 'inline-block' }}>
              <ReCAPTCHA 
                theme='light'
                sitekey={environment.captchaKey}
                onChange={handleCaptchaChange}/>
            </div>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                loading={submitting}
                disabled={expired}
              >
                Log in
              </Button>
              <br/>
              <a className='login-form-forgot' href="/account/login">
                Forgot password
              </a>
              <br />
              <Link to='/account/register/investor'>register now!</Link>
              {/* <br/> */}
              {/* <>Or </> */}
              <br/>
              {/* <Link to='/account/resend-email-verification-code'>resend email verification link</Link> */}
            </Form.Item>
    
          </Form>
    
        </Card>

      </Col>

    </Row>
  )
}