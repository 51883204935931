import React, { useState, useEffect, Fragment } from 'react';
import { Typography, Form, Card, Row, Input, Select, DatePicker, Radio, Button, Modal, Col, Progress, Result, message, Descriptions, Spin  } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { SharedService } from '../../Shared/Shared.service';
import { Country, IssuerBasicInfoVerificationRequest, APIResponse, IssuerRegistrationRequest } from '../../Shared/interfaces';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { AuthService } from '../../Shared/Auth.service';
import moment from 'moment';

const { Text, Title } = Typography;
const { Option } = Select;
const {TextArea} = Input;


const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 14,
      offset: 10
    }
  }
};

const regulationRadioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const useUserContext = () => new AuthService().useUserContext();
interface State{
_id:string;
state_name:string;
state_id:string;
}

export default () => {
  const [isSectionOpen, setIsSectionOpen] = useState({issuerInfo: true, issuerAdditionalInfo: false, legalInfo: true });
  const [submitting, setSubmitting] = useState(false);
  const {userInfo, setUserInfo} = useUserContext();

  const [countries, setCountries] = useState<Country[]>();
  const [basicInfo, setBasicInfo] = useState<IssuerBasicInfoVerificationRequest>();
  const [cCountry,setCCountry]= useState(false);
  const [usaStates,setUSAStates]= useState<State[]>();
  const [form] = Form.useForm();

  const [basicInfoFiles, setBasicInfoFiles] = useState(
    {
      companyRegFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }, 
      taxNumberRegFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }, 
      boardResolutionFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      SECFilingFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
    }
  );

  
  useEffect(() => {
    (async () => {
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
    })();},[]);


  useEffect(() => {
    (async () => {
      if(!userInfo?.status.basicInfo) {
        const [_registrationReq, _countries] = (await Promise.all([
          issuerSuperAdminService.getRegistrationRequest(),
          sharedService.getCountries()
        ])).map(res => res.data);
        setCountries(_countries);
        form.setFieldsValue({companyName: (_registrationReq as IssuerRegistrationRequest).companyName})

      } else if(userInfo?.status.basicInfo === 'verified') {
        const response = await issuerSuperAdminService.getBasicInfoVerif();
        const _basicInfo: IssuerBasicInfoVerificationRequest  = response.data;
        setBasicInfo(_basicInfo);
      }
    })();
  }, [userInfo, form]);


  const regOptions = sharedService.regOptions;




  const SectionIcon = (key: string) => {
    return (
      isSectionOpen[key]?
      <MinusOutlined style={{ marginRight: '20px' }} onClick={() => {setIsSectionOpen(prev => ({...prev, [key]: !prev[key]}))}}/> : 
      <PlusOutlined style={{ marginRight: '20px' }} onClick={() => {setIsSectionOpen(prev => ({...prev, [key]: !prev[key]}))}}/>
    )
  }


  const getRegItem = (getFieldValue, regulation: string) => {
    return getFieldValue('regulation') === regulation? (
      <>
        <Form.Item style={{marginBottom:0}}
          label={`Issuer Filed for ${sharedService.regulationName(getFieldValue('regulation'))} Excemption with SEC`}
          name='SECFilingOption'
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}>
          <Radio.Group>
            <Radio key="yes" value={'yes'}>Yes</Radio>
            <Radio key="no" value={'no'}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item labelCol={{span:24}} style={{marginBottom:0}}
          label={"Upload SEC filing Document (Optional)"}
          name='SECFilingFile'
          >
            <>
              <Input type='file' onChange={e => { uploadFile(e, 'SECFilingFile') }} />
              {basicInfoFiles.SECFilingFile.uploadFilePercentage>0 && 
                <Progress percent={basicInfoFiles.SECFilingFile.uploadFilePercentage} />
              }
            </>
        </Form.Item>
      </>
    ): null
  };


  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if(!e.target.files) return;

    const file =  e.target.files[0];
    console.log(file);

    if(!file) return;
    
    
    const fileType = sharedService.getFileType(file.name);

    setBasicInfoFiles((prev) => ({[key]: {URL: null, ...prev[key]}, ...prev}));

    issuerSuperAdminService.uploadFile(file, (uploadFilePercent: number) => {
      setBasicInfoFiles((prev) => {
        console.log(uploadFilePercent);
        return ({...prev, [key]: {...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent}});
      });

    }, (err) => {
      console.error(err);
      setBasicInfoFiles((prev) => ({ ...prev, [key]: {...prev[key], uploading: false}}));
      
    }, async(response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        setBasicInfoFiles((prev) => ({...prev, [key]: {...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase()}}));
        form.setFieldsValue({[key]: file.name});
        
      } else {
        console.error(response.error);
        setBasicInfoFiles((prev) => ({ ...prev, [key]: {...prev[key], uploading: false}}));
      }

    });

  }

  const currentCountry=(value)=>{
    if(value=='United States of America')setCCountry(true);
    else setCCountry(false); 
 }
  const submitBasicInfoVerif = async (_form) => {
    const form = {..._form};
    
    form.dateOfIncorporate = (form.dateOfIncorporate.toDate() as Date).getTime();
    form.additionInfo = form.additionInfo || null;

    form.companyRegFile = {
      name: basicInfoFiles.companyRegFile.name,
      URL: basicInfoFiles.companyRegFile.URL,
      type: basicInfoFiles.companyRegFile.type,
    };
    form.taxNumberRegFile = {
      name: basicInfoFiles.taxNumberRegFile.name,
      URL: basicInfoFiles.taxNumberRegFile.URL,
      type: basicInfoFiles.taxNumberRegFile.type,
    };
    form.boardResolution.file = basicInfoFiles.boardResolutionFile.URL? {
      name: basicInfoFiles.boardResolutionFile.name,
      URL: basicInfoFiles.boardResolutionFile.URL,
      type: basicInfoFiles.boardResolutionFile.type,
    } : null;
    form.SECFilingFile = basicInfoFiles.SECFilingFile.URL? {
      name: basicInfoFiles.SECFilingFile.name,
      URL: basicInfoFiles.SECFilingFile.URL,
      type: basicInfoFiles.SECFilingFile.type,
    } : null;

    console.log(form);

    setSubmitting(true);

    const response = await issuerSuperAdminService.submitBasicInfoVerif({form});

    if (response.success) {

      if(userInfo) {
        // userInfo.status.basicInfo = 'pending';
        setUserInfo((prev:any) => ({...prev, status: {...prev.status, basicInfo: 'pending'}}));
      }

    } else {
      message.error(response.error.message);
      // notification.open({
      //   message: 'Error',
      //   description: response.error.message,
      //   duration: 0,
      // });
    }

    setSubmitting(false);
    
  }

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  }


  return (
    <>
      <br/>
    {!userInfo?.status.basicInfo && 
      <>
        <Title level={1} style={{ color: '#186AB4', textAlign:'center' }}>Basic Issuer Information</Title>
        <br></br>
        <Row justify="center">
          <Col span={16}>
            <Form {...formItemLayout} labelAlign="left" form={form} initialValues={{boardResolution: {option: 'upload'}, additionInfo:null}} onFinish={submitBasicInfoVerif}>

              <div>
                <Card style={{ borderRadius: '3px', borderTopColor: '#00008b', borderTopWidth: '3px' }}>
                  <Title level={2} style={{ color: '#186AB4',  }}>
                    {SectionIcon('issuerInfo')} ISSUER INFO
                  </Title>
                  <br></br>
                </Card>

                <Card hidden={!isSectionOpen.issuerInfo}>
                  <Card>
                    
                    <Form.Item 
                      label={`Company Name Issuing Tokens`}
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input disabled placeholder='Enter Company Name'/>
                    </Form.Item>

                    <Form.Item 
                      label='Company Registered Country'
                      name="companyCountry"
                      validateStatus={countries? '' : 'validating'}
                      hasFeedback
                      help= {countries? '' : "Loading Countries..."}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Select autoClearSearchValue showSearch  onChange={currentCountry}  placeholder="Select Country">
                        {countries?.sort(function (a, b){return b.name['en'].localeCompare(a.name['en']);}).map(country => 
                          <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                        )}
                      </Select>
                    </Form.Item>
                    { cCountry &&
                    <Form.Item 
                      label='Company Registered State'
                      name="companyState"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
               
                <Select showSearch placeholder="Select state"  >
                  {usaStates?.map(state => 
                    <Option key={state.state_id} value={state.state_name}>{state.state_name}</Option>
                 )}
                </Select>

                    </Form.Item>
                  }

                    { !cCountry &&
                    <Form.Item 
                      label='Company Registered State'
                      name="companyState"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                        <Input/>
                    </Form.Item>
                  }

                    <Form.Item 
                      label='Company City'
                      name="companyCity"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder='Company City'/>
                    </Form.Item>

                    <Form.Item 
                      label='Zip Code'
                      name="zipCode"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder='Enter Zip Code'/>
                    </Form.Item>
                        <Form.Item
                        label='Street Address 1'
                        name='street_address_1' 
                        rules={[{required:true,message:'Street address-1 is required'}]}>
                          <Input placeholder='Street address 1'/>
                        </Form.Item>
                        <Form.Item
                        label='Street Address 2'
                        name='street_address_2'
                        rules={[{message:'Street address 2 is required'}]}>
                          <Input placeholder='Street address 2'/>
                        </Form.Item>
                    <Form.Item 
                      label='Date of Incorporate'
                      name="dateOfIncorporate"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <DatePicker disabledDate={disabledDate} format="MMMM DD, YYYY" />
                    </Form.Item>

                    <Form.Item 
                      label='Government Tax ID Number'
                      name="govTaxIdNumber"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder='Enter Government Tax ID' maxLength={9} minLength={9}/>
                      {/* <Text
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'red'
                        }}
                      >
                        {taxIDErrFlag}
                      </Text> */}
                    </Form.Item>

                    <div className="ant-col ant-form-item-label ant-form-item-label-left ant-col-xs-24 ant-col-sm-8">
                      <label className="ant-form-item-required">Upload Documents</label>
                    </div>

                    <Card>
                      <Form.Item
                        label="Certificate of incorporation"
                        name='companyRegFile'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}>
                        <>
                          <Input type='file' onChange={e => { uploadFile(e, 'companyRegFile') }} />
                          {basicInfoFiles.companyRegFile.uploadFilePercentage>0 && 
                            <Progress percent={basicInfoFiles.companyRegFile.uploadFilePercentage} />
                          }
                        </>
                      </Form.Item>

                      <Form.Item 
                        label="Tax Number Registration Document(EIN)"
                        name='taxNumberRegFile'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}>
                        <>
                          <Input type='file' onChange={e => { uploadFile(e, 'taxNumberRegFile') }} />
                          {basicInfoFiles.taxNumberRegFile.uploadFilePercentage>0 && 
                            <Progress percent={basicInfoFiles.taxNumberRegFile.uploadFilePercentage} />
                          }
                        </>
                      </Form.Item>
                      
                      <Form.Item
                        label="Board Resolution on token Creation"
                        name={['boardResolution','option']}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}>
                        <Radio.Group>
                          <Radio key='Upload' value='upload'>
                            Upload
                          </Radio>
                          {/* <Radio key='Create' value='create'>
                            Create
                          </Radio> */}
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) => prevValues.boardResolution?.option !== currentValues.boardResolution?.option}
                      >
                        {({ getFieldValue }) => {
                          return getFieldValue('boardResolution')?.option === 'create' ? (
                            <>
                              <Form.Item
                                wrapperCol={{push:8}}
                                name={['boardResolution','textArea1']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required'
                                  }
                                ]}
                                >
                                <TextArea placeholder='Text Area message' allowClear />
                              </Form.Item>
                              <Form.Item 
                                wrapperCol={{push:8}}
                                name={['boardResolution','textArea2']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required'
                                  }
                                ]}
                                >
                                <TextArea placeholder='<Board member Names><Board member email addresses' allowClear />
                              </Form.Item>

                              <Form.Item wrapperCol={{push:8}}>
                                <Button
                                  style={{ marginLeft: '10px' }}
                                  type='primary'
                                  htmlType='button'
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </>
                          ) : 
                          <>
                            <Form.Item 
                              wrapperCol={{push:8}}
                              // name='boardResolutionFile'
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required'
                                }
                              ]}>
                                <>
                                  <Input type='file' onChange={e => { uploadFile(e, 'boardResolutionFile') }} />
                                  {basicInfoFiles.boardResolutionFile.uploadFilePercentage>0 && 
                                    <Progress percent={basicInfoFiles.boardResolutionFile.uploadFilePercentage} />
                                  }
                                </>
                            </Form.Item>
                          </>
                        }}
                      </Form.Item>

                    </Card>
                
                  </Card>
                </Card>
              </div>

              <div>
                <Card style={{ borderRadius: '3px', borderTopColor: '#00008b', borderTopWidth: '3px' }}>
                  <Title level={2} style={{ color: '#186AB4',  }}>
                  {SectionIcon('issuerAdditionalInfo')} ISSUER ADDITIONAL INFO
                  </Title>
                  <br></br>
                </Card>

                <Card hidden={!isSectionOpen.issuerAdditionalInfo}>
                  <Card>
                    <Form.Item labelCol={{span: 4}}
                      label={`Addition Info`}
                      name="additionInfo"
                    >
                      <TextArea placeholder="Enter addition Info (Optional)"/>
                    </Form.Item>
                  </Card>
                </Card>
              </div>
              
              <div>
                <Card style={{ borderRadius: '3px', borderTopColor: '#00008b', borderTopWidth: '3px' }} >
                  <Title level={2} style={{ color: '#186AB4',  }}>
                    {SectionIcon('legalInfo')} LEGAL INFO
                  </Title>
                  <br></br>
                </Card>

                <Card hidden={!isSectionOpen.legalInfo}>
                  <Card>
                    <Form.Item labelCol={{span: 4}}
                      label="Regulation"
                      name="regulation"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required'
                        }
                      ]}
                    >
                      <Radio.Group>
                        {regOptions.map(option => (
                          <Fragment key={option.value}>
                            <Radio value={option.value} style={regulationRadioStyle}>{option.name}</Radio>
                            <Form.Item style={{marginBottom: 0}}
                              shouldUpdate={(prevValues, currentValues) => prevValues.regulation !== currentValues.regulation}
                            >
                              {({ getFieldValue }) => getRegItem(getFieldValue, option.value)}
                            </Form.Item>
                          </Fragment>
                        ))}
                      </Radio.Group>
                    </Form.Item>

                  </Card>
                </Card>
              </div>
              
              <br/><br/>
              <Form.Item {...tailFormItemLayout}>
                <Button size='large' style={{ marginLeft: '10px' }} danger htmlType="button">Cancel</Button>
                <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={submitting}>Submit</Button>
              </Form.Item>
              <br/><br/>

            </Form>
          </Col>


        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Button type='danger'>Cancel</Button> */}
          <div>
            {/* <Button
              style={{ marginLeft: '10px' }}
              // type='primary'
              // onClick={e => showModal(e)}
            >
              Preview
            </Button> */}

            <Modal
              title='Preview'
              // visible={visible}
              // onOk={e => handleOk(e)}
              // onCancel={e => handleCancel(e)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <Text style={{ fontSize: '20px' }}>Issuer Info</Text>
                {/* <Text>CompanyName : {ComapanyName}</Text> */}
                {/* <Text>Country :{country}</Text> */}
                {/* <Text>Zip Code : {zipCode}</Text> */}
                {/* <Text>State :{state}</Text> */}
                {/* <Text>Date of Incorporate : {date}</Text> */}
                {/* <Text>Government Tax ID Number:{taxID}</Text> */}
                <Text>
                  Comapany Registration Document :
                  {/* {company_reg_uploads ? company_reg_uploads.name : ''} */}
                </Text>
                <Text>
                  Tax Number Registration Document :
                  {/* {tax_reg_uploads !== '' ? tax_reg_uploads.name : ''} */}
                </Text>
                <Text>Board Resolution ontoken Creation : 
                  {/* {radio} */}
                  </Text>
                {/* {radio === 'Upload' ? (
                  <Text>
                    Upload Document :
                    {board_res_uploads !== '' ? board_res_uploads.name : ''}
                  </Text>
                ) : (
                  <>
                    <Text>Text-Area : {text_area}</Text>

                    <Text>Board members List : {text_board}</Text>
                  </>
                )}{' '} */}
                <br></br>
                <Text style={{ fontSize: '20px' }}>ISSUER ADDITIONAL INFO</Text>
                <Text>Company Name Issuing Tokens</Text>
                <br></br>
                <Text style={{ fontSize: '20px' }}>LEGAL INFO</Text>
                <Text>Regulation : 
                  {/* {Regulation} */}
                  </Text>
                <Text>
                  Issuer filed for Regulation-CF excemption with SEC:{' '}
                  {/* {issuer_radio} */}
                </Text>
                <Text>
                  SEC filing Document(Optional) :
                  {/* {sec_filing_doc !== '' ? sec_filing_doc.name : ''} */}
                </Text>
                <Text></Text>
              </div>
            </Modal>
          </div>
        </div>
        <br></br>
      </>
    }
    {userInfo?.status.basicInfo === 'pending' && 
      <Row justify="center">
        <Col span={16} style={{textAlign:'center'}}>
          <Card>
            <h2 style={{textAlign:'center'}}>Verification request submitted</h2>
            <Result
              title={`Platform issuer admin is reviewing your request`}
              // subTitle="CCAP team is reviewing your request"
              // extra={
              //   <Button key="console">
              //     Email didn't arrive?
              //   </Button>
              // }
              />
          </Card>
        </Col>
      </Row>
    }
    {userInfo?.status.basicInfo === 'rejected' && 
      <Row justify="center">
        <Col span={16} style={{textAlign:'center'}}>
          <Card>
            <h2 style={{textAlign:'center'}}>Verification request rejected</h2>
            <Result
              status="error"
              title={`Your request has been rejected`}
              />
          </Card>
        </Col>
      </Row>
    }
    {/*userInfo?.status.basicInfo === 'pending' && 
      <Row justify="center">
        <Col span={20}>
          <Card>
            {!basicInfo && 
              <div style={{textAlign:'center'}}>
                <Spin size='large'/>
              </div>
            }
            {basicInfo && 
              <Descriptions bordered column={2} title="Information">
                <Descriptions.Item label="Company Name Issuing Tokens">{basicInfo.companyName}</Descriptions.Item>
                <Descriptions.Item label="Company Registered Country">{basicInfo['displayableCountry']}</Descriptions.Item>
                <Descriptions.Item label="Company Registered State">{basicInfo.companyState}</Descriptions.Item>
                <Descriptions.Item label="Zip Code">{basicInfo.zipCode}</Descriptions.Item>
                <Descriptions.Item label="Date of Incorporate">{moment(basicInfo.dateOfIncorporate).format('LLL')}</Descriptions.Item>
                <Descriptions.Item label="Government Tax ID Number">{basicInfo.govTaxIdNumber}</Descriptions.Item>
                <Descriptions.Item label="Company registered documents">
                  <a href={basicInfo.companyRegFile.URL} download={basicInfo.companyRegFile.name} target="_blank" rel="noopener noreferrer">{basicInfo.companyRegFile.name}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Tax Number Registration Document">
                  <a href={basicInfo.taxNumberRegFile.URL} download={basicInfo.taxNumberRegFile.name} target="_blank" rel="noopener noreferrer">{basicInfo.taxNumberRegFile.name}</a>
                </Descriptions.Item>
                {basicInfo.boardResolution.option === 'upload' && 
                  <Descriptions.Item label="Board Resolution on token Creation">
                    <a href={basicInfo.boardResolution.file?.URL} download={basicInfo.boardResolution.file?.name} target="_blank" rel="noopener noreferrer">{basicInfo.boardResolution.file?.name}</a>
                  </Descriptions.Item>
                }
                {basicInfo.additionInfo && 
                  <Descriptions.Item label="Addition Info">{basicInfo.additionInfo}</Descriptions.Item>
                }
                <Descriptions.Item label="Regulation">{sharedService.regulationName(basicInfo.regulation)}</Descriptions.Item>
                <Descriptions.Item label="Issuer Filed for Regulation D Excemption with SEC">{basicInfo.SECFilingOption === 'yes'? 'Yes' : 'No'}</Descriptions.Item>
                {basicInfo.SECFilingFile && 
                  <Descriptions.Item label="SEC filing Document">
                    <a href={basicInfo.SECFilingFile?.URL} download={basicInfo.SECFilingFile?.name} target="_blank" rel="noopener noreferrer">{basicInfo.SECFilingFile?.name}</a>
                  </Descriptions.Item>
                }
              </Descriptions>
            }
          </Card>
        </Col>
      </Row>
      */
    }
    {userInfo?.status.basicInfo === 'verified' &&
    <div > 
      <Row justify="center">
        <Col span={20}>
          <Card>
            {!basicInfo && 
              <div style={{textAlign:'center'}}>
                <Spin size='large'/>
              </div>
            }
            {basicInfo && 
            <div>
            <div style={{backgroundColor:'#142A44',padding:'15px'}}>
              <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>&nbsp;&nbsp;Information</sub>
              <br/>
              <sub style={{color:'white',fontSize:'18px'}}>&nbsp;&nbsp;&nbsp;&nbsp;Company registered details</sub>
            </div>

            <Row>
              <Col span={12}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company Name:</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.companyName}</sub>
                </div>
              </Col>
              <Col span={12}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Country :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo['displayableCountry']}</sub>
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
            <Col span={12}>
            <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>State :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.companyState}</sub>
            </div>
            </Col>
              <Col span={12}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Zip :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.zipCode}</sub>
              </div>
              </Col>
            </Row>
            <br/>
            <Card style={{backgroundColor:'#F3F3F3'}}>
            <Row>
            <Col span={8}>
            <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Date Of Incorporate :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(basicInfo.dateOfIncorporate).format('LLL')}</sub>
            </div>
            </Col>
              <Col span={8}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>TAX ID Number :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.govTaxIdNumber}</sub>
            </div>
              </Col>
              <Col span={8}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Documents :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={basicInfo.companyRegFile.URL} download={basicInfo.companyRegFile.name} target="_blank" rel="noopener noreferrer">{basicInfo.companyRegFile.name}</a></sub>
            </div>
              </Col>
            </Row>
            <br/>
            <Row>
            <Col span={8}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>TAX Documents :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={basicInfo.taxNumberRegFile.URL} download={basicInfo.taxNumberRegFile.name} target="_blank" rel="noopener noreferrer">{basicInfo.taxNumberRegFile.name}</a></sub>
            </div>
              </Col>
              <Col span={8}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Regulation :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{sharedService.regulationName(basicInfo.regulation)}</sub>
            </div>
              </Col>
              <Col span={8}>
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Issuer filed for Regulation D with Excemption with SEC :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.SECFilingOption === 'yes'? 'Yes' : 'No'}</sub>
            </div>
              </Col>

            </Row>
            <br/>
            <Row>
            <Col span={8}>
            {basicInfo.boardResolution.option === 'upload' && 
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Board Resolution On Token Creation :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}><a href={basicInfo.boardResolution.file?.URL} download={basicInfo.boardResolution.file?.name} target="_blank" rel="noopener noreferrer">{basicInfo.boardResolution.file?.name}</a></sub>
            </div>
              }
              </Col>
              <Col span={8}>
            {basicInfo.additionInfo && 
              <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Addition Information :</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{basicInfo.additionInfo}</sub>
            </div>
              }
              </Col>
            </Row>
            </Card>
              </div>
            }
          </Card>
        </Col>
      </Row>
      </div>
    }

    </>
  );
}