import React, { useState, useEffect, Fragment } from 'react'
import { Card, Input, Button, Row, Col, Form, Typography, InputNumber, Radio, Descriptions, Spin, message } from 'antd';
import { SharedService } from '../../../Shared/Shared.service';
import { TokenConfigurationProcess, TokenTypeAndDetails, User } from '../../../Shared/interfaces';
import { TokenConfigurationService } from '../../TokenConfiguration.service';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();

const {Title} = Typography;
const {TextArea} = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 11 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 17,
      offset: 7
    }
  }
};
const typeOfSecurityRadioStyle = {
  display: 'block',
  // height: '30px',
  lineHeight: '30px',
};


const typeOfSecurityOptions = sharedService.typeOfSecurityOptions;
const commonStockOptions = sharedService.commonStockOptions;

const getEquitityBackSecurityItem = (getFieldValue, typeOfSecurity: string) => {
  return getFieldValue('typeOfSecurity') === typeOfSecurity? (
    <Card style={{borderColor:'darkgrey'}} bodyStyle={{paddingTop:0, paddingBottom:0}}>   
      <Form.Item labelCol={{push:2}} wrapperCol={{push:2}} style={{marginBottom: 0}}
        label={`Select one of the option for Equity Backed Securities`}
        name='stock'
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}>
        <Radio.Group>
          {commonStockOptions.map(option => (
            <Fragment key={option.value}>
              <Radio value={option.value} style={typeOfSecurityRadioStyle}>{option.name}</Radio>
                {option.value.startsWith('common') && 
                  <Form.Item style={{marginBottom: 0}}
                    shouldUpdate={(prevValues, currentValues) => prevValues.stock !== currentValues.stock}
                  >
                    {({ getFieldValue }) => (
                      getFieldValue('stock') === option.value?
                      <Card style={{marginRight:'30px', borderColor:'darkgrey'}} bodyStyle={{paddingTop:0, paddingBottom:0}}>
                        <Form.Item 
                          label="Voting Rights for Common Stock Equity Backed Security"
                          name="hasVotingRights"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <Radio.Group>
                            <Radio key="yes" value={true}>Yes</Radio>
                            <Radio key="no" value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                      : null
                    )}
                  </Form.Item>
                }
            </Fragment>
          ))}
        </Radio.Group>
      </Form.Item>
    </Card>
  ): null;
};


export default (
  {saveTokenTypeAndDetails, tokenConfigurationProcess, userInfo, isRelaunch}: 
  {saveTokenTypeAndDetails:any, tokenConfigurationProcess:TokenConfigurationProcess | undefined, userInfo: User, isRelaunch: boolean}) => {

  const [submitting, setSubmitting] = useState<boolean>();
  const [tokenTypeAndDetails, setTokenTypeAndDetails] = useState<TokenTypeAndDetails>();
  const [regulation, setRegulation] = useState<string>();
  const [loading, setLoading] = useState(true);

  const [prevTokenTypeAndDetails, setPrevTokenTypeAndDetails] = useState<TokenTypeAndDetails>();

  const location = useLocation();
  const {relaunchRegulation} = queryString.parse(location.search);


  useEffect(() => {
    (async () => {
      const _regulation = tokenConfigurationProcess?.regulation || relaunchRegulation as TokenConfigurationProcess['regulation'] || (await tokenConfigurationService.getRegulation()).data;
      setRegulation(_regulation);

      if(!tokenConfigurationProcess) {
        if(isRelaunch) {
          const _prevTokenTypeAndDetails = (await tokenConfigurationService.getPreviousTokenTypeAndDetails()).data as TokenTypeAndDetails;
          setPrevTokenTypeAndDetails(_prevTokenTypeAndDetails)
        }
        return setLoading(false);
      }
      const response = await tokenConfigurationService.getTokenTypeAndDetails(tokenConfigurationProcess.tokenTypeAndDetailsId);
      setTokenTypeAndDetails(response.data);
      setLoading(false);
      
    })();
  }, [tokenConfigurationProcess, relaunchRegulation]);


  const _saveTokenTypeAndDetails = async(formValue) => {
    const req = {...formValue};
    req.regulation = regulation;
    req.isRelaunch = isRelaunch;

    if(req.typeOfSecurity === 'equity backed securities' && (regulation === 'regulation-d' || regulation === 'regulation-s') && req.totalInvestors > 2000) {
      return message.error('Total Investors has to be less than 2000 for Equity Based Securities and Regulation D or S');
    }
    setSubmitting(true);
    await saveTokenTypeAndDetails(req);
    setSubmitting(false);
  }


  return (
    <>
    <br/><br/>
    <Row justify="center">
      <Col span={20}>

        {loading && 
          <div style={{textAlign:'center'}}>
            <Spin size='large'/>
          </div>
        }


        {!loading && 
          <>
            {
            (
              ['issuer token admin', 'issuer super admin'].includes(userInfo.role) && 
              tokenConfigurationProcess?.status !== 'verified'
            ) && 

              <>
                <Title level={4} style={{textAlign:'center'}}>Define your Token Type, Number of Investors, Lock Period for Investors</Title>
                <Form 
                {...formItemLayout} 
                labelAlign="left" 
                initialValues={tokenTypeAndDetails || (isRelaunch? {isDivisible: prevTokenTypeAndDetails?.isDivisible} : {})} 
                onFinish={_saveTokenTypeAndDetails}>

                  <Form.Item
                    noStyle
                    name='description'
                  >
                    <TextArea placeholder='Description message' />
                  </Form.Item>
                  <br/><br/>

                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => prevValues.typeOfSecurity !== currentValues.typeOfSecurity}
                    noStyle
                    >
                    {({ getFieldValue }) => {

                      return (
                        <Form.Item 
                          label={`Total Number of Investors`}
                          name="totalInvestors"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            }
                          ]}>
                          <InputNumber 
                            min={0} 
                            max={(getFieldValue('typeOfSecurity') === 'equity backed securities' && (regulation === 'regulation-d' || regulation === 'regulation-s'))? 2000 : undefined} 
                            placeholder='Total'/>
                        </Form.Item>
                      );
                    }}

                  </Form.Item>

                    
                  <Form.Item
                    label='Security Token must be'
                    name='isDivisible'
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <Radio.Group disabled={isRelaunch}>
                      <Radio key='isDivisible' value={true}>
                        Divisible
                      </Radio>
                      <Radio key='isNoDivisible' value={false}>
                        Indivisible
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item 
                    label={`Price per Token`}
                    name="pricePerToken"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <InputNumber min={0} placeholder='Cents'/>
                  </Form.Item>

                  {/* {regulation === 'regulation-d' && 
                    <Form.Item
                      label='Do you want to allow the transfer of tokens from US to Non US'
                      name='regDTransferableOutsideUSA'
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Radio.Group>
                        <Radio key="yes" value={true}>Yes</Radio>
                        <Radio key="no" value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>                
                  } */}

                  <Form.Item
                    label='Type of Security'
                    name='typeOfSecurity'
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>

                    <Radio.Group>
                      {typeOfSecurityOptions.map(option => (
                        <Fragment key={option.value}>
                          <Radio value={option.value} style={typeOfSecurityRadioStyle}>{option.name}</Radio>
                            {option.value === 'equity backed securities' && 
                              <Form.Item className='extra-form-item' style={{marginBottom:0}}
                                shouldUpdate={(prevValues, currentValues) => prevValues.typeOfSecurity !== currentValues.typeOfSecurity}
                                >
                                {({ getFieldValue }) => getEquitityBackSecurityItem(getFieldValue, option.value)}
                              </Form.Item>
                            }
                        </Fragment>
                      ))}
                    </Radio.Group>
                      
                  </Form.Item>
                  
                  <br/><br/>

                      
                  <br/><br/>
                  <Form.Item {...tailFormItemLayout}>
                    <Button size='large' type='primary' htmlType="submit" loading={submitting}>
                      SAVE MY TOKEN TYPE AND DETAILS
                    </Button>
                  </Form.Item>

                </Form>
              </>
            }



            {
            (
              !['issuer token admin', 'issuer super admin'].includes(userInfo.role) || 
              tokenConfigurationProcess?.status === 'verified'
            ) &&
              <>
              <Card>
              <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                  <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>&nbsp;&nbsp;Information</sub>
                  <br/>
                  <sub style={{color:'white',fontSize:'20px'}}>&nbsp;&nbsp;&nbsp;&nbsp;Token types and details </sub>
              </div>
              <br/>
              <Row>
                <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Total number of investor</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails?.totalInvestors}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Price per token</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails?.pricePerToken} cents</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Security token must be</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails?.isDivisible? 'Divisible' : 'Indivisible'}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Card style={{backgroundColor:'#F3F3F3'}}>
              <Row>
                <Col span={8}>
                {tokenConfigurationProcess?.regulation === 'regulation-d' &&
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Allow the transfer of tokens from US to Non US</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails?.pricePerToken? 'Yes' : 'No'}</sub>
                </div>
                }
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Type of security</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{sharedService.typeOfSecurityName(tokenTypeAndDetails?.typeOfSecurity as string)}</sub>
                </div>
              </Col>
              <Col span={8}>
              {tokenTypeAndDetails?.typeOfSecurity === 'equity backed securities' &&
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Stock for Equity Backed Securities</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{sharedService.commonStockName(tokenTypeAndDetails.stock)}</sub>
                </div>
              }
              </Col>
              </Row>
              <br/>
              <Row>
              <Col span={8}>
                {tokenTypeAndDetails?.stock?.startsWith('common') &&
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Voting Rights for Common Stock</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails.hasVotingRights? 'Yes' : 'No'}</sub>
                </div>
                }
              </Col>
                <Col span={8}>
                {tokenTypeAndDetails?.description &&
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Description</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{tokenTypeAndDetails.description}</sub>
                </div>
                }
              </Col>
              </Row>
              </Card>
              </Card>
              </>
            }
          </>
        }


      </Col>
    </Row>
    </>
  );

}