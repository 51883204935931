import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Result, message } from 'antd';
// import { PlusOutlined} from '@ant-design/icons';
import { AuthService } from '../../../Shared/Auth.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import TransactionModal from '../../../Shared/TransactionModal';
import { TokenConfigurationProcess, SymbolDetailsAndSTData } from '../../../Shared/interfaces';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SharedService } from '../../../Shared/Shared.service';
// import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import { IssuerComplianceAdminService } from '../../IssuerComplianceAdmin.service';

// import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../../../Shared/SecurityToken/Facets/WhitelistFacet/index';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet/index';


const {Title} = Typography;
// const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();
const mainFacet = new MainFacet();

const sharedService = new SharedService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const issuerComplianceAdminService = new IssuerComplianceAdminService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  // const history = useHistory();
  const [whitelistData, setWhitelistData] = useState<any[]>();
  // const [count, setCount] = useState(0);
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [issuerComplianceAdminWallet, setIssuerComplianceAdmin] = useState('');


  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      
      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      
      // const [_issuerComplianceAdminWallet, walletsResponse] = await Promise.all([
      //   securityTokenService.issuerComplianceAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   issuerComplianceAdminService.getPendingWhitelistedWallets(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      // ]);

      const [_issuerComplianceAdminWallet, walletsResponse] = await Promise.all([
        mainFacet.issuerComplianceAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        issuerComplianceAdminService.getPendingWhitelistedWallets(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);
      
      const _whitelistData: any[] = walletsResponse.data;

    for(let index = 0; index < _whitelistData.length; index++) {
      const whitelistedWalletData = _whitelistData[index];

      whitelistedWalletData['key'] = index;
      whitelistedWalletData['wallet'] = whitelistedWalletData.user.searchAbleWallet;
      whitelistedWalletData['isAffiliate'] = whitelistedWalletData.user.isAffiliate;
      whitelistedWalletData['isFromUSA'] = whitelistedWalletData.isFromUSA;
      whitelistedWalletData['firstName'] = whitelistedWalletData.user.firstName;
      whitelistedWalletData['email'] = whitelistedWalletData.user.email;
      whitelistedWalletData['isAccredetedInvestor'] = !!whitelistedWalletData.isAccredetedInvestor;
    }
      
      setIssuerComplianceAdmin(_issuerComplianceAdminWallet);
      setWhitelistData(_whitelistData);

      setLoading(false);

    })();
  }, [userInfo]);


  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string) => (
        <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
          {sharedService.minifyAddress(value)}
        </a>
      )
    },
    {
      title: 'Affiliate',
      dataIndex: 'isAffiliate',
      render: (value: boolean) => value? 'YES' : 'No'
      // render: (value: boolean, record) => {
      //   return (
      //     // <div style={{marginLeft:'30%'}}>
      //       <Checkbox checked={value} onChange={e => editWhitelistTable(e.target.checked, record, 'isAffiliate')} />
      //     // </div>
      //   );
      // }
    },
    {
      title: 'USA Person',
      dataIndex: 'isFromUSA',
      render: (value: boolean) => value? 'YES' : 'NO'
    },
    {
      title: 'Accredeted Investor',
      dataIndex: 'isAccredetedInvestor',
      render: (value: boolean) => value? 'YES' : 'NO'
    },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (text, record) =>
    //   whitelistData.length > 1 ? (
    //     <Popconfirm
    //       title='Sure to delete?'
    //       onConfirm={() => deleteWalletFromTable(record.key)}
    //     >
    //       <Button type='link'>Delete</Button>
    //     </Popconfirm>
    //   ) : null
    // }
  ];


  // const editWhitelistTable = (value: any, record, dataIndex: 'wallet' | 'isAffiliate' | 'isFromUSA') => {
  //   // if(!value && value !== 0 && value !== false) return;
  //   const newData = [...whitelistData];
  //   const index = newData.findIndex(offer => offer.key === record.key);
  //   newData[index] = {...newData[index], [dataIndex]: value};
  //   setWhitelistData(newData);
  // };

  // const addWalletToWhitelistTable = () => {
  //   setCount(prev => prev + 1);
  //   setWhitelistData(prev => [...prev, {key: count, wallet: '', isAffiliate: false, isFromUSA: false}]);
  // };

  // const deleteWalletFromTable = (key: number) => {
  //   setWhitelistData(prev => [...prev.filter(item => item.key !== key)]);
  // };

  const addWhitelistedWalletMulti = async() => {
    if(!whitelistData?.length) return;

    if(whitelistData.find(whitelistMember => !whitelistMember.wallet)) return message.error('Enter all the wallets');

    setIsModalVisible(true);
    setTransactions([{details: 'Adding to Whitelist', submitting: true}]);

    console.log(whitelistData);

    const _from: string[] = [];
    const _isAffiliate: boolean[] = [];
    const _isFromUSA: boolean[] = [];
    const _isAccredetedInvestor: boolean[] = [];

    whitelistData.forEach(whitelistMember => {
      _from.push(whitelistMember.wallet);
      _isAffiliate.push(whitelistMember.isAffiliate);
      _isFromUSA.push(whitelistMember.isFromUSA);
      _isAccredetedInvestor.push(whitelistMember.isAccredetedInvestor);
    });


    try {
      // const receipt = await securityTokenService.addWhitelistedWalletMulti(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   _from,
      //   _isAffiliate,
      //   _isFromUSA,
      //   _isAccredetedInvestor
      // );

      const receipt = await whitelistFacet.addWhitelistedWalletMulti(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        _from,
        _isAffiliate,
        _isFromUSA,
        _isAccredetedInvestor
      );

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
       return current;
     });

      if(receipt?.status) setWhitelistData([]);

      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });


  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Pending Whitelisted Wallets</Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                  />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== issuerComplianceAdminWallet.toLowerCase() && 
                <>
                  <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                  <Result
                    status="error"
                    title = {
                      <p>
                        Select the wallet {' '}
                        <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${issuerComplianceAdminWallet}`}>
                          {sharedService.minifyAddress(issuerComplianceAdminWallet.toLowerCase())}
                        </a> 
                        {' '} in order to add Wallets to the Whitelist
                      </p>
                    }
                  />
                </>
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === issuerComplianceAdminWallet.toLowerCase() && 
                <>
                  <Table
                    scroll={{x: true}}
                    columns={columns}
                    dataSource={whitelistData}
                    pagination={false}
                    // footer={()=><Button onClick={addWalletToWhitelistTable}><PlusOutlined/> Click here to add wallet</Button>}
                    />
                  <div style={{textAlign:'right'}}>
                    <Button size='large' type='primary' loading={false} onClick={addWhitelistedWalletMulti}>
                      ADD TO WHITELIST
                    </Button>
                  </div>
                </>
              }
            </Card>
          }
        </Col>
      </Row>

      <TransactionModal
        title = 'Pending Whitelisted Wallets'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
}