import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Spin,
  Descriptions,
  Form,
  Radio,
  notification
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { User } from '../../../Shared/interfaces';
import { AuthService } from '../../../Shared/Auth.service';
import { SharedService } from '../../../Shared/Shared.service';
import { AdminService } from '../Admin.service';
import moment from 'moment';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
// import { SharedService } from '../../../Shared/Shared.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import OwnershipFacet from '../../../Shared/SecurityToken/Facets/OwnershipFacet/index';

const { Title } = Typography;
const adminService = new AdminService();
const sharedService = new SharedService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const ownershipFacet = new OwnershipFacet();

const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const useUserContext = () => new AuthService().useUserContext();
// const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default () => {
  const { userInfo } = useUserContext();
  // const {selectedWallet, networkId} = useSelectedWalletContext();
  const history = useHistory();
  const [filter, setFilter] = useState({ limit: 10, searchPage: 1 });
  const [teamMembers, setTeamMembers] = useState<User[] | null>();
  const [loadingMoreTeamMembers, setLoadingMoreTeamMembers] = useState<boolean>();
  const [canLoadMoreTeamMembers, setCanLoadMoreTeamMembers] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [form] = Form.useForm();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const confirmAuthorization = async (record: any) => {
    const tokenData = await adminService.getTokenSymbol({ companyId: userInfo?.company?.id });
    const symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(tokenData.data.tokenSymbol)
    const tokenAddress = symbolDetailsAndSTData.securityTokenData.contractAddress;
    try {
        await ownershipFacet.transferOwnershipIssuerTokenAdmin(tokenAddress, selectedWallet, record.wallet);
        await adminService.confirmAuthorization({_id : record?._id,companyId:userInfo?.company?.id});      
      } catch (e) {
      notification.error({ message: 'Error', description: e })
    }
    notification.success({ message: 'Success', description: 'Ownership Transferred' });
    history.push(`/issuer-super-admin/admin/admin-roles`);
  }

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      let [_teamMembers] = (await Promise.all([
        adminService.getCompanyMembers(filter.limit, 1)
      ])).map(res => res.data)
      _teamMembers = _teamMembers.filter(teamMember => teamMember['authorizer'] === true)
      setTeamMembers(_teamMembers);
      setFilter((prev: any) => ({ ...prev, searchPage: prev.searchPage + 1 }));
      setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
    })();
  }, [userInfo, filter.limit]);

  const loadMoreTeamMembers = async () => {
    if (loadingMoreTeamMembers || !teamMembers || !userInfo) return;
    setLoadingMoreTeamMembers(true);
    const response = await adminService.getCompanyMembers(filter.limit, filter.searchPage);
    const _teamMembers: User[] = response.data;
    setTeamMembers(prev => [...prev as any, ..._teamMembers]);
    setLoadingMoreTeamMembers(false);
    setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
  }

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'firstName'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ADMIN ROLES',
      dataIndex: 'roles',
      render: (roles: string[], record: User) => (
        sharedService.adminRolesOptions.find(opt => roles.includes(opt.value)) ?
          sharedService.adminRolesOptions.filter(opt => roles.includes(opt.value)).map(opt => opt.name).join('| ')
          :
          'None'
      )
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'displayableTeamDepartment',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'TEAM ROLE',
      dataIndex: 'displayableTeamRole',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'AFFILIATE',
      dataIndex: 'isAffiliate',
      render: (value: User['isAffiliate']) => (value ? 'Affiliate' : 'Not Affiliate')
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: User['creationTS']) => moment(value).format('LLL'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (status: any, record: User) => {
        return (
          <>
            { 
            !record['authorized'] ? 
            <>
              { !record['inAssignmentReview'] && 'Not Authorized'} <br />
              { record['inAssignmentReview'] && !record['isReviewedByIssuerCompanyOfficer'] && 'In Review BY ICO'} <br />
              { record['isReviewedByIssuerCompanyOfficer'] && (record['isRejectedByIssuerCompanyOfficer'] ? 'Rejected By ICO' : 'Accepted By ICO')} <br />
              { record['isReviewedByIssuerCompanyOfficer'] && !record['isRejectedByIssuerCompanyOfficer'] && !record['isReviewedByIssuerCounsel'] && 'In Review By IC'} <br />
              { record['isReviewedByIssuerCounsel'] && (record['isRejectedByIssuerCounsel'] ? 'Rejected By IC' : 'Accepted By IC')} <br />
              { record['isReviewedByIssuerCounsel'] && !record['isRejectedByIssuerCounsel'] && !record['isReviewedByDirector'] && 'In Review By Director'}
            </> : <>
                {
                  record['confirmedByISA'] && <b>Authrorized</b>
                }
                {
                  !record['confirmedByISA'] && <Button onClick={() => confirmAuthorization(record)} type="primary" > Confirm </Button>
                }
              </>
            }
          </>
        );
      }
    },

  ];

  const selectTeamMembersFromTable = (selectedRows: User[]) => {
    const newData = sharedService.clone(teamMembers);
    newData?.forEach(member => member['selected'] = selectedRows.map(row => row._id).includes(member._id));
    setTeamMembers(newData);
  }

  const removeAuthorizers = async () => {
    if (!teamMembers?.find(member => member['selected'])) return;
    setRemoving(true);
    const response = await adminService.removeAuthorizers(
      {
        teamMembersIds: teamMembers.filter((member => member['selected'])).map(member => member._id)
      });
    if (response.success) {
      notification.success({ message: 'Success', description: 'Authorizer(s) removed successfully' });
      history.push(`/issuer-super-admin/admin/admin-roles`);
    } else {
      notification.error({ message: 'Error', description: response.error.message });
    }
    setRemoving(false);
  }

  const assignAuthorizers = async () => {
    if (!teamMembers?.find(member => member['selected'])) return;
    if (teamMembers.find((member: any) => member['selected'] && member.inAssignmentReview === true)) return notification.error({ message: 'Error', description: 'In Review' });
    setSubmitting(true);
      await adminService.assignAuthorizers(
      {
        teamMembersIds: teamMembers.filter((member => member['selected'])).map(member => member._id)
      });
    setSubmitting(false);
    notification.success({ message: 'Success', description: 'Authorizer(s) assigned successfully' });
    history.push(`/issuer-super-admin/admin/admin-roles`);
  }

  return (
    <>
      <br /><br />
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Title level={1} style={{ textAlign: 'center' }}>Assign Authorizers</Title>
            <Table
              columns={columns}
              dataSource={(teamMembers as User[])}
              loading={!teamMembers}
              rowKey='_id'
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: teamMembers?.filter((member => member['selected'])).map(member => member._id),
                onChange: (selectedRowKeys, selectedRows) => {
                  selectTeamMembersFromTable(selectedRows);
                },
              }}
              footer={() =>
                <div style={{ textAlign: 'center' }}>
                  {teamMembers && canLoadMoreTeamMembers &&
                    <Button onClick={loadMoreTeamMembers}><PlusOutlined />Load more</Button>
                  }
                </div>
              }
            />
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: '5px' }} htmlType='button' size='large' onClick={() => history.push(`/issuer-super-admin/admin/admin-roles`)}>
                BACK
              </Button>
              <Button htmlType='submit' style={{ marginRight: '5px' }} size='large' type='primary' danger onClick={removeAuthorizers} loading={removing}>
                REMOVE
              </Button>
              <Button htmlType='submit' size='large' type='primary' onClick={assignAuthorizers} loading={submitting}>
                ASSIGN
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};