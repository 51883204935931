import React from 'react';

import { useParams } from 'react-router-dom';
import IssuerSuperAdmin from './IssuerSuperAdmin';
import TeamMember from './TeamMember';
import IssuerCounsel from './IssuerCounsel';
import IssuerTokenAdmin from './IssuerTokenAdmin';

import IssuerRegistration from './IssuerRegistration'
import InvestorRegistration from './InvestorRegistration'
import IssuerCompanyOfficer from './IssuerCompanyOfficer';
import PlatformAdmin from './PlatformAdmin';



export default () => {
  const {role} = useParams<{role: string}>(); //ROLE URL. INCLUDES A DASH ("-") AS SEPARATOR
  const {email}=useParams<{email:string}>();
  return (
    <>
      {email && <IssuerRegistration/>}
      {role === 'issuer' && <IssuerSuperAdmin/>}
      {(role === 'team-member') && <TeamMember/>}
      {(role === 'issuer-token-admin') && <IssuerTokenAdmin/>}
      {(role === 'issuer-company-officer') && <IssuerCompanyOfficer/>}
      {(role === 'issuer-counsel') && <IssuerCounsel/>}
      {role==='investor' && <InvestorRegistration/>}
      {role==='platform-admin' && <PlatformAdmin/>}
    </>
  );
}