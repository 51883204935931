import { MetamaskService } from '../../../Metamask.service';

export default class OwnershipFacet {
  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async transferOwnershipIssuerTokenAdmin(tokenAddress: string, _from: any, newOwner: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.transferOwnershipIssuerTokenAdmin(newOwner).send({ from: _from });
  }

};

// Not Implemented
// function owner() external override view returns (address owner_) {
// }
