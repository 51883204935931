import { Button, Card, Col, Form, Input, Modal, notification, Result, Row, Spin, Table, Typography } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { RecoveryRequest, RegLaunched, SymbolDetailsAndSTData, User } from '../../Shared/interfaces';
import { MetamaskService } from '../../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SharedService } from '../../Shared/Shared.service';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { RecoveryRequestsService } from '../RecoveryRequests.service';
import moment from 'moment';
import TransactionModal from '../../Shared/TransactionModal';


import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import TransferFacet from '../../Shared/SecurityToken/Facets/TransferFacet/index';
import WhitelistFacet from '../../Shared/SecurityToken/Facets/WhitelistFacet/index';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import GeneralTransferMangerFacet from '../../Shared/SecurityToken/Facets/GeneralTransferManagerFacet/index';

const {Title} = Typography;
const recoveryRequestsService = new RecoveryRequestsService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();

const securityTokenService = new SecurityTokenService();
const transferFacet = new TransferFacet();
const whitelistFacet = new WhitelistFacet(); 
const mainFacet = new MainFacet();
const generalTransferMangerFacet = new GeneralTransferMangerFacet();

export default function RecoveryRequestsDetails() {
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [recoveryProcessReq, setRecoveryProcessReq] = useState<RecoveryRequest>();
  const {recoveryProcessReqId} = useParams<{recoveryProcessReqId: string}>();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>([]);

  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [closingTransactionModal, setClosingTransactionModal] = useState(false);

  const [isTokenTransferVisible, setIsTokenTransferVisible] = useState<boolean>();
  const [selectedRegToTransfer, setSelectedRegToTransfer] = useState<RegLaunched>();
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();
  const [transactionModalTitle, setTransactionModalTitle] = useState('');

  const tokenSymbol = symbolDetailsAndSTData?.securityTokenData.symbol;
  const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);

  const history = useHistory();


  useEffect(() => {
    (async () => {

      let _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);


      // let [__regulationsLaunched, _recoveryProcessReqRes] = await Promise.all([
      //   securityTokenService.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   recoveryRequestsService.getRecoveryProcessRequestById({recoveryProcessReqId}),
      // ]);

      let [__regulationsLaunched, _recoveryProcessReqRes] = await Promise.all([
       mainFacet.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        recoveryRequestsService.getRecoveryProcessRequestById({recoveryProcessReqId}),
      ]);

      const _recoveryProcessReq = _recoveryProcessReqRes.data as RecoveryRequest;

      let _regulationsLaunched = __regulationsLaunched.map(launched => ({
        regulation: launched.regulation,
        dateLaunched: launched.dateLaunched,
        regDTransferableOutsideUSA: launched.regDTransferableOutsideUSA,
        creationTS: launched.creationTS,
        index: launched.index,
        legendRemoved: launched.legendRemoved,
        typeOfSecurity: launched.typeOfSecurity
      }));

      for(let _regulationLaunched of _regulationsLaunched) {
        // let [oldWalletBalance, newWalletBalance] = await Promise.all([
        //   securityTokenService.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.oldWallet, +_regulationLaunched.index),
        //   securityTokenService.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.newWallet, +_regulationLaunched.index),
        // ]);
        let [oldWalletBalance, newWalletBalance] = await Promise.all([
          mainFacet.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.oldWallet, +_regulationLaunched.index),
          mainFacet.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.newWallet, +_regulationLaunched.index),
        ]);
        _regulationLaunched['oldWalletBalance'] = oldWalletBalance;
        _regulationLaunched['newWalletBalance'] = newWalletBalance;
      }


      setRecoveryProcessReq(_recoveryProcessReq);
      setRegulationsLaunched(_regulationsLaunched);
  
      setLoading(false);
  
    })();
  },[recoveryProcessReqId]);



  const displayTokenAmount = (amount) => {
    return new BigNumber(amount).times(new BigNumber(10).pow(-(symbolDetailsAndSTData?.securityTokenData.decimals as string))).toFixed();
  }



  const regBalanceColumns = [
    {
      title: 'Regulation', 
      dataIndex: 'regulationName'
    },
    {
      title: 'Launched on',
      dataIndex: 'dateLaunched',
      render: (value: string) => moment(+(value)*1000).format('LLL')
    },
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      render: (value: string) => `${sharedService.typeOfSecurityName(value)}`
    },
    {
      title: 'Amount', 
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount(value)} ${tokenSymbol}`
    },
    {
      title: 'Action', 
      render: (value, row: RegLaunched)=> (
        <>
          {row['isNewWallet'] && 
            <Button onClick={()=>openIssueRegModal(row)} type='primary'>ISSUE NEW TOKENS AND WHITELIST</Button> 
          }
        </> 
      )
    }
  ];

  
  const openIssueRegModal = async(reg: RegLaunched) => {
    setSelectedRegToTransfer(reg);
    setIsTokenTransferVisible(true);
  }


  
  const burnAllRegs = async() => {
    setTransactionModalTitle('Removing Wallet');

    setIsModalVisible(true);

    setTransactions([
      { details: 'Removing Wallet from Whitelist', submitting: true },
      { details: 'Burning tokens' }
    ]);

    try {

      // const receipt0 = await securityTokenService.removeWhitelistedWallet(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   recoveryProcessReq?.oldWallet as string,
      // );

      const receipt0 = await whitelistFacet.removeWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        recoveryProcessReq?.oldWallet as string,
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if(!receipt0.status) return;

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });

      // const receipt1 = await securityTokenService.burnAllRegs(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   recoveryProcessReq?.oldWallet as string,
      // );

      const receipt1 = await transferFacet.burnAllRegs(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        recoveryProcessReq?.oldWallet as string,
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }



  const issueReg = async(formValue, _selectedRegToTransfer: RegLaunched) => {
    console.log(formValue);

    setTransactionModalTitle('Adding Wallet');

    const _to = recoveryProcessReq?.newWallet as string;
    const _value = new BigNumber(formValue.amount).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toFixed();

    setLoadingCanTransfer(true);

    // const canTransfer = await securityTokenService.canTransfer(
    //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
    //   '0x0000000000000000000000000000000000000000',
    //   _to,
    //   _value,
    //   +(selectedRegToTransfer?.index as string),
    //   false
    // );

    const canTransfer = await generalTransferMangerFacet.canTransfer(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      '0x0000000000000000000000000000000000000000',
      _to,
      _value,
      +(selectedRegToTransfer?.index as string),
      false
    );



    if(!canTransfer[0] && canTransfer[1] !== 'Receiver Wallet not whitelisted') {
      notification.error({
        message: 'Token Transfer error',
        description: canTransfer[1],
      });
      return;
    }

    // console.log(canTransfer);
    setLoadingCanTransfer(false);

    setIsModalVisible(true);
    setIsTokenTransferVisible(false);


    setTransactions([
      { details: 'Whitelisting Wallet', submitting: true },
      { details: 'Issuing tokens' }
    ]);

    try {

      // const receipt0 = await securityTokenService.addWhitelistedWallet(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   _to,
      //   !!(recoveryProcessReq?.['user'] as User).isAffiliate,
      //   !!(recoveryProcessReq?.['user'] as User)['isFromUSA'],
      //   !!(recoveryProcessReq?.['user'] as User)['isAccredetedInvestor'],
      // );

      const receipt0 = await whitelistFacet.addWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        _to,
        !!(recoveryProcessReq?.['user'] as User).isAffiliate,
        !!(recoveryProcessReq?.['user'] as User)['isFromUSA'],
        !!(recoveryProcessReq?.['user'] as User)['isAccredetedInvestor'],
      );


      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if(!receipt0.status) return;

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });

      // const receipt1 = await securityTokenService.issueReg(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   _to,
      //   _value,
      //   +(selectedRegToTransfer?.index as string),
      // );

      const receipt1 = await transferFacet.issueReg(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        _to,
        _value,
        +(selectedRegToTransfer?.index as string),
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }



  const closeModal = async() => {
    setClosingTransactionModal(true);

    let _regulationsLaunched = regulationsLaunched.map(launched => ({
      regulation: launched.regulation,
      dateLaunched: launched.dateLaunched,
      regDTransferableOutsideUSA: launched.regDTransferableOutsideUSA,
      creationTS: launched.creationTS,
      index: launched.index,
      legendRemoved: launched.legendRemoved,
      typeOfSecurity: launched.typeOfSecurity
    }));

    for(let _regulationLaunched of _regulationsLaunched) {

      // let [oldWalletBalance, newWalletBalance] = await Promise.all([
      //   securityTokenService.regBalanceOf(
      //     symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //     recoveryProcessReq?.oldWallet as string, 
      //     +_regulationLaunched.index
      //   ),
      //   securityTokenService.regBalanceOf(
      //     symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //     recoveryProcessReq?.newWallet as string, 
      //     +_regulationLaunched.index
      //   ),
      // ]);

      let [oldWalletBalance, newWalletBalance] = await Promise.all([
        mainFacet.regBalanceOf(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
          recoveryProcessReq?.oldWallet as string, 
          +_regulationLaunched.index
        ),
        mainFacet.regBalanceOf(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
          recoveryProcessReq?.newWallet as string, 
          +_regulationLaunched.index
        ),
      ]);

      _regulationLaunched['oldWalletBalance'] = oldWalletBalance;
      _regulationLaunched['newWalletBalance'] = newWalletBalance;
    }


    setRegulationsLaunched(_regulationsLaunched);

    setClosingTransactionModal(false);
    setIsModalVisible(false);
  }


  const approveRecoveryProcessRequest = async() => {
    setSubmitting(true);

    const response = await recoveryRequestsService.approveRecoveryProcessRequest({recoveryProcessReqId});

    if (response.success) {
      notification.success({message: 'SUCCESS', description: 'Request approved successfully'});
      history.push('/recovery-requests');
  
    } else {
      console.error(response.error);
      notification.error({message: 'ERROR', description: response.error.message});
    }

    setSubmitting(false);

  }

 


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={2} style={{textAlign:'center'}}>Securities Recovery Process</Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }
              
              {symbolDetailsAndSTData?.symbolDetails.isDeployed && 
                <>
                  {selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                    <>
                      <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                      <Result
                        status="error"
                        title = {
                          <p>
                            Select the wallet {' '}
                            <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                              {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                            </a> 
                            {' '} in order to proceed
                          </p>
                        }
                      />
                    </>
                   }

                  {selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                    <>
                    <div>

                      <div style={{
                        backgroundColor: 'beige', 
                        width: '70%', 
                        margin: 'auto', 
                        textAlign: 'center', 
                        fontWeight: 'bold', 
                        borderStyle: 'groove'
                      }}>
                         {recoveryProcessReq?.reason}
                      </div>
                      <br/>

                      <div>
                        <Title style={{textAlign:'center'}} level={3}>Old Wallet</Title>

                        <Title style={{textAlign:'center', marginTop: '0px'}} level={3}>
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${recoveryProcessReq?.oldWallet}`}>
                            {/* {sharedService.minifyAddress(recoveryProcessReq?.oldWallet.toLowerCase() as string)} */}
                            {recoveryProcessReq?.oldWallet.toLowerCase() as string}
                          </a> 
                        </Title>
                      </div>

                      <Title style={{textAlign:'center'}} level={3}>Balance</Title>

                      <Table
                        columns={regBalanceColumns}
                        rowKey='regulation'
                        pagination={false}
                        dataSource={regulationsLaunched.map(regLaunched => ({
                          regulation: regLaunched.regulation,
                          dateLaunched: regLaunched.dateLaunched,
                          regDTransferableOutsideUSA: regLaunched.regDTransferableOutsideUSA,
                          creationTS: regLaunched.creationTS,
                          index: regLaunched.index,
                          legendRemoved: regLaunched.legendRemoved,
                          regulationName: sharedService.regOptions.find(opt => opt.shortValue === regLaunched.regulation)?.name,
                          typeOfSecurity: regLaunched.typeOfSecurity,
                          amount: regLaunched['oldWalletBalance'],
                          isNewWallet: false
                        }))}
                      />
                      <div style={{textAlign:'right', marginTop:'20px'}}>
                        <Button style={{marginRight:'5px'}} danger size='large' onClick={burnAllRegs}>
                          BURN ALL AND REMOVE FROM WHITELIST
                        </Button>
                      </div>
                    </div>

                    <br/>
                    <hr/>
                    <br/>

                    <div>

                      <div>
                        <Title style={{textAlign:'center'}} level={3}>New Wallet</Title>

                        <Title style={{textAlign:'center', marginTop: '0px'}} level={3}>
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${recoveryProcessReq?.newWallet}`}>
                            {/* {sharedService.minifyAddress(recoveryProcessReq?.newWallet.toLowerCase() as string)} */}
                            {recoveryProcessReq?.newWallet.toLowerCase() as string}
                          </a> 
                        </Title>
                      </div>

                      <Title style={{textAlign:'center'}} level={3}>Balance</Title>

                      <Table
                        columns={regBalanceColumns}
                        rowKey='regulation'
                        pagination={false}
                        dataSource={regulationsLaunched.map(regLaunched => ({
                          regulation: regLaunched.regulation,
                          dateLaunched: regLaunched.dateLaunched,
                          regDTransferableOutsideUSA: regLaunched.regDTransferableOutsideUSA,
                          creationTS: regLaunched.creationTS,
                          index: regLaunched.index,
                          legendRemoved: regLaunched.legendRemoved,
                          regulationName: sharedService.regOptions.find(opt => opt.shortValue === regLaunched.regulation)?.name,
                          typeOfSecurity: regLaunched.typeOfSecurity,
                          amount: regLaunched['newWalletBalance'],
                          isNewWallet: true
                        }))}
                      />
                    </div>
                      
                    <br/><br/>
                    <div style={{textAlign:'center', marginTop:'20px'}}>
                      <Button style={{marginRight:'5px', width: '40%'}} loading={submitting} type="primary" size='large' onClick={approveRecoveryProcessRequest}>
                        FINISH
                      </Button>
                    </div>
                    <br/>

                    </>
                  }
                </>
              }


            </Card>
            
          }
        </Col>
      </Row>

      <Modal
        title={sharedService.regOptions.find(opt => opt.shortValue === selectedRegToTransfer?.regulation)?.name}
        okText='ISSUE'
        cancelText='Cancel'
        visible={isTokenTransferVisible}
        onOk={()=>form.submit()}
        okButtonProps={{loading: loadingCanTransfer}}
        onCancel={()=>{setIsTokenTransferVisible(false); form.resetFields()}}
      >
        <Form form={form} autoComplete={'off'} initialValues={{to: recoveryProcessReq?.newWallet}} onFinish={(formValue) => issueReg(formValue, selectedRegToTransfer as RegLaunched)}>

          <Form.Item wrapperCol={{span:18, offset:3}} 
            name='to'
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true
              },
              {
                validator: (rule, value) => {
                  if(value && !sharedService.isEthereumAddress(value)) return Promise.reject("Enter a valid Address");
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input disabled placeholder='Receiver'/>
          </Form.Item>

          <Form.Item wrapperCol={{span:18, offset:3}}
            name='amount'
            normalize = {(value: any, prevValue: any, allValues) => {
              // console.log(value, prevValue, allValues);
              if(new BigNumber(value).isGreaterThan(0)) return value;
              if(!value) return '';
              return prevValue || '';
            }}
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true,
              }
            ]}
          >
            <Input placeholder='Amount'/>
          </Form.Item>

          <br/>

          </Form>

      </Modal>


      <TransactionModal
        title = {transactionModalTitle}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closingModal={closingTransactionModal}
        closeModal = {() => closeModal()}
      />
    </>
  );
}