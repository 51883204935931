import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthService } from '../../Shared/Auth.service';
import { useHistory, useLocation } from 'react-router-dom';
import { SharedService } from '../../Shared/Shared.service';

import './SideNav.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const sharedService = new SharedService();


const menuHeader = sharedService.menuHeader;


const appPages = [
  {
    title: 'Team Member KYC Requests',
    url: '/issuer-compliance-admin/team-member-kyc-requests',
    icon: <UserOutlined/>,
    roles: ['issuer compliance admin']
  },
  {
    title: 'Investor KYC Requests',
    url: '/issuer-compliance-admin/kyc-verification-request',
    icon: <UserOutlined/>,
    roles: ['issuer compliance admin']
  },
  {
    title: 'Investor KYC Requests',
    url: '/platform-compliance-admin/kyc-verification-request',
    icon: <UserOutlined/>,
    roles: ['platform compliance admin']
  },
  {
    title: 'Team Member KYC Requests',
    url: '/platform-compliance-admin/team-member-kyc-requests',
    icon: <UserOutlined/>,
    roles: ['platform compliance admin']
  },
  {
    title: 'Due diligence for issuer',
    key: 'Due Diligence',
    icon: <UserOutlined/>,
    roles: ['platform compliance admin'],
    children:[
      {
        title: 'Add',
        url: '/platform-compliance-admin/add-due-diligence-for-issuer',
        roles: ['platform compliance admin']
      },
      {
        title: 'Review answers',
        url: '/platform-compliance-admin/review-due-diligence',
        roles: ['platform compliance admin']
      }
    ]
  },
  {
    title: 'Token Dashboard',
    url: '/token-dashboard',
    icon: <UserOutlined/>,
    roles: ['issuer super admin','issuer token admin', 'employee', 'contractor_consultant_Advisor']
  },
  {
    title: 'View All Issuer Super Admins',
    url: '/platform-issuer-admin/issuer-super-admins',
    icon: <UserOutlined/>,
    roles: ['platform issuer admin']
  },
  {
    title: 'Issuer Approval Requests',
    key: 'issuer-approval-requests',
    icon: <UserOutlined/>,
    roles: ['platform issuer admin'],
    children: [
      {
        title: 'Issuer Registration Requests',
        url: '/platform-issuer-admin/issuer-reg-reqs',
        roles: ['platform issuer admin']
      },
      {
        title: 'Issuer Basic Info Verification Requests',
        url: '/platform-issuer-admin/issuer-basic-info-verif-reqs',
        roles: ['platform issuer admin']
      },
    ],
  },
  // {
  //   title: 'Edit Basic Info of Issuer Super Admin',
  //   url: '/1',
  //   icon: <UserOutlined/>,
  //   roles: ['platform issuer admin']
  // },
  {
    title: 'Investor Registation Requests',
    url: '/platform-issuer-admin/investor-registration-requests',
    icon: <UserOutlined/>,
    roles: ['issuer super admin']
  },
  // // ,
  {
    title:'Information Question for issuer',
    key:'information-ques-for-issuer',
    icon: <UserOutlined/>,
    roles:['platform issuer admin'],
    children:[
      { 
        title:'Create Information Questions',
        url:'/platform-issuer-admin/create-information-questions',
        roles:['platform issuer admin']
      },
      {        
        title:'View & Edit Questions',
        url:'/platform-issuer-admin/view-and-edit-questions',
        roles:['platform issuer admin']
      }
    ]
  },
  {
    title: 'Basic Information',
    url: '/issuer-super-admin/basic-info-verif',
    icon: <UserOutlined/>,
    roles: ['issuer super admin']
  },
  // {
  //   title: 'Dashboard',
  //   url: '/issuer-super-admin/dashboard',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin']
  // },
  {
    title: 'Create Offering',
    url: '/issuer-super-admin/create-offering',
    icon: <UserOutlined/>,
    roles: ['issuer super admin']
  },
  {
    title: 'Admin',
    key: 'admin',
    icon: <UserOutlined/>,
    roles: ['issuer super admin', 'issuer token admin'],
    children: [
      {
        title: 'Admin Roles',
        url: '/issuer-super-admin/admin/admin-roles',
        roles: ['issuer super admin'],
      },
      {
        title: 'Role Managment',
        url: '/issuer-super-admin/admin/role-managment',
        roles: ['issuer super admin'],
      },
      {
        title: 'Team Managment',
        url: '/issuer-super-admin/admin/team-managment',
        roles: ['issuer super admin', 'issuer token admin'],
      },
    ]
  },
  /*{
    title: 'Bank Information',
    url: '/issuer-super-admin/bank-info',
    icon: <UserOutlined/>,
    roles: ['issuer super admin']
  },*/
  {
    title: 'Token Configuration',
    url: '/token-configurations',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],

  },
  {
    title: 'Due Diligence',
    url: '/issuer-super-admin/answer-due-diligence',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },
  // {
  //   title: 'Token Configuration',
  //   key: 'token-configuration',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer token admin'],
  //   children: [
  //     {
  //       title: 'Token Type & Details',
  //       url: '/token-configuration/type-and-details',
  //       // url: '/issuer-super-admin/token-configuration/type-and-details',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Rule 144',
  //       url: '/token-configuration/rule-144',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Phase',
  //       url: '/token-configuration/phase',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Create Token',
  //       url: '/token-configuration/create',
  //       roles: ['issuer token admin'],
  //     },
  //   ]
  // },
  // {
  //   title: 'Employees and Affiliates',
  //   url: '/issuer-super-admin/team',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin'],
  // },
  {
    title: 'Whitelist',
    key: 'whitelist',
    icon: <UserOutlined/>,
    roles: ['issuer compliance admin'],
    children: [
      {
        title: 'Pending Whitelisted Wallets',
        url: '/issuer-compliance-admin/whitelist/pending-whitelisted-wallets',
        roles: ['issuer compliance admin'],
      },
      {
        title: 'Whitelisted Wallets',
        url: '/issuer-compliance-admin/whitelist/whitelisted-wallets',
        roles: ['issuer compliance admin'],
      },
    ]
  },
  {
    title: 'Team Token Managment',
    key: 'token-managment',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],
    children: [
      {
        title: 'Create Vesting Schedule',
        url: '/issuer-token-admin/token-managment/create-vesting-schedule',
        roles: ['issuer token admin'],
      },
      {
        title: 'Apply Vesting to Team',
        url: '/issuer-token-admin/token-managment/apply-vesting-schedule',
        roles: ['issuer token admin'],
      },
      {
        title: 'Token Security',
        url: '/issuer-token-admin/token-managment/token-security',
        roles: ['issuer token admin'],
      },
    ]
  },
  {
    title: 'Token Features',
    key: 'token-features',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],
    children: [
      {
        title: 'Dividend',
        url: '/issuer-token-admin/token-features/dividend',
        roles: ['issuer token admin'],
      },
      {
        title: 'Voting',
        url: '/issuer-token-admin/token-features/voting',
        roles: ['issuer token admin'],
      },
      {
        title: 'Cap Table',
        url: '/issuer-token-admin/token-features/cap-table',
        roles: ['issuer token admin'],
      }
    ]
  },{
    title: 'Transfer Restrictions',
    key: 'trasnferRestrictions',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],
    children: [
      {
        title: 'Select Countries',
        url: '/issuer-token-admin/transfer-restriction/select-countries',
        roles: ['issuer token admin'],
      },
    ]
  },

  /*{
    title: 'Choose your Providers',
    url: '/issuer-super-admin/choose-providers',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },*/
  {
    title: 'Issue tokens',
    url: '/issuer-token-admin/issue-tokens',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],
  },
  // {
  //   title: 'Create Issuer Role',
  //   url: '/issuer-super-admin/create-issuer-role',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin'],
  // },
  // {
  //   title: 'Remove Legend',
  //   url: '/issuer-token-admin/remove-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer token admin'],
  // },
  // {
  //   title: 'Vesting Schedule',
  //   url: '/employee/vesting-schedule',
  //   icon: <UserOutlined/>,
  //   roles: ['employee'],
  // },
  {
    title: 'Vesting Schedule',
    url: '/team-member/vesting-schedule',
    icon: <UserOutlined/>,
    roles: ['employee', 'contractor_consultant_Advisor'],
  },
  {
    title: 'Information questions',
    url: '/issuer-super-admin/information-questions',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },
  {
    title:'Investor Payment Details',
    url:'/issuer-token-admin/investor-payment-details',
    icon:<UserOutlined/>,
    roles:['issuer token admin']
  },
  {
    title:'AI Verification Request',
    url:'/issuer-compliance-admin/ai-verification-request',
    icon:<UserOutlined/>,
    roles:['issuer compliance admin']
  },
  {
    title: 'Create Admin Role',
    url: '/platform-super-admin/create-admin-role',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  {
    title: 'Manage all Admins Roles',
    url: '/platform-super-admin/manage-all-admin-roles',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  {
    title: 'View All Issuers',
    url: '/platform-super-admin/view-all-issuers',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  {
    title: 'View All Investors',
    url: '/platform-super-admin/view-all-investors',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  {
    title: 'View/Edit All Admins according to issuer',
    url: '/platform-super-admin/edit-all-admins',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  {
    title: 'Add Service Providers',
    url: '/platform-super-admin/add-service-providers',
    icon: <UserOutlined/>,
    roles: ['platform super admin'],
  },
  // {
  //   title: 'Dashboard',
  //   url: '/investor/dashboard',
  //   icon: <UserOutlined/>,
  //   roles: ['investor']
  // },
  {
    title: 'Invest',
    url: '/investor/home',
    icon: <UserOutlined/>,
    roles: ['investor']
   },
   {
    title: 'KYC Status',
    url: '/investor/kyc-status',
    icon: <UserOutlined/>,
    roles: ['investor']
   },
   {
    title: 'AI Verification status',
    url: '/investor/ai-status',
    icon: <UserOutlined/>,
    roles: ['investor']
   },
   {
    title: 'Payments',
    url: '/investor/payments',
    icon: <UserOutlined/>,
    roles: ['investor']
   },
  //  {
  //   title: 'Create a Legend',
  //   url: '/issuer-counsel/create-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer counsel'],
  // },
  // {
  //   title: 'Remove Legend from Securities',
  //   url: '/issuer-counsel/remove-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer counsel'],
  // },
  {
    title: 'Review Token Configuration',
    url: '/token-configurations',
    icon: <UserOutlined/>,
    roles: ['issuer super admin', 'issuer company officer', 'issuer counsel'],
  },
  {
    title: 'Review Affiliate Form 144',
    url: '/issuer-counsel/review-affiliate-form-144',
    icon: <UserOutlined/>,
    roles: ['issuer counsel'],
  },
  /*{
    title: 'Manage Approvers & Crypto Accounts',
    url: '/issuer-super-admin/approvers-and-accounts',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },*/
  {
    title: 'Amount Withdrawal Requests',
    url: '/issuer-super-admin/withdrawal-requests',
    icon: <UserOutlined/>,
    roles: ['issuer super admin', 'issuer company officer', 'issuer token admin', 'issuer compliance admin'],
  },
  {
    title: 'Security Recovery Processes',
    url: '/recovery-requests',
    icon: <UserOutlined/>,
    roles: ['issuer token admin'],
  },
  {
    title: 'Security Recovery Process',
    url: '/recovery-requests/new',
    icon: <UserOutlined/>,
    roles: ['investor'],
    // roles: ['employee', 'contractor_consultant_Advisor', 'investor'],
  },
];




const authService = new AuthService();

const useUserContext = () => authService.useUserContext();

export default () => {
  const history = useHistory();
  const {pathname} = useLocation();

  const {userInfo} = useUserContext();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  // const [openKeys, setOpenkeys] = useState<string[]>([]);
  const [visiblePages, setVisiblePages] = useState<any>();


  useEffect(() => {
    if(!selectedKeys.includes(pathname)) setSelectedKeys([pathname]);
    // if(!openKeys.includes('issuer-approval-requests')) {
    //   if(pathname === '/platform-issuer-admin/issuer-reg-reqs') setOpenkeys(['issuer-approval-requests']);
    // }
  }, [pathname, selectedKeys]);

  
  useEffect(() => {
    if(userInfo) {
      const v = [...appPages].filter(page => page.roles.includes(userInfo?.role as string)).map(page => {
        page.children = page.children?.filter(child => child.roles.includes(userInfo?.role as string));
        return page;
      });
      if(userInfo?.corporateRoles?.includes("director") || userInfo?.roles?.includes("issuer company officer") || userInfo?.roles?.includes("issuer counsel")) v.push({
          title: 'Authorization Request',
          url: '/authorization-request-review',
          icon: <UserOutlined/>,
          roles: [],
      });
      setVisiblePages(v);
    }
  }, [userInfo]);

    
  const goTo = ({key}) => history.push(key);


  return (
    <Sider 
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }} 
    >

      {userInfo &&
        <Menu
          mode="inline"
          theme='dark'
          selectedKeys={selectedKeys}
          // openKeys={openKeys}
          // defaultOpenKeys={selectedKeys}
          // style={{ height: '100%', borderRight: 0 }}
        >
          <li style={{lineHeight: 'normal', textAlign: 'center', fontWeight: 'bold', margin:'10px 0'}}>
            {/* {menuHeader[userInfo.role]} */}
            {
            userInfo.role === 'contractor_consultant_Advisor' || userInfo.role === 'employee'? 
              `PROFILE (${userInfo.role === 'contractor_consultant_Advisor'? 'CONTRACTOR' : 'EMPLOYEE'})` : menuHeader[userInfo.role]
            }
          </li>

          {(visiblePages as any[])?.map((page) => (
            !page.children?
              <Menu.Item key={page.url} onClick={goTo}>{/* {page.icon}  */} {page.title}</Menu.Item>
            :
              <SubMenu
                key={page.key}
                title={
                  <span>
                    {/* {page.icon}  */} {page.title}
                  </span>
                }>
                {(page.children as any[]).map(child => (
                    <Menu.Item key={child.url} onClick={goTo}>{child.title}</Menu.Item>
                  )
                )}
              </SubMenu>
          ))}
        </Menu>
      }
    </Sider>
  );
}
