import React, { useState, useEffect } from 'react';
import { TokenConfigurationProcess, VestingScheduleTemplate, SymbolDetailsAndSTData } from '../../../../Shared/interfaces';
import { AuthService } from '../../../../Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Spin, Card, Typography, Table, Result } from 'antd';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import { MetamaskService } from '../../../../Shared/Metamask.service';
import { SharedService } from '../../../../Shared/Shared.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';

import { SecurityTokenService } from '../../../../Shared/SecurityToken/SecurityToken.service';
// import { VestingService } from '../../../../Shared/Vesting/Vesting.service';
import VestingFacet from "../../../../Shared/SecurityToken/Facets/VestingFacet/index";

const {Title} = Typography;
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const securityTokenService = new SecurityTokenService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();

const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  const history = useHistory();

  const [vestingScheduleTemplates, setVestingScheduleTemplates] = useState<VestingScheduleTemplate[]>();
  const [loading, setLoading] = useState<boolean>(true);
  // const [vestingModuleExists, setVestingModuleExists] = useState<boolean>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  
  // const [loadingVestingScheduleList, setLoadingVestingScheduleList] = useState<boolean>(true);
  // const [vestingScheduleList, setVestingScheduleList] = useState<VestingSchedule[]>();
  // const [tokenConfigurationProcess, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  // const [loadingConfigProcess, setLoadingConfigProcess] = useState(true);
  // const [loadingMoreVestingScheduleList, setLoadingMoreVestingScheduleList] = useState<boolean>();
  // const [canLoadMoreVestingScheduleList, setCanLoadMoreVestingScheduleList] = useState(true);
  
  // const [filter, setFilter] = useState({limit: 10, searchPage: 1});

  // useEffect(() => {
  //   (async () => {
  //   if(!userInfo) return;
    
  //   const response = await issuerSuperAdminService.getTokenConfigurationProcess();
  //   const _tokenConfigurationProcess = response.data;
  //   setTokenConfigurationProcess(_tokenConfigurationProcess);
  //   setLoadingConfigProcess(false);

  //   })();
  // }, [userInfo]);

  
  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const [_symbolDetailsAndSTData] = await Promise.all([
        securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol),
      ]);

      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const _vestingModule = await securityTokenService.getVestingModule(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      const _vestingModuleExists = new BigNumber(_vestingModule.creationDate).isGreaterThan(0);

      // const _vestingScheduleTemplates = _vestingModuleExists? await vestingService.getVestingScheduleTemplates(_vestingModule.moduleAddress) : [];
      const _vestingScheduleTemplates = _vestingModuleExists? await vestingFacet.getVestingScheduleTemplates(_vestingModule.moduleAddress) : [];

      // _vestingScheduleTemplates.forEach(template => template['key'] = template.index);

      setLoading(false);
      // setVestingModuleExists(_vestingModuleExists);
      setVestingScheduleTemplates(_vestingScheduleTemplates);




      // if(!tokenConfigurationProcess) return;
      // setLoadingVestingScheduleList(true);
      
      // const response = await issuerSuperAdminService.getVestingScheduleList(tokenConfigurationProcess._id, filter.limit, 1);
      // const _vestingScheduleList: VestingSchedule[] = response.data;

      // setVestingScheduleList(_vestingScheduleList);
      // setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
      // setCanLoadMoreVestingScheduleList(_vestingScheduleList.length === filter.limit);
      // setLoadingVestingScheduleList(false);
      
      if(!_vestingScheduleTemplates.length) history.push(`/issuer-token-admin/token-managment/create-vesting-schedule/new`);
      
    })();
    
  // }, [tokenConfigurationProcess, history, filter.limit]);
  },[userInfo, history]);


  // const loadMoreVestingScheduleList = async() => {
  //   if(loadingMoreVestingScheduleList || !vestingScheduleList || !tokenConfigurationProcess) return;

  //   setLoadingMoreVestingScheduleList(true);

  //   const response = await issuerSuperAdminService.getVestingScheduleList(tokenConfigurationProcess._id, filter.limit, filter.searchPage);
  //   const moreVestingScheduleList: VestingSchedule[] = response.data;


  //   setVestingScheduleList(prev => [...prev as any, ...moreVestingScheduleList]);
  //   setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
  //   setCanLoadMoreVestingScheduleList(moreVestingScheduleList.length === filter.limit);
  //   setLoadingMoreVestingScheduleList(false);
  // }


  const colums = [
    {
      title: 'Vesting',
      dataIndex: 'name',
      render: (value: string) => sharedService.bytes32ToString(value)
    },
    {
     title: 'Duration',
     dataIndex: 'duration',
     render: (value: number) => `${value} months` 
    },
    {
      title: 'Total Schedules',
      dataIndex: 'totalSchedules',
      render: (value, record: VestingScheduleTemplate) => record.vesting.length
    },
    {
      title: 'Details',
      render: (value, record: VestingScheduleTemplate) => {
        return (
          <>
            <Button type='link' onClick={() => {
              history.push(`/issuer-token-admin/token-managment/create-vesting-schedule/details/${record.index}`)
              // setSelectedVestingSchedule(record); 
              // setScreenState('details')
              }}>
                View
            </Button>
            <Button type='link' onClick={() => {
              history.push(`/issuer-token-admin/token-managment/create-vesting-schedule/edit/${record.index}`)
              // setSelectedVestingSchedule(record); 
              // setScreenState('edit')
              }}>
              Edit
            </Button>
          </>
        );
      }
    }
  ];


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }

          {!loading &&
            <Card>
              <Title level={1} style={{textAlign:'center'}}>List of Vesting Schedules</Title>
              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                  subTitle="You have to deploy your Security Token first"
                  extra={
                    <Button key="console" onClick={() => history.push('/token-configurations')}>
                      Create Token
                    </Button>
                  }/>
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                <>
                  <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                  <Result
                    status="error"
                    title = {
                      <p>
                        Select the wallet {' '}
                        <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                          {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                        </a> 
                        {' '} in order to Create Vesting
                      </p>
                    }
                  />
                </>
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                <>
                  <Table
                    columns={colums} 
                    dataSource={vestingScheduleTemplates}
                    pagination={false}
                    rowKey='index'
                    footer={()=>
                      <div style={{textAlign:'center'}}>
                        {/* {vestingScheduleList && canLoadMoreVestingScheduleList && 
                          <Button onClick={loadMoreVestingScheduleList}><PlusOutlined/>Load more</Button>
                        } */}
                      </div>
                    }
                  />
                  <div style={{textAlign:'right'}}>
                    <Button size='large' type='primary' htmlType="submit" onClick={()=> history.push(`/issuer-token-admin/token-managment/create-vesting-schedule/new`)}>
                      NEW VESTING SCHEDULE
                    </Button>
                  </div>
                </>
              }
              
            </Card>
          }
        </Col>
      </Row>
    </>
  );
}