import { SharedService } from '../Shared/Shared.service';
import { APIResponse} from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';

// import { SecurityTokenService } from '../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../Shared/SecurityToken/Facets/WhitelistFacet/index';

const sharedService = new SharedService();
// const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();


export class IssuerCounselService {

  private APIURL = environment.APIURL;

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/issuerCounsel/uploadDocument`, req, progressCb, errorCb, completeCb)
  }

  async createLegend(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerCounsel/createLegend`, req, {headers: await sharedService.getAuthHeader()});
  }

  async uploadOpinionLetter(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerCounsel/uploadOpinionLetter`, req, {headers: await sharedService.getAuthHeader()});
  }


  async getFiledNoticesWithoutApproval(tokenAddress: string) {

    // const addresses = await securityTokenService.getAffiliatesWithApprovedFileNotice(tokenAddress);
    const addresses = await whitelistFacet.getAffiliatesWithApprovedFileNotice(tokenAddress);

    const response = await axios.get<any, APIResponse>(`${this.APIURL}/issuerCounsel/getFiledNoticesWithoutApproval`, {params: {addresses: addresses.toString()}, headers: await sharedService.getAuthHeader()});

    const filedNotices = response.data;

    return {filedNotices, totalAffiliatesWithApprovedFileNotice: addresses.length};
  }

  async getFiledNotice(filedNoticeId: string) {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerCounsel/getFiledNotice`, {params: {filedNoticeId}, headers: await sharedService.getAuthHeader()});
  }

  async rejectFiledNotice(req: {filedNoticeId: string, comments: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerCounsel/rejectFiledNotice`, req, {headers: await sharedService.getAuthHeader()});
  }

  async notifyApprovedFiledNotice(req: {filedNoticeId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerCounsel/notifyApprovedFiledNotice`, req, {headers: await sharedService.getAuthHeader()});
  }


}