import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateLegend from './CreateLegend';
import RemoveLegend from './RemoveLegend';
import FiledNoticesWithSEC from './FiledNoticesWithSEC';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create-legend/:tokenConfigurationProcessId`}>
        <CreateLegend/>
      </Route>
      <Route path={`${path}/remove-legend/:tokenConfigurationProcessId`}>
        <RemoveLegend/>
      </Route>
      <Route path={`${path}/review-affiliate-form-144`}>
        <FiledNoticesWithSEC/>
      </Route>
    </Switch>
  );
}