import React,{useState,useEffect} from 'react';
import {Result,Button,Card,Col,Row,Typography,Descriptions,Divider} from 'antd'
import {InvestorService} from '../Investor.service'
import { useHistory } from 'react-router-dom';
import {AuthService} from '../../Shared/Auth.service'
import moment from 'moment';
import Process from '../Process';
const {Title}=Typography;
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService=new InvestorService();
export default ()=>{
    const history=useHistory();
    const {userInfo,setUserInfo}=useUserContext();
    const [currentStatus,setCurrentStatus]=useState(null);
    const [investorDetails,setInvestorDetails]=useState(null);
    const [previousState,setPreviousState]=useState(null);
    useEffect(()=>{
        (async ()=>{
            const response=await investorService.getKYCDetails({investorId:userInfo._id});
            if(response.success && response.data)
            {
                if(response.data.length<=0)
                {
                    setCurrentStatus('notSubmitted')
                }
                else
                {
                    setInvestorDetails(response.data['0']);
                    setCurrentStatus('submitted')
                    if(response.data['0'].approvedByICA)
                    {   
                        setCurrentStatus('approved')
                    }
                    if(response.data['0'].rejected)
                    {
                        setCurrentStatus('rejected')
                    }
                }
            }
        })()
    },[])
    return(
        <>
        <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
            <Process />
            <Divider />
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            KYC Status
          </Title> 
        <div hidden={currentStatus!=='approved'}>
        <Result
         status="success"
         title="Successfully verified!"
         subTitle="your KYC details are verified successfully.It will be valid for 6 months."
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>,
             <Button type="primary" onClick={()=>{setCurrentStatus('viewDetails'); setPreviousState('approved');}} >Details</Button>
         ]}
        >
        </Result>
        </div>
        <div hidden={currentStatus!=='rejected'}>
        <Result
         status="error"
         title="Rejected!"
         subTitle="Your KYC details are rejected"
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>,
             <Button type="primary" onClick={()=>{setCurrentStatus('viewDetails'); setPreviousState('rejected')}} >Details</Button>
         ]}
        >
        </Result>
        </div>
        <div hidden={currentStatus!=='submitted'}>
        <Result
         title="Successfully Submitted!"
         subTitle="your KYC details are successfully submitted please wait for the result."
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>,
             <Button type="primary" onClick={()=>{setCurrentStatus('viewDetails'); setPreviousState('submitted');}} >Details</Button>
         ]}
        >
        </Result>
        
        </div>
        <div hidden={currentStatus!=='notSubmitted'}>
        <Result
         status="warning"
         title="Not Submitted!"
         subTitle="you have not submitted your KYC details."
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>
         ]}
        >
        </Result>
        </div>
        <div hidden={currentStatus!=='viewDetails'}>
        <Descriptions 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            title="KYC Details"
            bordered>
          <Descriptions.Item label="Selfie"><img height={200} width={200} src={investorDetails?.selfie} target="_blank"></img></Descriptions.Item>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of KYC Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
          <Descriptions.Item label="Present Address">{<div>{investorDetails?.pAStreetAddress1} <br/>{investorDetails?.pAStreetAddress2} <br/> {investorDetails?.pACity} {investorDetails?.pAZipCode} </div>}</Descriptions.Item>
              <Descriptions.Item label="Present State">{investorDetails?.pAState}</Descriptions.Item>
              <Descriptions.Item label="Present Country">{investorDetails?.pACountry}</Descriptions.Item>
              <Descriptions.Item label="Permanent Address">{<div>{investorDetails?.pStreetAddress1} <br/> {investorDetails?.pStreetAddress2} <br/> {investorDetails?.pCity} {investorDetails?.pZipCode}</div>}</Descriptions.Item>
              <Descriptions.Item label="Permanent State">{investorDetails?.pState}</Descriptions.Item>
              <Descriptions.Item label="Permanent Country">{investorDetails?.pCountry}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide.URL} target="_blank" >{investorDetails?.licenceFrontSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Licence Back Side"><a href={investorDetails?.licenceBackSide.URL} target="_blank">{investorDetails?.licenceBackSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Passport"><a href={investorDetails?.passport.URL} target="_blank">{investorDetails?.passport?.name}</a></Descriptions.Item>
            </Descriptions>
            <br/>
            <Button type='primary' onClick={()=>setCurrentStatus(previousState)} >Back</Button>
            &nbsp;&nbsp;&nbsp;
            <Button type="primary" onClick={()=>history.push('/investor/home')} >Home</Button>
        </div>
        </Card>
        </Col>
        </Row>
        </>
    )
}