import React, { useEffect,useState } from 'react'
import {Table,Card,Col,Row,Typography, Descriptions,Button,Badge,Tabs} from 'antd'
import {IssuerComplianceAdminService} from '../IssuerComplianceAdmin.service'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import {AuthService} from '../../Shared/Auth.service'
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment'
const {Title}=Typography
const {TabPane}=Tabs
const issuerComplianceAdminService=new IssuerComplianceAdminService()

const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();


export default ()=>{
  const [investors,setInvestors]=useState()
  const [detailsDivision2,setDetailsDivision2]=useState(true)
  const {userInfo,setUserInfo}=useUserContext()
  const [detailsDivision,setDetailsDivision]=useState(true)
  const [investorDetails,setInvestorDetails]=useState()
  const [submitting,setSubmitting]=useState(false)
  const [approvedAndRejectedKYCRequests,setInvestorsList]=useState([])
  console.log(userInfo);
  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'ROLE',
      dataIndex:'role',
      key:'role'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetails(investor)}>DETAILS</Button>
    }

  ]

  const columns2=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'ROLE',
      dataIndex:'role',
      key:'role'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return(
          <>
          {investor.approvedByICA && <span>Approved</span>}
          {investor.rejected && <span>rejected</span>}
          </>

        )
      }
    },
    {
      title:'Action',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetailsForResult(investor)}>DETAILS</Button>
    }

  ]
  const showDetails=investor=>{
    setDetailsDivision(false)
    setInvestorDetails(investor)
  }

  const showDetailsForResult=investor=>{
    setDetailsDivision2(false)
    setInvestorDetails(investor)
  }
  const rejectKYC=(details)=>{
    (async ()=>{
      setSubmitting(true)
      details.approved=false
      details.rejected=true
      const response=await issuerComplianceAdminService.updateTeamMemberKYC(details);
      if(response.success)
      {
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  const acceptKYC=(details)=>{
    (async ()=>{
      setSubmitting(true)
      details.approvedByICA=true
      details.rejected=false
      const response=await issuerComplianceAdminService.updateTeamMemberKYC(details);
      if(response.success)
      {
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  useEffect(
    ()=>{
      (async ()=>{
        let data={company:userInfo.companyName.toLowerCase()}
        const response=await issuerComplianceAdminService.getTeamMemberKYCRequests(data);
        setInvestors(response.data)
      }
    )();
    },[detailsDivision]
  )
  useEffect(
    ()=>{
      (async ()=>{
        let data={company:userInfo.companyName.toLowerCase()}
        const response=await issuerComplianceAdminService.getTeamMemberList(data);
        setInvestorsList(response.data)
      }
    )();
    },[detailsDivision]
  )

  return(
  <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Team Member KYC Request
          </Title>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={<span><PlaySquareOutlined/>Pending KYC Requests</span>}  key="1">
          <div hidden={!detailsDivision}>
          <Table dataSource={investors} columns={columns} />
          </div>
          <div hidden={detailsDivision}>
          <Descriptions title='Team Member Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            bordered>
          <Descriptions.Item label="Selfie"><img height={200} width={200} src={investorDetails?.selfie} ></img></Descriptions.Item>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of KYC Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
          <Descriptions.Item label="Present Address">{<div>{investorDetails?.pAStreetAddress1} <br/>{investorDetails?.pAStreetAddress2} <br/> {investorDetails?.pACity} {investorDetails?.pAZipCode} </div>}</Descriptions.Item>
              <Descriptions.Item label="Present State">{investorDetails?.pAState}</Descriptions.Item>
              <Descriptions.Item label="Present Country">{investorDetails?.pACountry}</Descriptions.Item>
              <Descriptions.Item label="Permanent Address">{<div>{investorDetails?.pStreetAddress1} <br/> {investorDetails?.pStreetAddress2} <br/> {investorDetails?.pCity} {investorDetails?.pZipCode}</div>}</Descriptions.Item>
              <Descriptions.Item label="Permanent State">{investorDetails?.pState}</Descriptions.Item>
              <Descriptions.Item label="Permanent Country">{investorDetails?.pCountry}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
          <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide} target="_blank">License Front Side</a></Descriptions.Item>
          <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide.URL} target="_blank" >{investorDetails?.licenceFrontSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Licence Back Side"><a href={investorDetails?.licenceBackSide.URL} target="_blank">{investorDetails?.licenceBackSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Passport"><a href={investorDetails?.passport.URL} target="_blank">{investorDetails?.passport?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Result">{investorDetails?.KYCResultFile?.map((file,index)=>{
                return(
                  <div>
                    {index+1}) <a href={file?.KYCResultFile?.URL} target="_blank">{file?.KYCResultFile?.URL}</a>
                  </div>
                )
              })}</Descriptions.Item>
            </Descriptions>
            <br/>
            <Button onClick={()=>acceptKYC(investorDetails)} type='primary' loading={submitting} >Accept</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>rejectKYC(investorDetails)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDetailsDivision(true)} disabled={submitting}>Cancel</Button>
          </div>
          </TabPane>
          <TabPane tab={<span><ProjectOutlined/>Approved/Rejected KYC Requests</span>} key="2">
          <div hidden={!detailsDivision2} >
              <Table columns={columns2} dataSource={approvedAndRejectedKYCRequests}></Table>
            </div>
          <div hidden={detailsDivision2}>
          <Descriptions title='Team Member Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            bordered>
          <Descriptions.Item label="Selfie"><img height={200} width={200} src={investorDetails?.selfie} ></img></Descriptions.Item>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of KYC Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
          <Descriptions.Item label="Present Address">{<div>{investorDetails?.pAStreetAddress1} <br/>{investorDetails?.pAStreetAddress2} <br/> {investorDetails?.pACity} {investorDetails?.pAZipCode} </div>}</Descriptions.Item>
              <Descriptions.Item label="Present State">{investorDetails?.pAState}</Descriptions.Item>
              <Descriptions.Item label="Present Country">{investorDetails?.pACountry}</Descriptions.Item>
              <Descriptions.Item label="Permanent Address">{<div>{investorDetails?.pStreetAddress1} <br/> {investorDetails?.pStreetAddress2} <br/> {investorDetails?.pCity} {investorDetails?.pZipCode}</div>}</Descriptions.Item>
              <Descriptions.Item label="Permanent State">{investorDetails?.pState}</Descriptions.Item>
              <Descriptions.Item label="Permanent Country">{investorDetails?.pCountry}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              
              <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide.URL} target="_blank" >{investorDetails?.licenceFrontSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Licence Back Side"><a href={investorDetails?.licenceBackSide.URL} target="_blank">{investorDetails?.licenceBackSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Passport"><a href={investorDetails?.passport.URL} target="_blank">{investorDetails?.passport?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Result">{investorDetails?.KYCResultFile?.map((file,index)=>{
                return(
                  <div>
                    {index+1}) <a href={file?.KYCResultFile?.URL} target="_blank">{file?.KYCResultFile?.URL}</a>
                  </div>
                )
              })}</Descriptions.Item>
              </Descriptions>
              <br/>
              <Button type="primary" htmlType="button" onClick={()=>{ setDetailsDivision2(true); }} >BACK</Button>
              </div>
          </TabPane>
          </Tabs>
          </Card>
      </Col>
     </Row>
  </>
  )
}