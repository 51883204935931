import { Col, Row } from "antd";
import React from "react";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";

const Disclaimer = () => {
  return (
    <div id="disclaimer" style={{ paddingTop: "150px",textAlign:'justify' }}>
      <Container>
        <div style={{ padding: "20px" }}></div>
        <div style={{ textAlign: "center" }}>
        <p style={{color:'#141838',fontSize:'55px',fontWeight:'bold'}}><span className="underline">Disclaimer</span></p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="tick">
              <div style={{ padding: "15px" }}></div>

              <h3 style={{color:'#00b6e3'}}>
                DISCLAIMERS FOR CRYPTO ASSET RATING WEBSITE
              </h3>

              <div style={{ padding: "15px" }}></div>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                THE INFORMATION CONTAINED IN OR LINKED TO THE CRYPTO ASSET
                RATING (“CRYPTO ASSET RATING”) WEBSITE IS FOR INFORMATION
                PURPOSES ONLY AND MAY NOT CONTAIN ALL THE RELEVANT INFORMATION
                REGARDING CRYPTO ASSET RATING OR ITS RATING METHODOLOGIES. THE
                CRYPTO ASSET RATING WEBSITE SHOULD NOT BE CONSIDERED A
                SOLICITATION, OFFER OR RECOMMENDATION FOR THE PURCHASE OR SALE
                OF ANY SECURITIES OR OTHER FINANCIAL PRODUCTS AND SERVICES
                DISCUSSED HEREIN. VIEWERS OF THIS WEBSITE WILL NOT BE CONSIDERED
                INVESTORS AND/OR CLIENTS OF CRYPTO ASSET RATING JUST BY VIRTUE
                OF ACCESS TO THIS WEBSITE. VISITORS TO CRYPTO ASSET RATING SITE
                SHOULD NOT CONSTRUE ANY DISCUSSION OR INFORMATION CONTAINED
                HEREIN AS PERSONALIZED ADVICE FROM CRYPTO ASSET RATING. CRYPTO
                ASSET RATING SHALL NOT BE LIABLE FOR ANY ERRORS OR INACCURACIES,
                REGARDLESS OF CAUSE, OR THE LACK OF TIMELINESS OF, OR FOR ANY
                DELAY OR INTERRUPTION IN, THE TRANSMISSION THEREOF TO THE
                WEBSITE USER.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                DECISIONS BASED ON INFORMATION CONTAINED ON THE CRYPTO ASSET
                RATING WEBSITE ARE THE SOLE RESPONSIBILITY OF THE PERSON VIEWING
                THE WEBSITE. IN EXCHANGE FOR UTILIZING THE INFORMATION ON THIS
                WEBSITE, THE VISITOR AGREES TO INDEMNIFY AND HOLD CRYPTO ASSET
                RATING, ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS,
                LICENSORS AND SUPPLIERS HARMLESS AGAINST ANY AND ALL CLAIMS,
                LOSSES, LIABILITY, COSTS AND EXPENSES (INCLUDING BUT NOT LIMITED
                TO ATTORNEYS' FEES) ARISING FROM THE USE OF THIS WEBSITE, OR
                FROM ANY INVESTMENT DECISIONS THAT THE VIEWER MAKES BASED ON
                SUCH INFORMATION.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                PROSPECTIVE INVESTORS SHOULD READ IN DETAIL THE CRYPTO ASSET
                RATING WHITE PAPER, OFFERING MEMORANDUM AND OTHER RELEVANT
                DOCUMENTS (“OFFER DOCUMENTS”) AND SEEK INDEPENDENT LEGAL AND
                FINANCIAL ADVICE, OR INDEPENDENTLY RESEARCH AND VERIFY ANY
                INFORMATION THAT THEY FIND ON THE CRYPTO ASSET RATING WEBSITE OR
                IN THE OFFER DOCUMENTS FOR THE PURPOSE OF MAKING AN INVESTMENT
                DECISION OR OTHERWISE. THE WEBSITE OR THE OFFER DOCUMENTS DO NOT
                CONTAIN ANY LEGAL OR FINANCIAL ADVICE AND POTENTIAL INVESTORS
                SHOULD REFER TO THE APPLICABLE PROVISIONS OF THE SECURITIES
                LEGISLATION IN THEIR RESPECTIVE JURISDICTION FOR THE PURPOSE OF
                THE INVESTMENT OR CONSULT WITH A LEGAL AND FINANCIAL ADVISOR.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                PROSPECTIVE INVESTORS SHOULD READ THE CRYPTO ASSET RATING OFFER
                DOCUMENTS CAREFULLY BEFORE INVESTING IN CRYPTO ASSET RATING
                TOKENS AND SHOULD BE AWARE THAT INVESTMENT IN CRYPTO ASSET
                RATING INVOLVES A HIGH DEGREE OF RISK. PEASE READ THE SECTIONS
                IN THE OFFER DOCUMENTS ENTITLED “RISK FACTORS”, “NOTICE TO
                INVESTORS” AND “TRANSFER RESTRICTIONS” FOR A DISCUSSION OF RISKS
                AND OTHER FACTORS WHICH SHOULD BE CONSIDERED PRIOR TO ANY
                INVESTMENT IN CRYPTO ASSET RATING.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                THE CRYPTO ASSET RATING TOKENS ARE BEING OFFERED ONLY IN THOSE
                JURISDICTIONS WHERE THEY MAY BE LAWFULLY PERMITTED TO BE OFFERED
                FOR SALE AND THEREIN ONLY TO THOSE PERSONS TO WHOM THEY MAY BE
                LAWFULLY OFFERED FOR SALE. PROSPECTIVE INVESTORS SHOULD INFORM
                THEMSELVES AS TO THE LEGAL REQUIREMENTS AND TAX CONSEQUENCES
                WITHIN THE COUNTRIES OF THEIR CITIZENSHIP, RESIDENCE, DOMICILE,
                AND PLACE OF BUSINESS WITH RESPECT TO THE ACQUISITION, HOLDING,
                OR DISPOSAL OF CRYPTO ASSET RATING TOKENS, AND ANY FOREIGN
                EXCHANGE RESTRICTIONS THAT MAY BE RELEVANT THERE TO THE
                DISTRIBUTION OF OFFER DOCUMENTS, AND THE OFFER AND SALE OF
                CRYPTO ASSET RATING TOKENS IN CERTAIN JURISDICTIONS MAY BE
                RESTRICTED BY LAW. THIS OFFER BY CRYPTO ASSET RATING DOES NOT
                CONSTITUTE AN OFFER TO SELL OR THE SOLICITATION OF AN OFFER TO
                BUY IN ANY COUTRY, STATE OR JURISDICTION TO ANY PERSON TO WHOM
                IT IS UNLAWFUL TO MAKE SUCH AN OFFER OR SOLICITATION IN SUCH
                COUNTRY, STATE OR JURISDICTION, ESPECIALLY IN CHINA, SOUTH
                KOREA, MACAU, RUSSIA AND DUBAI.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                THE OFFERING BY CRYPTO ASSET RATING OF CRYPTO ASSET RATING
                TOKENS HAVE NOT BEEN AND WILL NOT BE REGISTERED UNDER THE UNITED
                STATES SECURITIES ACT OF 1933, OR ANY EUROPEAN UNION OR UNITED
                STATES STATE BLUE SKY SECURITIES LAWS OR THE SINGAPORE
                SECURITIES LAWS OR THE SECURITIES LAWS OF CAYMAN ISLANDS OR THE
                LAWS OF ANY OTHER JURISDICTION. THE INTERESTS WILL BE OFFERED
                AND SOLD UNDER EXEMPTIONS UNDER THE LAWS OF THE JURISDICTIONS
                WHERE THE OFFERING WILL BE MADE. CONSEQUENTLY, INVESTORS WILL
                NOT BE AFFORDED THE PROTECTIONS OF THOSE LAWS.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                THE OFFER OF CRYPTO ASSET RATING TOKENS IN THE UNITED STATES IS
                BEING MADE PURSUANT TO RULE 506(C) OF REGULATION D OF THE
                SECURITIES ACT AND PARTICIPATION IN THE OFFERING IS LIMITED TO
                (I) INSIDE THE UNITED STATES TO “ACCREDITED INVESTORS” (AS
                DEFINED UNDER THE SECURITIES ACT, RULE 506 OF REGULATION D)
                CONSIDERED “A SAFE HARBOR" FOR THE PRIVATE OFFERING EXEMPTION OF
                SECTION 4(A)(2) OF THE SECURITIES ACT OF 1933 AS AMENDED AND
                (II) NON-U.S. PERSONS (AS DEFINED IN SECTION 902 OF REGULATION
                SUNDER THE SECURITIES ACT) IN AN OFFSHORE TRANSACTION IN
                RELIANCE ON REGULATION S OF THE SECURITIES ACT OF 1933.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                PROSPECTIVE INVESTORS SHOULD MAKE THEIR OWN INVESTIGATIONS AND
                EVALUATIONS OF CRYPTO ASSET RATING, INCLUDING THE MERITS AND
                RISKS INVOLVED IN AN INVESTMENT IN CRYPTO ASSET RATING.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <p>
                  THE TOKEN HAS NOT BEEN REGISTERED UNDER THE U.S. SECURITIES
                  ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”), AND,
                  ACCORDINGLY, MAY NOT BE OFFERED, SOLD, PLEDGED, OR OTHERWISE
                  TRANSFERRED WITHIN THE UNITED STATES OR TO, OR FOR THE ACCOUNT
                  OR BENEFIT OF, U.S. PERSONS, EXCEPT AS SET FORTH IN THE
                  FOLLOWING SENTENCE. BY ITS ACQUISITION HEREOF OR OF A
                  BENEFICIAL INTEREST HEREIN, THE HOLDER:
                </p>
                <p>
                  1. AGREES THAT IT WILL NOT RESELL OR OTHERWISE TRANSFER THIS
                  SECURITY, EXCEPT (A) IF IT IS A U.S. PERSON, UNTIL THE FIRST
                  ANNIVERSARY OF THE ISSUANCE OF THE VAULTBANK TOKENS AND THEN
                  NOT TO ANY U.S. PERSON (AS DEFINED IN REGULATIONS) UNLESS THEY
                  SELL ALL OF THEIR VAULTBANK TOKENS TO A SINGLE U.S. PERSON;
                  (B) IF IT IS A NON-U.S. PERSON OUTSIDE THE UNITED STATES IN AN
                  OFFSHORE TRANSACTION IN COMPLIANCE WITH RULE 903 OR RULE 904
                  UNDER THE SECURITIES ACT AND SUBJECT TO COMPLIANCE WITH
                  APPLICABLE LAWS IN OTHER JURISDICTIONS; (C) TO VAULTBANK
                </p>
                <p>
                  PURSUANT TO THE REDEMPTION DESCRIBED IN THE OFFERING
                  MEMORANDUM; OR (D) PURSUANT TO AN EFFECTIVE REGISTRATION
                  STATEMENT UNDER THE SECURITIES ACT AND, IN EACH CASE, IN
                  ACCORDANCE WITH APPLICABLE STATE AND LOCAL SECURITIES LAWS,
                  AND
                </p>
                <p>
                  2. AGREES THAT IT WILL DELIVER TO EACH PERSON TO WHOM THIS
                  SECURITY OR AN INTEREST HEREIN IS TRANSFERRED (OTHER THAN A
                  TRANSFER PURSUANT TO CLAUSE (I)(D) ABOVE) A NOTICE
                  SUBSTANTIALLY TO THE EFFECT OF THIS LEGEND.
                </p>
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                CRYPTO ASSET RATING WILL NOT PROVIDE RATINGS FOR ANY TOKENS
                WHICH ARE LABELED OR MARKED AS A "CRYPTOCURRENCY" OR FOR ANY
                TOKEN WHICH IS FULLY DECENTRALIZED. CRYPTO ASSET RATING MAY
                DECLINE TO PROVIDE RATINGS FOR A PARTICULAR DIGTIAL ASSET IN ITS
                SOLE DISCRETION.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                CRYPTO ASSET RATING IS NOT A BROKER-DEALER OR PLACEMENT AGENT.
                AT NO TIME DOES CRYPTO ASSET RATING OFFER, BROKER, ADVISE,
                PURCHASE, SELL OR OTHERWISE TRANSACT IN SECURITIES REGULATED BY
                THE SEC OR FEDERAL OR STATE LAW. CRYPTO ASSET RATING IS NOT A
                PERSONAL FINANCIAL ADVISOR. CRYPTO ASSET RATING, WHETHER THROUGH
                THE WEBSITE OR OTHERWISE, DOES NOT PROVIDE PERSONAL FINANCIAL
                ADVICE, LOANS OR CREDIT, BANKING, CONSUMER CREDIT RATINGS,
                CREDIT DECISIONS, FINANCIAL PRODUCTS, BROKERAGE ACCOUNTS,
                INSURANCE, TAX ADVICE, LEGAL ADVICE, OR FINANCIAL OR LEGAL
                SERVICES OF ANY KIND. CRYPTO ASSET RATING TAKES A STANDARD FEE
                FOR FEATURING AN CRYPTO ASSET ON THE PLATFORM, HOWEVER, SUCH FEE
                DOES NOT HAVE ANY EFFECT ON THE RATING PROVIDED FOR SUCH CRYPO
                RATING. EVEN IF FEATURED ON THE PLATFORM, UNLESS EXPRESSLY
                STATED OTHERWISE, WE DO NOT PROVIDE ENDORSEMENT TO ANY CAPITAL
                OR INVESTMENT OPPORTUNITY, INITIAL COIN OFFERING, TOKENS OR ANY
                OTHER DIGITAL ASSET.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                For more information, please read our{" "}
                <a href="#">
                  Private Placement Document (Offering Memorandum)
                </a>
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                For more information, please read{" "}
                <a href="#">
                  Token Purchase Agreement
                </a>
              </li>
              <div style={{ padding: "30px" }}></div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Disclaimer;
