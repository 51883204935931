import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Form, Input, Button, Table, Spin, Result, notification, Descriptions, message, Modal, Checkbox, Radio } from 'antd';
import { AuthService } from '../Shared/Auth.service';
import { MetamaskService } from '../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import { SharedService } from '../Shared/Shared.service';
import { TokenConfigurationProcess, SymbolDetailsAndSTData, UserLockedAmount, TokenModule, WhitelistedWallet, FiledNotice, RegLaunched } from '../Shared/interfaces';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin/IssuerSuperAdmin.service';
import TransactionModal from '../Shared/TransactionModal';
import moment from 'moment';
import { TokenConfigurationService } from '../TokenConfigurations/TokenConfiguration.service';
import { useHistory } from 'react-router-dom';
import { TeamMemberService } from '../TeamMember/TeamMember.service';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin/IssuerTokenAdmin.service';

// import { GeneralTransferManagerService } from '../Shared/GeneralTransferManager/GeneralTransferManager.service';
import { SecurityTokenService } from '../Shared/SecurityToken/SecurityToken.service';
import GeneralTransferManagerFacet from '../Shared/SecurityToken/Facets/GeneralTransferManagerFacet/index';
import MainFacet from '../Shared/SecurityToken/Facets/MainFacet/index';
import WhitelistFacet from '../Shared/SecurityToken/Facets/WhitelistFacet/index';
import TransferFacet from '../Shared/SecurityToken/Facets/TransferFacet/index';
import Rule144Facet from '../Shared/SecurityToken/Facets/Rule144Facet/index';

const { Title } = Typography;
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const teamMemberService = new TeamMemberService();
const tokenConfigurationService = new TokenConfigurationService();


// const generalTransferManagerService = new GeneralTransferManagerService();
const securityTokenService = new SecurityTokenService();
const generalTransferManagerFacet = new GeneralTransferManagerFacet();
const mainFacet = new MainFacet();
const whitelistFacet = new WhitelistFacet();
const transferFacet = new TransferFacet();
const rule144Facet = new Rule144Facet();


const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const etherscanURL = sharedService.etherscanURL;
const affiliateFormCheckboxesOpts = [
  'Make sure that the sale is handled under routine trading transactions',
  'Make sure that neither you (Affiliate) nor Broker cannot solicit the orders to buy the securities',
  'Make sure that the Brokers may not receive more than normal commission'
];
export default function TokenDashboard() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const history = useHistory();
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [transactions, setTransactions] = useState<{ submitting?: boolean, receipt?: any, details: string }[]>([]);
  const [monthUnit, setMonthUnit] = useState('');
  const [generalTransferManagerModule, setGeneralTransferManagerModule] = useState<TokenModule>();
  const [balance, setBalance] = useState<string>();
  const [totalLockedAmount, setTotalLockedAmount] = useState<string>();
  const [totalLockedAmountAccredeted, setTotalLockedAmountAccredeted] = useState<string>();
  const [userLockedAmountList, setUserLockedAmountList] = useState<UserLockedAmount[]>();
  const [whitelistedWallet, setWhitelistedWallet] = useState<WhitelistedWallet>();
  const [maxAffiliateSellPercentage, setMaxAffiliateSellPercentage] = useState<string>();
  const [maxAffiliateAmountMonths, setMaxAffiliateAmountMonths] = useState<string>();
  const [maxAffiliateAmount, setMaxAffiliateAmount] = useState<string>();
  const [maxAffiliateTokensMonths, setMaxAffiliateTokensMonths] = useState<string>();
  const [maxAffiliateTokens, setMaxAffiliateTokens] = useState<string>();
  const [totalSupply, setTotalSupply] = useState<string>();
  const [affiliateAmountSentIn3Months, setAffiliateAmountSentIn3Months] = useState<string>();
  const [affiliateSellLimit, setAffiliateSellLimit] = useState<string>();
  const [affiliateTokenAmountSent, setAffiliateTokenAmountSent] = useState<string>();
  const [affiliateUSDAmountSent, setAffiliateUSDAmountSent] = useState<string>();
  const [USDPrice, setUSDPrice] = useState<string>();
  const [lastUpdateUSDPrice, setLastUpdateUSDPrice] = useState<string>();
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>([]);
  // const [regBalance, setRegBalance] = useState<RegBalance>();
  const [selectedRegToTransfer, setSelectedRegToTransfer] = useState<RegLaunched>();
  const [defaultSenderReg, setDefaultSenderReg] = useState<RegLaunched>();
  const [defaultSenderRegIndex, setDefaultSenderRegIndex] = useState<number>(-1);
  const [isDefaultRegModalVisible, setIsDefaultRegModalVisible] = useState(false);
  const [isTokenPriceOutdated, setIsTokenPriceOutdated] = useState<boolean>();
  const [needsFiledNoticeWithSEC, setNeedsFiledNoticeWithSEC] = useState<boolean>();
  const [requestingPriceUpdate, setRequestingPriceUpdate] = useState<boolean>();
  const [filedNotice, setFiledNotice] = useState<FiledNotice>();
  const [isAffiliateModalVisible, setIsAffiliateModalVisible] = useState<boolean>();
  const [isTokenTransferVisible, setIsTokenTransferVisible] = useState<boolean>();
  const [form] = Form.useForm();
  const [formUSDPrice] = Form.useForm();
  const [affiliateForm] = Form.useForm();
  const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);
  const precision = securityTokenService.precision;
  const tokenSymbol = symbolDetailsAndSTData?.securityTokenData.symbol;

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess: TokenConfigurationProcess;
      // if(userInfo.role === 'issuer super admin') {
      _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      // } else {
      //   _tokenConfigurationProcess = (await teamMemberService.getTokenConfigurationProcess()).data;
      // }
      // setTokenConfigurationProcess(_tokenConfigurationProcess);

      if (!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const _generalTransferManagerModule = await securityTokenService.getGeneralTransferManagerModule(_symbolDetailsAndSTData.securityTokenData.contractAddress);

      // let [
      //   _totalLockedAmount, 
      //   _totalLockedAmountAccredeted, 
      //   _userLockedAmountListTemp,
      //    _balance, 
      //    _whitelistedWallet,
      //    _totalSupply,
      //    _monthUnit,
      //    __regulationsLaunched,
      //    _defaultSenderReg
      // ] = await Promise.all([
      //   generalTransferManagerService.calculateTotalLockedAmount(_generalTransferManagerModule.moduleAddress, selectedWallet),
      //   generalTransferManagerService.calculateTotalLockedAmountAccredited(_generalTransferManagerModule.moduleAddress, selectedWallet),
      //   generalTransferManagerService.getUserLockedAmountList(_generalTransferManagerModule.moduleAddress, selectedWallet),
      //   securityTokenService.balanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
      //   securityTokenService.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
      //   securityTokenService.totalSupply(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   securityTokenService.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   securityTokenService.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   securityTokenService.getDefaultSenderReg(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet)
      // ]);

      let [
        _totalLockedAmount,
        _totalLockedAmountAccredeted,
        _userLockedAmountListTemp,
        _balance,
        _whitelistedWallet,
        _totalSupply,
        _monthUnit,
        __regulationsLaunched,
        _defaultSenderReg
      ] = await Promise.all([
        generalTransferManagerFacet.calculateTotalLockedAmount(_generalTransferManagerModule.moduleAddress, selectedWallet),
        generalTransferManagerFacet.calculateTotalLockedAmountAccredited(_generalTransferManagerModule.moduleAddress, selectedWallet),
        generalTransferManagerFacet.getUserLockedAmountList(_generalTransferManagerModule.moduleAddress, selectedWallet),
        mainFacet.balanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
        whitelistFacet.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
        mainFacet.totalSupply(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        mainFacet.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        mainFacet.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        mainFacet.getDefaultSenderReg(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet)
      ]);


      let _regulationsLaunched = __regulationsLaunched.map(launched => ({
        regulation: launched.regulation,
        dateLaunched: launched.dateLaunched,
        regDTransferableOutsideUSA: launched.regDTransferableOutsideUSA,
        creationTS: launched.creationTS,
        index: launched.index,
        legendRemoved: launched.legendRemoved,
        typeOfSecurity: launched.typeOfSecurity
      }));


      // for(let _regulationLaunched of _regulationsLaunched) {
      //   let _regBalance = await securityTokenService.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet, +_regulationLaunched.index);
      //   _regulationLaunched['balance'] = _regBalance;
      // }

      for (let _regulationLaunched of _regulationsLaunched) {
        let _regBalance = await mainFacet.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet, +_regulationLaunched.index);
        _regulationLaunched['balance'] = _regBalance;
      }

      console.log(_regulationsLaunched);
      console.log(_whitelistedWallet);
      console.log(_userLockedAmountListTemp);


      const _userLockedAmountList = [..._userLockedAmountListTemp].map(data => ({ amount: data.amount, lockPeriod: data.lockPeriod, dateLocked: data.dateLocked })).filter(lockAmount => Date.now() < (+lockAmount.lockPeriod) * 1000);

      _userLockedAmountList.forEach((userLockedAmount, index) => userLockedAmount['key'] = index);

      setTotalLockedAmount(_totalLockedAmount);
      setTotalLockedAmountAccredeted(_totalLockedAmountAccredeted);
      setUserLockedAmountList(_userLockedAmountList);
      setBalance(_balance);
      setGeneralTransferManagerModule(_generalTransferManagerModule);
      setWhitelistedWallet(_whitelistedWallet);
      setTotalSupply(_totalSupply);
      setMonthUnit(_monthUnit);
      setRegulationsLaunched(_regulationsLaunched);
      setDefaultSenderReg(_defaultSenderReg);
      // setRegBalance(_regBalance);

      // if((+_defaultSenderReg.dateLaunched)> 0) {
      // setDefaultSenderRegIndex(_regulationsLaunched.findIndex(regLaunched => regLaunched.creationTS === _defaultSenderReg.creationTS));
      // }

      if (_whitelistedWallet.isAffiliate) {

        // let [
        //   _getAffiliateRule144Params,
        //   _affiliateAmountSentIn3Months,
        //   _affiliateSellLimit,
        //   _affiliateTokenAmountSent,
        //   _affiliateUSDAmountSent,
        //   _USDPrice,
        //   _filedNoticeResponse
        // ] = await Promise.all([
        //   generalTransferManagerService.getAffiliateRule144Params(_generalTransferManagerModule.moduleAddress),
        //   generalTransferManagerService.calculateAffiliateAmountSentIn3Months(_generalTransferManagerModule.moduleAddress, selectedWallet),
        //   generalTransferManagerService.calculateAffiliateSellLimit(_generalTransferManagerModule.moduleAddress),
        //   generalTransferManagerService.calculateAffiliateTokenAmountSent(_generalTransferManagerModule.moduleAddress, selectedWallet),
        //   generalTransferManagerService.calculateAffiliateUSDAmountSent(_generalTransferManagerModule.moduleAddress, selectedWallet),
        //   generalTransferManagerService.USDPrice(_generalTransferManagerModule.moduleAddress),
        //   teamMemberService.getFiledNotice()
        // ]);

        let [
          _getAffiliateRule144Params,
          _affiliateAmountSentIn3Months,
          _affiliateSellLimit,
          _affiliateTokenAmountSent,
          _affiliateUSDAmountSent,
          _USDPrice,
          _filedNoticeResponse
        ] = await Promise.all([
          rule144Facet.getAffiliateRule144Params(_generalTransferManagerModule.moduleAddress),
          generalTransferManagerFacet.calculateAffiliateAmountSentIn3Months(_generalTransferManagerModule.moduleAddress, selectedWallet),
          generalTransferManagerFacet.calculateAffiliateSellLimit(_generalTransferManagerModule.moduleAddress),
          generalTransferManagerFacet.calculateAffiliateTokenAmountSent(_generalTransferManagerModule.moduleAddress, selectedWallet),
          generalTransferManagerFacet.calculateAffiliateUSDAmountSent(_generalTransferManagerModule.moduleAddress, selectedWallet),
          generalTransferManagerFacet.USDPrice(_generalTransferManagerModule.moduleAddress),
          teamMemberService.getFiledNotice()
        ]);



        setMaxAffiliateSellPercentage(_getAffiliateRule144Params[0]);
        setMaxAffiliateAmountMonths(_getAffiliateRule144Params[1]);
        setMaxAffiliateAmount(_getAffiliateRule144Params[2]);
        setMaxAffiliateTokensMonths(_getAffiliateRule144Params[3]);
        setMaxAffiliateTokens(_getAffiliateRule144Params[4]);
        setAffiliateAmountSentIn3Months(_affiliateAmountSentIn3Months);
        setAffiliateSellLimit(_affiliateSellLimit);
        setAffiliateTokenAmountSent(_affiliateTokenAmountSent);
        setAffiliateUSDAmountSent(_affiliateUSDAmountSent);
        setUSDPrice(_USDPrice);
        setFiledNotice(_filedNoticeResponse.data);
      }

      if (selectedWallet.toLowerCase() === _symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()) {

        // let [_USDPrice, _lastUpdateUSDPrice] = await Promise.all([
        //   generalTransferManagerService.USDPrice(_generalTransferManagerModule.moduleAddress),
        //   generalTransferManagerService.lastUpdateUSDPrice(_generalTransferManagerModule.moduleAddress)
        // ]);

        let [_USDPrice, _lastUpdateUSDPrice] = await Promise.all([
          generalTransferManagerFacet.USDPrice(_generalTransferManagerModule.moduleAddress),
          generalTransferManagerFacet.lastUpdateUSDPrice(_generalTransferManagerModule.moduleAddress)
        ]);

        setUSDPrice(_USDPrice);
        setLastUpdateUSDPrice(_lastUpdateUSDPrice);
        // formUSDPrice.setFieldsValue({USDPrice:_USDPrice});
      }

      setLoading(false)

    })();
  }, [userInfo, selectedWallet]);


  const lockFundsColumns = [
    {
      title: 'Release Date',
      dataIndex: 'lockPeriod',
      render: (value: string, record: UserLockedAmount) => (
        <>
          {!whitelistedWallet?.isAccredetedInvestor &&
            <>
              {moment(new BigNumber(value).times(1000).toNumber()).format('LLL')}
            </>
          }

          {whitelistedWallet?.isAccredetedInvestor &&
            <>
              {new BigNumber(record.dateLocked).plus(3 * +monthUnit).times(1000).isGreaterThanOrEqualTo(Date.now()) &&
                `
              ${moment(new BigNumber(record.dateLocked).plus(3 * +monthUnit).times(1000).toNumber()).format('LLL')} if sender is an Accredeted Investor
              ${moment(new BigNumber(value).times(1000).toNumber()).format('LLL')} if sender is NOT an Accredeted Investor
              `
              }
              {new BigNumber(record.dateLocked).plus(3 * +monthUnit).times(1000).isLessThan(Date.now()) &&
                `
              Already released if sender is an Accredeted Investor
              ${moment(new BigNumber(value).times(1000).toNumber()).format('LLL')} if sender is NOT an Accredeted Investor
              `
              }
            </>
          }
        </>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount(value)} ${symbolDetailsAndSTData?.securityTokenData.symbol}`
    },
  ];

  const regBalanceColumns = [
    {
      title: 'Regulation',
      dataIndex: 'regulationName'
    },
    {
      title: 'Launched on',
      dataIndex: 'dateLaunched',
      render: (value: string) => moment(+(value) * 1000).format('LLL')
    },
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      render: (value: string) => `${sharedService.typeOfSecurityName(value)}`
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount(value)} ${tokenSymbol}`
    },
    {
      title: 'Action',
      render: (value, row: RegLaunched) => <Button onClick={() => openTokenTransferModal(row)} type='primary'>REGULATION TRANSFER</Button>
    }
  ];


  const displayTokenAmount = (amount) => {
    return new BigNumber(amount).times(new BigNumber(10).pow(-(symbolDetailsAndSTData?.securityTokenData.decimals as string))).toFixed();
  }



  const transfer = async (formValue, _selectedRegToTransfer: RegLaunched) => {
    const _to = formValue.to;
    const _value = new BigNumber(formValue.amount).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toFixed();
    let response = await sharedService.getDetailByWalletAddress({ walletAddress: _to.toLowerCase() });
    if (response.data === null) {
      notification.error({
        message: 'Token Transfer error',
        description: 'Wallet Address is Not Whitelisted in Our Platform',
      });
      return;
    }
    const { country } = response.data;
    const { approvedByICA } = response.data;
    response = await issuerTokenAdminService.getAllowedCountries({ companyId: userInfo?.company?.id });
    const allowedCountries = response.data;
    if (!approvedByICA) {
      notification.error({
        message: 'Token Transfer error',
        description: 'Wallet Address is Not Whitelisted in Our Platform',
      });
      return;
    }
    if (!allowedCountries.includes(country.toUpperCase())) {
      notification.error({
        message: 'Token Transfer error',
        description: 'User Not Belongs to Allowed Countries for Transfer Exchange',
      });
      return;
    }
    setLoadingCanTransfer(true);

    // const canTransfer = await securityTokenService.canTransfer(
    //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
    //   selectedWallet as string,
    //   _to,
    //   _value,
    //   +(selectedRegToTransfer?.index as string),
    //   selectedERC20
    // );

    // console.log(canTransfer);

    const canTransfer = await generalTransferManagerFacet.canTransfer(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      selectedWallet as string,
      _to,
      _value,
      +(selectedRegToTransfer?.index as string),
      selectedERC20
    );

    setLoadingCanTransfer(false);

    if (!canTransfer[0]) {

      if (canTransfer[1] === 'Token Price outdated. Contact Issuer') {
        // canTransfer[1] = 'Receiver Wallet belongs to affiliate but Token Price is outdated';
        setIsTokenPriceOutdated(true);

      } else if (
        canTransfer[1] === "Can't transfer. Affiliate token limit reached. File a Notice With SEC" ||
        canTransfer[1] === "Can't transfer. Affiliate USD limit reached. File a Notice With SEC"
      ) {
        setNeedsFiledNoticeWithSEC(true);
      }

      notification.error({
        message: 'Token Transfer error',
        description: canTransfer[1],
      });
      return;
    }


    setIsModalVisible(true);
    setIsTokenTransferVisible(false);

    setTransactions([
      { details: 'Sending funds', submitting: true }
    ]);

    try {
      const receipt =
        // reg? 
        // await securityTokenService.regTransfer(
        //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        //   selectedWallet as string,
        //   _to,
        //   _value,
        //   +(selectedRegToTransfer?.index as string),
        // )

        await transferFacet.regTransfer(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          selectedWallet as string,
          _to,
          _value,
          +(selectedRegToTransfer?.index as string),
        )


      // : await securityTokenService.transfer(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   _to,
      //   _value,
      // );

      if (receipt.status) {

        // let [_balance] = await Promise.all([
        //   securityTokenService.balanceOf(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string),
        // ]);

        let [_balance] = await Promise.all([
          mainFacet.balanceOf(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string),
        ]);

        setBalance(_balance);


        // for(let _regulationLaunched of regulationsLaunched) {
        //   let _regBalance = await securityTokenService.regBalanceOf(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string, +_regulationLaunched.index);
        //   _regulationLaunched['balance'] = _regBalance;
        // }

        for (let _regulationLaunched of regulationsLaunched) {
          let _regBalance = await mainFacet.regBalanceOf(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string, +_regulationLaunched.index);
          _regulationLaunched['balance'] = _regBalance;
        }

        setRegulationsLaunched(regulationsLaunched);

      }


      // if(receipt.status && whitelistedWallet?.isAffiliate) {
      //   let [
      //     _getAffiliateRule144Params,
      //     _totalSupply,
      //     _affiliateAmountSentIn3Months,
      //     _affiliateSellLimit,
      //     _affiliateTokenAmountSent,
      //     _affiliateUSDAmountSent,
      //     _whitelistedWallet
      //     // _USDPrice
      //   ] = await Promise.all([
      //     generalTransferManagerService.getAffiliateRule144Params(generalTransferManagerModule?.moduleAddress as string),
      //     securityTokenService.totalSupply(symbolDetailsAndSTData?.securityTokenData.contractAddress as string),
      //     generalTransferManagerService.calculateAffiliateAmountSentIn3Months(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
      //     generalTransferManagerService.calculateAffiliateSellLimit(generalTransferManagerModule?.moduleAddress as string),
      //     generalTransferManagerService.calculateAffiliateTokenAmountSent(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
      //     generalTransferManagerService.calculateAffiliateUSDAmountSent(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
      //     securityTokenService.getWhitelistedWallet(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string),
      //     // generalTransferManagerService.USDPrice(generalTransferManagerModule?.moduleAddress as string)
      //   ]);


      if (receipt.status && whitelistedWallet?.isAffiliate) {
        let [
          _getAffiliateRule144Params,
          _totalSupply,
          _affiliateAmountSentIn3Months,
          _affiliateSellLimit,
          _affiliateTokenAmountSent,
          _affiliateUSDAmountSent,
          _whitelistedWallet
          // _USDPrice
        ] = await Promise.all([
          rule144Facet.getAffiliateRule144Params(generalTransferManagerModule?.moduleAddress as string),
          mainFacet.totalSupply(symbolDetailsAndSTData?.securityTokenData.contractAddress as string),
          generalTransferManagerFacet.calculateAffiliateAmountSentIn3Months(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
          generalTransferManagerFacet.calculateAffiliateSellLimit(generalTransferManagerModule?.moduleAddress as string),
          generalTransferManagerFacet.calculateAffiliateTokenAmountSent(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
          generalTransferManagerFacet.calculateAffiliateUSDAmountSent(generalTransferManagerModule?.moduleAddress as string, selectedWallet as string),
          whitelistFacet.getWhitelistedWallet(symbolDetailsAndSTData?.securityTokenData.contractAddress as string, selectedWallet as string),
          // generalTransferManagerFacet.USDPrice(generalTransferManagerModule?.moduleAddress as string)
        ]);

        setMaxAffiliateSellPercentage(_getAffiliateRule144Params[0]);
        setMaxAffiliateAmountMonths(_getAffiliateRule144Params[1]);
        setMaxAffiliateAmount(_getAffiliateRule144Params[2]);
        setMaxAffiliateTokensMonths(_getAffiliateRule144Params[3]);
        setMaxAffiliateTokens(_getAffiliateRule144Params[4]);
        setTotalSupply(_totalSupply);
        setAffiliateAmountSentIn3Months(_affiliateAmountSentIn3Months);
        setAffiliateSellLimit(_affiliateSellLimit);
        setAffiliateTokenAmountSent(_affiliateTokenAmountSent);
        setAffiliateUSDAmountSent(_affiliateUSDAmountSent);
        setWhitelistedWallet(_whitelistedWallet);

        // setUSDPrice(_USDPrice);
      }

      setTransactions(prev => {
        const _prev = [...prev].map(obj => ({ ...obj }));
        _prev[0].receipt = receipt;
        return _prev;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const _prev = [...prev].map(obj => ({ ...obj }));
      _prev[0].submitting = false;
      return _prev;
    });
  };


  const openTokenTransferModal = async (reg: RegLaunched) => {
    setSelectedRegToTransfer(reg);

    setIsTokenTransferVisible(true);
  }



  const updateUSDPrice = async (formValue) => {
    // formUSDPrice.setFieldsValue({USDPrice: formValue.USDPrice});
    setIsModalVisible(true);

    setTransactions([
      { details: 'Updating Price', submitting: true }
    ]);

    try {

      // const receipt = await generalTransferManagerService.updateUSDPrice(
      //   generalTransferManagerModule?.moduleAddress as string, 
      //   selectedWallet as string,
      //   new BigNumber(formValue.USDPrice).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toFixed()
      // );

      const receipt = await generalTransferManagerFacet.updateUSDPrice(
        generalTransferManagerModule?.moduleAddress as string,
        selectedWallet as string,
        new BigNumber(formValue.USDPrice).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toFixed()
      );

      if (receipt?.status) setLastUpdateUSDPrice(Math.floor(Date.now() / 1000).toString());

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].receipt = receipt;
        return current;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  }




  const requestPriceUpdate = async () => {
    setRequestingPriceUpdate(true);

    try {

      const response = await issuerSuperAdminService.requestPriceUpdate();

      if (response.success) {

        message.success('Token Price update request sent successfully');
        setIsTokenPriceOutdated(false);

      } else {
        message.error(response.error.message);
      }

    } catch (err) {
      console.error(err);
    }

    setRequestingPriceUpdate(false);
  }


  const openDefaultRegModal = () => {
    setDefaultSenderRegIndex(regulationsLaunched.findIndex(regLaunched => regLaunched.creationTS === defaultSenderReg?.creationTS));

    setIsDefaultRegModalVisible(true);
  }



  const submitDefaultSenderReg = async () => {
    const _index = defaultSenderRegIndex;

    console.log('_index:', _index);
    // formUSDPrice.setFieldsValue({USDPrice: formValue.USDPrice});
    setIsModalVisible(true);
    setIsDefaultRegModalVisible(false);

    setTransactions([
      { details: 'Setting default regulation', submitting: true }
    ]);

    try {

      // const receipt = await securityTokenService.setDefaultSenderReg(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string,
      //   _index
      // );

      const receipt = await mainFacet.setDefaultSenderReg(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        _index
      );

      if (receipt?.status) {
        setDefaultSenderReg(regulationsLaunched[_index]);
      }

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].receipt = receipt;
        return current;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  }




  const filedNoticeExists = filedNotice && whitelistedWallet && filedNotice.creationTS > +(whitelistedWallet.dateFiledNoticeRemoved) * 1000;

  const selectedERC20 = (selectedRegToTransfer?.dateLaunched as string) === (defaultSenderReg?.dateLaunched as string);


  return (
    <>
      <br /><br />
      <Row justify="center">
        <Col span={20}>
          {loading &&
            <div style={{ textAlign: 'center' }}>
              <br /><br />
              <Spin size='large' />
            </div>
          }

          {!loading &&
            <>
              <Card>
                <Title level={1} style={{ textAlign: 'center' }}>Token Dashboard</Title>

                {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                  <Result
                    title={`Security Token not deployed`}
                  />
                }

                {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                  <>
                    <div>
                      <Descriptions bordered column={1}>
                        <Descriptions.Item label={'Token address'}>
                          <a
                            style={{ textDecoration: 'underline' }}
                            href={`${etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData?.securityTokenData.contractAddress}`} target="_blank" rel="noopener noreferrer"
                          >
                            {symbolDetailsAndSTData?.securityTokenData.contractAddress}
                          </a>
                        </Descriptions.Item>

                        <Descriptions.Item label={'Total Tokens created'}>
                          {displayTokenAmount(totalSupply)} {tokenSymbol}
                        </Descriptions.Item>

                      </Descriptions>

                      <br />
                      <Title style={{ textAlign: 'center' }} level={3}>Balance</Title>

                      <Table
                        columns={regBalanceColumns}
                        rowKey='regulation'
                        dataSource={regulationsLaunched.map(regLaunched => ({
                          regulation: regLaunched.regulation,
                          dateLaunched: regLaunched.dateLaunched,
                          regDTransferableOutsideUSA: regLaunched.regDTransferableOutsideUSA,
                          creationTS: regLaunched.creationTS,
                          index: regLaunched.index,
                          legendRemoved: regLaunched.legendRemoved,
                          regulationName: sharedService.regOptions.find(opt => opt.shortValue === regLaunched.regulation)?.name,
                          typeOfSecurity: regLaunched.typeOfSecurity,
                          amount: regLaunched['balance'],
                          // amount: regBalance?.[sharedService.regOptions.find(opt => opt.shortValue === regLaunched)?.camelCaseValue as string]
                        }))}
                        pagination={false}
                        summary={() => {
                          return (
                            <>
                              <tr>
                                <th> </th>
                                <th> </th>
                                <th>Total =
                                <br />
                                </th>
                                <th>{displayTokenAmount(balance)} {tokenSymbol}</th>
                                <th>
                                  <Button onClick={() => openTokenTransferModal(defaultSenderReg as RegLaunched)} size='small' type='primary'>
                                    STANDAR (ERC20) TRANSFER
                                </Button>

                                  <Button style={{ marginLeft: '10px', marginTop: '4px' }} onClick={openDefaultRegModal} size='small'>
                                    SET DEFAULT REGULATION
                                </Button>
                                </th>
                              </tr>

                            </>
                          );
                        }}
                      />



                      <br />
                    </div>




                    {isTokenPriceOutdated &&
                      <div style={{ textAlign: 'center' }}>
                        <Button size='large' type='primary' loading={requestingPriceUpdate} onClick={requestPriceUpdate}>
                          TOKEN PRICE IS OUTDATED. REQUEST PRICE UPDATE
                        </Button>
                      </div>
                    }

                    {needsFiledNoticeWithSEC &&
                      <div style={{ textAlign: 'center' }}>
                        {/* {Date.now() < +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 && 
                          <Button size='large' type='primary' disabled>
                            You have to wait until {' '}
                            {moment(new BigNumber(whitelistedWallet?.dateFiledCanBeNoticedAgain as string).times(1000).toNumber()).format('LLL')} {' '}
                            to File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                          </Button>
                        } */}
                        {
                          // Date.now() >= +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 && 
                          <Button size='large' type='primary' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                            File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                          </Button>
                        }
                      </div>
                    }
                  </>

                }
              </Card>

              <br />
              {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <>
                  {selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() &&
                    <Card style={{ textAlign: 'center' }}>
                      <Title level={1} style={{ textAlign: 'center' }}>Token Price</Title>

                      {(Date.now() - (+(lastUpdateUSDPrice as string)) * 1000 > (24 * 3600 * 1000)) &&
                        <div>
                          <Title level={3} style={{ color: 'red', fontWeight: 'bold' }}>Price is outdated</Title>
                          {+(lastUpdateUSDPrice as string) > 0 &&
                            <Title level={4} style={{ color: 'red', fontWeight: 'bold', marginTop: 0 }}>
                              Last Update: {moment(new BigNumber(lastUpdateUSDPrice as string).times(1000).toNumber()).format('LLL')}
                            </Title>
                          }
                        </div>
                      }

                      <Form form={formUSDPrice} initialValues={{ USDPrice: displayTokenAmount(USDPrice) }} autoComplete={'off'} onFinish={updateUSDPrice}>
                        <p style={{ display: 'inline' }}>1 USD = </p>

                        <Form.Item wrapperCol={{ span: 18, offset: 3 }}
                          name='USDPrice'
                          noStyle
                          normalize={(value: any, prevValue: any, allValues) => {
                            if (new BigNumber(value).isGreaterThan(0)) return value;
                            if (!value) return '';
                            return prevValue || '';
                          }}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true,
                            }
                          ]}
                        >
                          <Input placeholder='Price' style={{ display: 'inline-block', width: '110px', margin: '0 10px' }} />
                        </Form.Item>

                        <p style={{ display: 'inline' }}>{tokenSymbol}</p>

                        <br /><br />

                        <div style={{ textAlign: 'center' }}>
                          <Button size='large' type='primary' htmlType="submit" loading={false}>
                            UPDATE
                          </Button>
                        </div>
                      </Form>
                    </Card>
                  }

                  <br />

                  {whitelistedWallet?.isAffiliate &&
                    <Card>
                      <Title level={1} style={{ textAlign: 'center' }}>Affiliate Rule 144</Title>
                      <Descriptions bordered column={1} title="Cond. 3: Trading Volume Formula (Equity Tokens only)">
                        <Descriptions.Item label="Total existing coins (Total supply)">
                          {displayTokenAmount(totalSupply)} {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item label="You cannot sell in any three months more than">
                          {displayTokenAmount(affiliateSellLimit)} {tokenSymbol} {' '}
                          ({new BigNumber(maxAffiliateSellPercentage as string).div(new BigNumber(10).pow(precision)).decimalPlaces(4).toFixed()}% of total supply)
                        </Descriptions.Item>
                        <Descriptions.Item label="Total sent in last three months">
                          {displayTokenAmount(affiliateAmountSentIn3Months)} {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item label="You can still send up to">
                          {displayTokenAmount(
                            new BigNumber(affiliateSellLimit as string).minus(affiliateAmountSentIn3Months as string).isGreaterThan(0) ?
                              new BigNumber(affiliateSellLimit as string).minus(affiliateAmountSentIn3Months as string) : 0
                          )} {tokenSymbol}
                        </Descriptions.Item>
                      </Descriptions>
                      <br />

                      {!whitelistedWallet.isFiledNoticeApproved &&
                        <>
                          <Descriptions bordered column={1} title="Cond. 5: Filing a Notice of Proposed Sale with the SEC">
                            <Descriptions.Item label="Token Price">
                              1 USD = {displayTokenAmount(USDPrice)} {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item label={`You cannot send in ${maxAffiliateAmountMonths} months more than`}>
                              {maxAffiliateAmount} USD
                              ({displayTokenAmount(new BigNumber(maxAffiliateAmount as string).times(USDPrice as string))} {tokenSymbol} {' '}
                              according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item label={`Total sent in ${maxAffiliateAmountMonths} months`}>
                              {affiliateUSDAmountSent} USD
                              ({displayTokenAmount(new BigNumber(affiliateUSDAmountSent as string).times(USDPrice as string))} {tokenSymbol} {' '}
                              according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item label={`You can still send in ${maxAffiliateAmountMonths} months up to`}>
                              {new BigNumber(maxAffiliateAmount as string).minus(affiliateUSDAmountSent as string).isGreaterThan(0) ?
                                new BigNumber(maxAffiliateAmount as string).minus(affiliateUSDAmountSent as string).toFixed() : 0
                              } USD
                              ({displayTokenAmount(
                                new BigNumber(maxAffiliateAmount as string).minus(affiliateUSDAmountSent as string).times(USDPrice as string).isGreaterThan(0) ?
                                  new BigNumber(maxAffiliateAmount as string).minus(affiliateUSDAmountSent as string).times(USDPrice as string) : 0
                              )} {tokenSymbol} {' '}
                              according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item label={`You cannot send in ${maxAffiliateTokensMonths} months more than`}>
                              {displayTokenAmount(maxAffiliateTokens)} {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item label={`Total sent in ${maxAffiliateTokensMonths} months`}>
                              {displayTokenAmount(affiliateTokenAmountSent)} {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item label={`You can still send in ${maxAffiliateTokensMonths} months up to`}>
                              {displayTokenAmount(
                                new BigNumber(maxAffiliateTokens as string).minus(affiliateTokenAmountSent as string).isGreaterThan(0) ?
                                  new BigNumber(maxAffiliateTokens as string).minus(affiliateTokenAmountSent as string) : 0
                              )} {tokenSymbol}
                            </Descriptions.Item>
                          </Descriptions>

                          {/* {!filedNotice && 
                            <Button size='large' style={{fontWeight:'bold'}} type='link' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                              File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                            </Button>
                          } */}
                          {filedNoticeExists &&
                            <>
                              {!filedNotice?.isRejected &&
                                <Button size='large' style={{ fontWeight: 'bold' }} type='link' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                                  Filed Notice Request is pending
                                </Button>
                              }
                              {filedNotice?.isRejected &&
                                <Button size='large' style={{ fontWeight: 'bold' }} type='link' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                                  Your Filed Notice has been Rejected. Click here to edit it
                                </Button>
                              }
                            </>
                          }
                        </>
                      }

                      {whitelistedWallet.isFiledNoticeApproved &&
                        <Button size='large' style={{ fontWeight: 'bold' }} type='link' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                          Your Filed notice is approved
                        </Button>
                      }

                    </Card>
                  }
                  <Card>
                    <Title level={1} style={{ textAlign: 'center' }}>Locked funds</Title>
                    <Table
                      columns={lockFundsColumns}
                      dataSource={userLockedAmountList}
                      pagination={false}
                      summary={() => {
                        return (
                          <>
                            <tr>
                              <td></td>
                              <th>Total Locked =
                              <br />
                                {(!whitelistedWallet?.isAccredetedInvestor || new BigNumber(totalLockedAmount as string).isEqualTo(totalLockedAmountAccredeted as string)) &&
                                  <>
                                    {displayTokenAmount(totalLockedAmount)} {tokenSymbol}
                                  </>
                                }

                                {(whitelistedWallet?.isAccredetedInvestor && !new BigNumber(totalLockedAmount as string).isEqualTo(totalLockedAmountAccredeted as string)) &&
                                  <>
                                    {displayTokenAmount(totalLockedAmountAccredeted)} {tokenSymbol} if sender is an Accredeted Investor
                                  <br />
                                    {displayTokenAmount(totalLockedAmount)} {tokenSymbol} if sender is NOT an Accredeted Investor
                                </>
                                }
                              </th>
                            </tr>

                          </>
                        );
                      }}
                    />
                  </Card>
                </>
              }

              <br /><br />
            </>

          }
        </Col>
      </Row>



      <Modal
        title={`
        ${selectedERC20 ? 'ERC20 Transfer' : sharedService.regOptions.find(opt => opt.shortValue === selectedRegToTransfer?.regulation)?.name}
        
        `}
        okText='TRANSFER'
        cancelText='Cancel'
        visible={isTokenTransferVisible}
        onOk={() => form.submit()}
        okButtonProps={{ loading: loadingCanTransfer, disabled: !(+(selectedRegToTransfer?.dateLaunched as string) > 0) }}
        onCancel={() => { setIsTokenTransferVisible(false); form.resetFields() }}
      >

        {selectedERC20 &&
          <>
            <p style={{ fontWeight: 'bold' }}>ERC20 Transfer behaviour</p>


            {!(+(selectedRegToTransfer?.dateLaunched as string) > 0) &&

              <p>You have to set a Default Regulation</p>

            }


            {+(selectedRegToTransfer?.dateLaunched as string) > 0 &&
              <>
                <p>
                  Default Regulation will be used for the transfer: {sharedService.regOptions.find(opt => opt.shortValue === selectedRegToTransfer?.regulation)?.name}
                </p>
                {/* <p>
                If receiver is USA person or {sharedService.regOptions.find(opt => opt.value === 'regulation-d')?.name} is allowed to be transfered from US to Non US,
                {' '} {sharedService.regOptions.find(opt => opt.value === 'regulation-d')?.name} will be used by default.
              </p>
              
              <p>
                Otherwise, {sharedService.regOptions.find(opt => opt.value === 'regulation-s')?.name} will be used.
              </p>
              <br/> */}

              </>
            }


          </>
        }

        {(!selectedERC20 || (+(selectedRegToTransfer?.dateLaunched as string) > 0)) &&

          // whitelist -> is kyc completed .

          <Form form={form} autoComplete={'off'} onFinish={(formValue) => whitelistedWallet?.isAffiliate ? setIsAffiliateModalVisible(true) : setIsAffiliateModalVisible(true)}>

            <Form.Item wrapperCol={{ span: 18, offset: 3 }}
              name='to'
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  validator: (rule, value) => {
                    if (value && !sharedService.isEthereumAddress(value)) return Promise.reject("Enter a valid Address");
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input placeholder='Receiver' />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 18, offset: 3 }}
              name='amount'
              normalize={(value: any, prevValue: any, allValues) => {
                // console.log(value, prevValue, allValues);
                if (new BigNumber(value).isGreaterThan(0)) return value;
                if (!value) return '';
                return prevValue || '';
              }}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                }
              ]}
            >
              <Input placeholder='Amount' />
            </Form.Item>

            <br />

          </Form>

        }

      </Modal>


      <TransactionModal
        title={transactions[0]?.details === 'Updating Price' ? 'Price update' : 'Token Transfer'}
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />


      <Modal
        title={'Rule 144: Condition 4: Ordinary Brokerage Transaction'}
        okText='Confirm'
        cancelText='Cancel'
        visible={isAffiliateModalVisible}
        onOk={() => affiliateForm.submit()}
        onCancel={() => { setIsAffiliateModalVisible(false); affiliateForm.resetFields() }}
      >

        <p>
          The <span style={{ fontWeight: 'bold' }}>Affiliate</span> has to agree to all the 3 conditions of Ordinary Brokerage Transaction as given below, before transferring or selling the securities.
        </p>


        <Form form={affiliateForm} labelAlign='right' onFinish={() => { setIsAffiliateModalVisible(false); transfer(form.getFieldsValue(), selectedRegToTransfer as RegLaunched) }}>
          {affiliateFormCheckboxesOpts.map((opt, index) => (
            <Form.Item
              key={index}
              name={`opt${index}`}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (rule, value) => {
                    if (value === false) return Promise.reject('This field is required');
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Checkbox>{opt}</Checkbox>
            </Form.Item>
          ))}
        </Form>

      </Modal>


      <Modal
        visible={isDefaultRegModalVisible}
        onOk={submitDefaultSenderReg}
        okButtonProps={{ disabled: !(defaultSenderRegIndex >= 0) }}
        onCancel={() => setIsDefaultRegModalVisible(false)}
        title='Set default regulation for ERC20 Transfers'>

        <Radio.Group value={defaultSenderRegIndex} onChange={(e) => setDefaultSenderRegIndex(e.target.value)} >
          {regulationsLaunched.map((launched, index) => (
            <Radio value={index} key={index}>
              <span style={{ fontWeight: 'bold' }}>
                {sharedService.regOptions.find(opt => opt.shortValue === launched?.regulation)?.name}
              </span>
              {' - '}
              Launched on: {moment((+launched.dateLaunched) * 1000).format('LLL')}
            </Radio>
          ))}
        </Radio.Group>

      </Modal>




    </>
  );
}