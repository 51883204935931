import React from 'react';
import {Tabs,Card} from 'antd';
import Dashboard from './Dashboard'
import Subscription from './Subscription'
const { TabPane } = Tabs;
export default class Demo extends React.Component {
render () {
    return (<>
    <Card>
     <Tabs defaultActiveKey="1" >
          <TabPane tab="Dashboard" key="1">
          <Dashboard></Dashboard>
          </TabPane>
          <TabPane tab="Subscription" key="2">
            <Subscription></Subscription>
          </TabPane>
        </Tabs>
    </Card>
    </>)
}
}
