import React, { useState, useEffect } from 'react'
import { Input, Button, Row, Col, Form, Typography, InputNumber, Card, Progress, Tabs, Checkbox, Result, Spin, notification  } from 'antd';
import { SharedService } from '../../../Shared/Shared.service';
// import { IssuerSuperAdminService } from '../../IssuerSuperAdmin.service';
import { APIResponse, TokenConfigurationProcess, Rule144Config, User, SymbolDetailsAndSTData } from '../../../Shared/interfaces';
import { LinkOutlined } from '@ant-design/icons';
import { TokenConfigurationService } from '../../TokenConfiguration.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';

// import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet/index'

const {Text} = Typography;
const { TabPane } = Tabs;

const sharedService = new SharedService();
// const issuerSuperAdminService = new IssuerSuperAdminService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
// const securityTokenService = new SecurityTokenService();
const mainFacet = new MainFacet();


const publicDocsForm = (rule144Config, rule144Files, uploadFile) => {
  return (
    <>
      <Text style={{fontWeight: 'bold'}}>2. Public Documents</Text>
      <p>
        As part of Rule 144 for an Affiliate, Issuer must upload all the required documents which will be reviewed by the Issuer Counsel 
        and Approve those documents then only transfer is allowed. Generally, in the first 12 months there is a lock but before this lock 
        is opened this requirement must be satisfied.
      </p>

      <Form.Item
        label="Upload Nature of Business Document"
        name='natureOfBusinessFile'
        labelCol={{span: 14}}
        wrapperCol={{span: 10}}
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}>
        <>
          {rule144Config?.natureOfBusinessFile.name && 
            <>
              <span style={{color:'green', fontWeight:'bold'}}>Saved: </span>
              <a 
                href={rule144Config?.natureOfBusinessFile.URL} 
                download={rule144Config?.natureOfBusinessFile.name} 
                target="_blank" rel="noopener noreferrer"> {rule144Config?.natureOfBusinessFile.name}
              </a>
            </>
            // <span style={{fontWeight:'bold', color:'green', textAlign:'left', display: 'block'}}>
            //   Saved: {rule144Config.natureOfBusinessFile.name}
            // </span>
          }
          <Input type='file' onChange={e => { uploadFile(e, 'natureOfBusinessFile') }} />
          {rule144Files.natureOfBusinessFile.uploadFilePercentage>0 && 
            <Progress percent={rule144Files.natureOfBusinessFile.uploadFilePercentage} />
          }
        </>
      </Form.Item>

      <Form.Item
        label="Upload Identity of Officers and Directors' Document"
        labelCol={{span: 14}}
        wrapperCol={{span: 10}}
        name='idOfOfficersAndDirectorsFile'
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}>
        <>
          {rule144Config?.idOfOfficersAndDirectorsFile.name && 
            <>
              <span style={{color:'green', fontWeight:'bold'}}>Saved: </span>
              <a 
                href={rule144Config?.idOfOfficersAndDirectorsFile.URL} 
                download={rule144Config?.idOfOfficersAndDirectorsFile.name} 
                target="_blank" rel="noopener noreferrer"> {rule144Config?.idOfOfficersAndDirectorsFile.name}
              </a>
            </>
            // <span style={{fontWeight:'bold', color:'green', textAlign:'left', display: 'block'}}>
            //   Saved: {rule144Config.idOfOfficersAndDirectorsFile.name}
            // </span>
          }
          <Input type='file' onChange={e => { uploadFile(e, 'idOfOfficersAndDirectorsFile') }} />
          {rule144Files.idOfOfficersAndDirectorsFile.uploadFilePercentage>0 && 
            <Progress percent={rule144Files.idOfOfficersAndDirectorsFile.uploadFilePercentage} />
          }
        </>
      </Form.Item>

      <Form.Item
        label="Upload Financial Statements"
        name='financialStatementsFile'
        labelCol={{span: 14}}
        wrapperCol={{span: 10}}
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}>
        <>
          {rule144Config?.financialStatementsFile.name && 
            <>
              <span style={{color:'green', fontWeight:'bold'}}>Saved: </span>
              <a 
                href={rule144Config?.financialStatementsFile.URL} 
                download={rule144Config?.financialStatementsFile.name} 
                target="_blank" rel="noopener noreferrer"> {rule144Config?.financialStatementsFile.name}
              </a>
            </>
            // <span style={{fontWeight:'bold', color:'green', textAlign:'left', display: 'block'}}>
            //   Saved: {rule144Config.financialStatementsFile.name}
            // </span>
          }
          <Input type='file' onChange={e => { uploadFile(e, 'financialStatementsFile') }} />
          {rule144Files.financialStatementsFile.uploadFilePercentage>0 && 
            <Progress percent={rule144Files.financialStatementsFile.uploadFilePercentage} />
          }
        </>
      </Form.Item>
    </>
  )
}


export default (
  {saveRule144Config, sendRequestToCreateLegend, addLegend, tokenConfigurationProcess, userInfo, isRelaunch}: 
  {saveRule144Config: any, sendRequestToCreateLegend: any, addLegend:any, tokenConfigurationProcess:TokenConfigurationProcess | undefined, userInfo: User, isRelaunch: boolean}) => {
  const [submitting, setSubmitting] = useState<boolean>();

  const [rule144Config, setRule144Config] = useState<Rule144Config>();
  const [prevRule144Config, setPrevRule144Config] = useState<Rule144Config>();

  const [loading, setLoading] = useState(true);

  const [requestToCreateLegendSent, setRequestToCreateLegendSent] = useState<boolean>();

  const [addLegengChecked, setAddLegengChecked] = useState<boolean>();

  const [monthUnit, setMonthUnit] = useState('');
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [legendRemoved, setLegendRemoved] = useState<boolean>();

  const [form] = Form.useForm();

  const [rule144Files, setRule144Files] = useState(
    {
      natureOfBusinessFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      idOfOfficersAndDirectorsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      financialStatementsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
    }
  );

  useEffect(() => {
    (async () => {
      if(!tokenConfigurationProcess?.rule144ConfigId) {
        if(isRelaunch) {
          const _prevRule144Config = (await tokenConfigurationService.getPreviousRule144Config()).data as Rule144Config;
          setPrevRule144Config(_prevRule144Config);
          console.log(_prevRule144Config);
        }

        setLoading(false);
        return;
      }
      
      const response = await tokenConfigurationService.getRule144Config(tokenConfigurationProcess.rule144ConfigId);
      const _rule144Config: Rule144Config = response.data;
      
      const _rule144Files: any = {
        natureOfBusinessFile: {..._rule144Config.natureOfBusinessFile, uploading: false, uploadFilePercentage: 0}, 
        idOfOfficersAndDirectorsFile: {..._rule144Config.idOfOfficersAndDirectorsFile, uploading: false, uploadFilePercentage: 0},
        financialStatementsFile: {..._rule144Config.financialStatementsFile, uploading: false, uploadFilePercentage: 0}
      };
      
      setRule144Config(_rule144Config);
      setRule144Files(_rule144Files);

      form.setFieldsValue({
        natureOfBusinessFile: _rule144Files.natureOfBusinessFile.name,
        idOfOfficersAndDirectorsFile: _rule144Files.idOfOfficersAndDirectorsFile.name,
        financialStatementsFile: _rule144Files.financialStatementsFile.name,
      });

      if(tokenConfigurationProcess.tokenSymbol) {
      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

        if(_symbolDetailsAndSTData?.symbolDetails.isDeployed) {
          // const [_monthUnit, _legendRemoved] = await Promise.all([
          //   securityTokenService.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
          //   securityTokenService.legendRemoved(_symbolDetailsAndSTData.securityTokenData.contractAddress, tokenConfigurationProcess.creationTS)
          // ]);
          const [_monthUnit, _legendRemoved] = await Promise.all([
            mainFacet.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
            mainFacet.legendRemoved(_symbolDetailsAndSTData.securityTokenData.contractAddress, tokenConfigurationProcess.creationTS)
          ]);
          setMonthUnit(_monthUnit);
          setLegendRemoved(_legendRemoved);
        }
      }

      setLoading(false);
      
    })();
  }, [tokenConfigurationProcess, form, isRelaunch]);

  useEffect(() => {
    (async () => {
      form.setFieldsValue({
        "lockPeriod":12,
        "maxAffiliateSellPercentage":1,
        "maxAffiliateAmountMonths":3,
        "maxAffiliateAmount":50000,
        "maxAffiliateTokensMonths":3,
        "maxAffiliateTokens":5000
      })
    })();
  }, [tokenConfigurationProcess]);


  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if(!e.target.files) return;

    const file =  e.target.files[0];
    console.log(file);

    if(!file) return;
    
    // e.target.value = '';
    
    const fileType = sharedService.getFileType(file.name);

    
    setRule144Files(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      current[key].uploadFilePercentage = 0;
      return current;
    })

    tokenConfigurationService.uploadFile(file, (uploadFilePercent: number) => {
      setRule144Files((prev) => {
        console.log(uploadFilePercent);
        return ({...prev, [key]: {...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent}});
      });

    }, (err) => {
      console.error(err);
      setRule144Files((prev) => ({ ...prev, [key]: {...prev[key], uploading: false}}));
      
    }, async(response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        setRule144Files((prev) => ({...prev, [key]: {...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase()}}));
        form.setFieldsValue({[key]: file.name});
        
      } else {
        console.error(response.error);
        setRule144Files((prev) => ({ ...prev, [key]: {...prev[key], uploading: false}}));
      }

    });

  }


  const _saveRule144Config = async(formValue) => {
    const req = {...formValue};

    req.natureOfBusinessFile = {
      name: rule144Files.natureOfBusinessFile.name,
      URL: rule144Files.natureOfBusinessFile.URL,
      type: rule144Files.natureOfBusinessFile.type,
    };
    req.idOfOfficersAndDirectorsFile = {
      name: rule144Files.idOfOfficersAndDirectorsFile.name,
      URL: rule144Files.idOfOfficersAndDirectorsFile.URL,
      type: rule144Files.idOfOfficersAndDirectorsFile.type,
    };
    req.financialStatementsFile = {
      name: rule144Files.financialStatementsFile.name,
      URL: rule144Files.financialStatementsFile.URL,
      type: rule144Files.financialStatementsFile.type,
    };

    req.filesFinancialDate = Date.now();
    
    req.tokenConfigurationProcessId = tokenConfigurationProcess?._id;

    setSubmitting(true);
    console.log(req);
    await saveRule144Config(req);
    setSubmitting(false);
  };

  const submitPublicDocs = async(formValue) => {
    const req = {...formValue};

    req.natureOfBusinessFile = {
      name: rule144Files.natureOfBusinessFile.name,
      URL: rule144Files.natureOfBusinessFile.URL,
      type: rule144Files.natureOfBusinessFile.type,
    };
    req.idOfOfficersAndDirectorsFile = {
      name: rule144Files.idOfOfficersAndDirectorsFile.name,
      URL: rule144Files.idOfOfficersAndDirectorsFile.URL,
      type: rule144Files.idOfOfficersAndDirectorsFile.type,
    };
    req.financialStatementsFile = {
      name: rule144Files.financialStatementsFile.name,
      URL: rule144Files.financialStatementsFile.URL,
      type: rule144Files.financialStatementsFile.type,
    };

    req.filesFinancialDate = Date.now();
    
    req.rule144ConfigId = tokenConfigurationProcess?.rule144ConfigId;


    setSubmitting(true);

    const response = await tokenConfigurationService.submitPublicDocs(req);

    if (response.success) {
      setRule144Config(prev => {
        const current = sharedService.clone(prev) as Rule144Config;
        
        current.natureOfBusinessFile = req.natureOfBusinessFile;
        current.idOfOfficersAndDirectorsFile = req.idOfOfficersAndDirectorsFile;
        current.financialStatementsFile = req.financialStatementsFile;
        current.filesFinancialDate = req.filesFinancialDate;
        return current;
      });

      notification.success({message: 'SUCCESS', description: 'Documents updated'});
      
    } else {
      
    }

    setSubmitting(false);


  }




  const _sendRequestToCreateLegend = async() => {
    setSubmitting(true);

    const response = await sendRequestToCreateLegend();
    if (response.success) setRequestToCreateLegendSent(true);

    setSubmitting(false);
  }


  const _addLegend = async() => {
    setSubmitting(true);

    await addLegend();

    setSubmitting(false);
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
         
          {loading && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          }


          {!loading && 
            <Tabs type='card' size='large' defaultActiveKey='1' style={{textAlign:'center'}}>

              <TabPane tab={'Rule 144 Configurations'} key='1'>

                {
                (
                  ['issuer token admin', 'issuer super admin'].includes(userInfo.role) && 
                  tokenConfigurationProcess?.status !== 'verified'
                ) &&
                // (
                //   !tokenConfigurationProcess?.rule144ConfigId || 
                //   (rule144Config && 
                //     (
                //       (userInfo.role === 'issuer token admin' && tokenConfigurationProcess?.status === 'rejected issuer super admin') || 
                //       (userInfo.role === 'issuer super admin' && ['pending issuer super admin', 'rejected issuer company officer'].includes(tokenConfigurationProcess?.status as string))
                //     ) 
                //   )
                // ) && 
                  <>
                    <Form 
                      labelAlign="left" 
                      form={form} 
                      initialValues={
                        rule144Config || 
                        (isRelaunch? 
                          {
                            maxAffiliateSellPercentage: +(prevRule144Config?.maxAffiliateSellPercentage as string),
                            maxAffiliateAmountMonths: prevRule144Config?.maxAffiliateAmountMonths,
                            maxAffiliateAmount: +(prevRule144Config?.maxAffiliateAmount as string),
                            maxAffiliateTokensMonths: prevRule144Config?.maxAffiliateTokensMonths,
                            maxAffiliateTokens: +(prevRule144Config?.maxAffiliateTokens as string)
                          } : 
                          {})} 
                      onFinish={_saveRule144Config}
                    >

                      <Card style={{borderColor:'darkgrey'}}>
                        <Text style={{fontWeight: 'bold', display: 'block'}}>1. Holding period</Text>
                        <p style={{display:'inline'}}>Lock Period for Investor</p>

                        <Form.Item 
                          noStyle
                          name="lockPeriod"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            }
                          ]}>
                          <InputNumber min={12} max={12} style={{display:'inline-block', width: '110px', margin: '0 10px'}}/>
                        </Form.Item>
                        
                        <p style={{display:'inline'}}>months</p>
                      </Card>
                      <br/>

                      <Card style={{borderColor:'darkgrey'}}>
                        {publicDocsForm(rule144Config, rule144Files, uploadFile)}
                      </Card>
                      <br/>

                      <Card style={{borderColor:'darkgrey'}}>
                        <Text style={{fontWeight: 'bold', display: 'block'}}>3. Trading Volume Formula</Text>

                        <p style={{display:'inline'}}>
                          Number of Equity Securities Affiliate may sell during any three-month period cannot exceed the greater of
                        </p>

                        <Form.Item 
                          name="maxAffiliateSellPercentage"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <InputNumber disabled={isRelaunch} min={1} max={1} style={{display:'inline-block', width: '110px', margin: '0 10px'}}/>
                        </Form.Item>

                        <p style={{display:'inline'}}> % of the outstanding shares of the same class being sold</p>
                      </Card>
                      <br/>


                      <Card style={{borderColor:'darkgrey'}}>
                        <Text style={{fontWeight: 'bold'}}>4. Ordinary Brokerage Transaction</Text>

                        <br/>
                        <Checkbox checked disabled/>{' '}
                        If you are an Affiliate, the sales must be handled in all respect as routing trading transactions, and brokers may not
                        receive more than a normal commission. Neither the seller nor the broker can solicit orders to buy the securities
                      </Card>
                      <br/>


                      <Card style={{borderColor:'darkgrey'}}>
                        <Text style={{fontWeight: 'bold', display: 'block'}}>5. Filing a notice of Proposed Sale With the SEC</Text>
                        <p style={{display:'inline'}}> Max allowed amount for affiliate in </p>

                        <Form.Item 
                          name="maxAffiliateAmountMonths"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <InputNumber disabled={isRelaunch} min={3} max={3} style={{display:'inline-block', width: '110px', margin: '0 10px'}}/>
                        </Form.Item>

                        <p style={{display:'inline'}}>months is $ </p>
                        <Form.Item 
                          name="maxAffiliateAmount"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <InputNumber disabled={isRelaunch} min={1} max={50000} style={{width: '110px', margin: '0 10px'}}/>
                        </Form.Item>
                        <br/><br/>
                        
                        <p style={{display:'inline'}}> Max allowed number of tokens for affiliate in </p>

                        <Form.Item 
                          name="maxAffiliateTokensMonths"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <InputNumber disabled={isRelaunch} min={3} max={3} style={{display:'inline-block', width: '110px', margin: '0 10px'}}/>
                        </Form.Item>

                        <p style={{display:'inline'}}> months is </p>

                        <Form.Item 
                          name="maxAffiliateTokens"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <InputNumber disabled={isRelaunch} min={1} max={5000} style={{width: '110px', margin: '0 10px'}}/>
                        </Form.Item>

                      </Card>
                      <br/><br/>

                      <Form.Item
                        wrapperCol = {{
                          xs: { span: 24, offset: 0 },
                          sm: { span: 17, offset: 7 }
                        }}
                      >
                        <Button size='large' type='primary' htmlType="submit" loading={submitting}>
                          SAVE RULE 144 CONFIGURATIONS
                        </Button>
                      </Form.Item>

                  </Form>                  
                  </>
                }

                {
                (
                  !['issuer token admin', 'issuer super admin'].includes(userInfo.role) || 
                  tokenConfigurationProcess?.status === 'verified'
                ) && rule144Config && 
                // (
                //   tokenConfigurationProcess?.rule144ConfigId && rule144Config &&
                //   (userInfo.role !== 'issuer token admin' || tokenConfigurationProcess.status !== 'rejected issuer super admin') &&  
                //   (userInfo.role !== 'issuer super admin' || !['pending issuer super admin', 'rejected issuer company officer'].includes(tokenConfigurationProcess.status))
                // ) && 
                  <>
                    <Card style={{borderColor:'darkgrey'}}>
                      <Text style={{fontWeight: 'bold', display: 'block'}}>1. Holding period</Text>
                      <p style={{display:'inline'}}>Lock Period for Investor</p>

                      <p style={{display:'inline', fontWeight:'bold'}}>: {rule144Config?.lockPeriod} months</p>
                    </Card>
                    <br/>


                  {(
                    userInfo.role !== 'issuer token admin' || 
                    (
                      !symbolDetailsAndSTData?.symbolDetails.isDeployed || 
                      (Date.now() - new Date(rule144Config.filesFinancialDate).getTime() < 12*(+monthUnit)*1000)
                    )

                  ) && 

                    <Card style={{borderColor:'darkgrey'}}>
                      <Text style={{fontWeight: 'bold'}}>2. Public Documents</Text>
                      <p>
                      As part of Rule 144 for an Affiliate, Issuer must upload all the required documents which will be reviewed by the Issuer Counsel 
                      and Approve those documents then only transfer is allowed. Generally, in the first 12 months there is a lock but before this lock 
                      is opened this requirement must be satisfied.
                      </p>

                      <Row>
                        <Col span={14}>Nature of Business Document:</Col>
                        <Col span={10}>
                          <a 
                            href={rule144Config?.natureOfBusinessFile.URL} 
                            download={rule144Config?.natureOfBusinessFile.name} 
                            target="_blank" rel="noopener noreferrer"> {rule144Config?.natureOfBusinessFile.name}
                          </a>
                        </Col>
                      </Row>
                        
                      <Row>
                        <Col span={14}>Identity of Officers and Directors' Document:</Col>
                        <Col span={10}>
                          <a 
                            href={rule144Config?.natureOfBusinessFile.URL} 
                            download={rule144Config?.idOfOfficersAndDirectorsFile.name} 
                            target="_blank" rel="noopener noreferrer"> {rule144Config?.idOfOfficersAndDirectorsFile.name}
                          </a>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={14}>Financial Statements:</Col>
                        <Col span={10}>
                          <a 
                            href={rule144Config?.financialStatementsFile.URL} 
                            download={rule144Config?.financialStatementsFile.name} 
                            target="_blank" rel="noopener noreferrer"> {rule144Config?.financialStatementsFile.name}
                          </a>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={14}>Financial Year for provided Documents: {new Date(rule144Config.filesFinancialDate).getFullYear()}</Col>
                      </Row>

                    </Card>
                  }


                  {(
                    userInfo.role === 'issuer token admin' && 
                    (
                      symbolDetailsAndSTData?.symbolDetails.isDeployed && 
                      (Date.now() - new Date(rule144Config.filesFinancialDate).getTime() >= 12*(+monthUnit)*1000)
                    )
                  ) && 
                  
                    <Card style={{borderColor:'darkgrey'}}>
                      <Form 
                        labelAlign="left" 
                        form={form} 
                        onFinish={submitPublicDocs}
                      >

                        {publicDocsForm(rule144Config, rule144Files, uploadFile)}


                        <p style={{fontWeight: 'bold', color: 'red'}}>
                          Documents are outdated
                        </p>

                        <Form.Item
                          wrapperCol = {{
                            xs: { span: 24, offset: 0 },
                            sm: { span: 20, offset: 4 }
                          }}
                          >
                          <Button size='large' type='primary' htmlType="submit" loading={submitting}>
                            SUBMIT
                          </Button>
                        </Form.Item>
                      </Form>
                    </Card>
                  }


                    <br/>


                    <Card style={{borderColor:'darkgrey'}}>
                      <Text style={{fontWeight: 'bold', display: 'block'}}>3. Trading Volume Formula</Text>

                      <p style={{display:'inline'}}>
                        Number of Equity Securities Affiliate may sell during any three-month period cannot exceed the greater of
                      </p>

                      <p style={{display:'inline', fontWeight:'bold'}}> {rule144Config?.maxAffiliateSellPercentage}</p>

                      <p style={{display:'inline'}}> % of the outstanding shares of the same class being sold</p>
                    </Card>
                    <br/>


                    <Card style={{borderColor:'darkgrey'}}>
                      <Text style={{fontWeight: 'bold'}}>4. Ordinary Brokerage Transaction</Text>

                      <p>
                        If you are an Affiliate, the sales must be handled in all respect as routing trading transactions, and brokers may not
                        receive more than a normal commission. Neither the seller nor the broker can solicit orders to buy the securities
                      </p>
                    </Card>
                    <br/>

                    <Card style={{borderColor:'darkgrey'}}>
                      <Text style={{fontWeight: 'bold', display: 'block'}}>5. Filing a notice of Proposed Sale With the SEC</Text>
                      <p style={{display:'inline'}}> Max allowed amount for affiliate in </p>

                      <p style={{display:'inline', fontWeight:'bold'}}> {rule144Config?.maxAffiliateAmountMonths} </p>

                      <p style={{display:'inline'}}>months is $ </p>

                      <p style={{display:'inline', fontWeight:'bold'}}>: {rule144Config?.maxAffiliateAmount}</p>

                      <br/><br/>

                      <p style={{display:'inline'}}> Max allowed number of tokens for affiliate in </p>

                      <p style={{display:'inline', fontWeight:'bold'}}> {rule144Config?.maxAffiliateTokensMonths} </p>

                      <p style={{display:'inline'}}> months is </p>

                      <p style={{display:'inline', fontWeight:'bold'}}>: {rule144Config?.maxAffiliateTokens}</p>

                    </Card>
                    <br/><br/>

                  </>
                }

              </TabPane>


              <TabPane tab={'Add a Legend'} key='2'>
                {
                !tokenConfigurationProcess?.legendCreationFile && 
                  <div style={{textAlign:'center'}}>
                    {!requestToCreateLegendSent && 
                      <Button size='large' type='primary' loading={submitting}  onClick={_sendRequestToCreateLegend}>
                        SEND REQUEST TO CREATE LEGEND
                      </Button>
                    }
                    {requestToCreateLegendSent && 
                      <>
                        <Result status='success' title={`Request sent successfully`}/>
                      </>
                    }
                  </div>
                }

                {tokenConfigurationProcess?.legendCreationFile && !tokenConfigurationProcess.isLegendAdded &&
                  <div> 
                    <Checkbox value={addLegengChecked} onChange={() => setAddLegengChecked(!addLegengChecked)}/>{' '}
                    <a 
                      href={tokenConfigurationProcess.legendCreationFile.URL} 
                      download={tokenConfigurationProcess.legendCreationFile.name} 
                      target="_blank" 
                      rel="noopener noreferrer">
                      <LinkOutlined /> Legend is being created for token by Issuer Counsel. Click here to view it
                    </a>
                    <br/><br/>
                    <div style={{textAlign:'center'}}>
                      <Button size='large' type='primary' disabled={!addLegengChecked} loading={submitting} onClick={_addLegend}>
                        ADD LEGEND TO MY TOKEN
                      </Button>
                    </div>
                  </div>
                }

                {tokenConfigurationProcess?.legendCreationFile && tokenConfigurationProcess.isLegendAdded && 
                  <div> 
                    <a 
                      href={tokenConfigurationProcess.legendCreationFile.URL} 
                      download={tokenConfigurationProcess.legendCreationFile.name} 
                      target="_blank" 
                      rel="noopener noreferrer">
                      <LinkOutlined /> Legend added. Click here to view it
                    </a>
                    <br/><br/>
                  </div>
                }

              </TabPane>

            </Tabs>
          }


        </Col>
      </Row>
    </>
  );
}