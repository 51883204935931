import { MetamaskService } from '../../../Metamask.service';

export default class Rule144Facet {
  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  saveRule144Config(
    moduleAddress: string,
    owner: string,
    _maxAffiliateSellPercentage: string,
    _maxAffiliateAmountMonths: number,
    _maxAffiliateAmount: string,
    _maxAffiliateTokensMonths: number,
    _maxAffiliateTokens: string,
    _investorLockPeriord: number
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.saveRule144Config(
      _maxAffiliateSellPercentage,
      _maxAffiliateAmountMonths,
      _maxAffiliateAmount,
      _maxAffiliateTokensMonths,
      _maxAffiliateTokens,
      _investorLockPeriord
    ).send({ from: owner });
  }

  async getMaxAffiliateSellPercentage(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getMaxAffiliateSellPercentage().call()) as string;
  }

  async getMaxAffiliateAmountMonths(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getMaxAffiliateAmountMonths().call()) as string;
  }

  async getMaxAffiliateAmount(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getMaxAffiliateAmount().call()) as string;
  }

  async getMaxAffiliateTokensMonths(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getMaxAffiliateTokensMonths().call()) as string;
  }

  async getMaxAffiliateTokens(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getMaxAffiliateTokens().call()) as string;
  }

  async getAffiliateRule144Params(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getAffiliateRule144Params().call()) as string[];
  }

  async rule144ConfigInitialized(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.rule144ConfigInitialized().call()) as boolean;
  }
};

// Not Implemented
// function investorLockPeriord() external view returns (uint256) {
// }
