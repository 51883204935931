import { MetamaskService } from '../../../Metamask.service';
import { UserLockedAmount } from '../../../interfaces';

export default class GeneralTransferManagerFacet {

  private contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async USDPrice(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.USDPrice().call()) as string;
  }

  async lastUpdateUSDPrice(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.lastUpdateUSDPrice().call()) as string;
  }

  async calculateTotalLockedAmount(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateTotalLockedAmount(_from).call()) as string;
  }

  async calculateTotalLockedAmountAccredited(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateTotalLockedAmountAccredited(_from).call()) as string;
  }

  async getUserLockedAmountList(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.getUserLockedAmountList(_from).call()) as UserLockedAmount[];
  }

  async calculateAffiliateAmountSentIn3Months(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateAffiliateAmountSentIn3Months(_from).call()) as string;
  }

  async calculateAffiliateSellLimit(moduleAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateAffiliateSellLimit().call()) as string;
  }

  async calculateAffiliateTokenAmountSent(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateAffiliateTokenAmountSent(_from).call()) as string;
  }

  async calculateAffiliateUSDAmountSent(moduleAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return (await contractInstance.methods.calculateAffiliateUSDAmountSent(_from).call()) as string;
  }

  updateUSDPrice(moduleAddress: string, owner: string, _USDPrice: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, moduleAddress);
    return contractInstance.methods.updateUSDPrice(_USDPrice).send({ from: owner });
  }

  async addInvestorLockedAmountMulti(tokenAddress: string, owner: string, lockPeriod: number, _from: string[], _amount: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addInvestorLockedAmountMulti(lockPeriod, _from, _amount).send({ from: owner });
  }

  async canTransfer(tokenAddress: string, _from: string, _to: string, _value: string, _launchIndex: number, _isDefaultTransfer: boolean) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.canTransfer(_from, _to, _value, _launchIndex, _isDefaultTransfer).call()) as [boolean, string];
  }

};

// Not Implemented  
// function addLockedAmount(address _from, uint256 _amount, uint256 _lockPeriod) external onlyFacetOrOwner {
// }
// function processCanTransfer(address _from, address _to, uint256 _value, uint256 _launchIndex, bool _isDefaultTransfer) onlyFacet external returns (bool, string memory) {
// }
// function stringsAreEqual(string memory a, string memory b) internal pure returns (bool) {
// }
  